<!-- FOOTER -->
<div class="footer custom-footer collectonme">
    <div class="container">
        <div class="row">
            <div class="footer-menu col-md-12">
                <div class="row">
                    <div class="col-md-5">
                        <div class="row">
                            <p style="font-size: 18px; color: white;">
                                Adresse : 36 rue de l'énergie atomique, Tunis, Tunisie
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <p style="font-size: 18px; color: white;">
                                Numéro +216 50 505 260
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <p style="font-size: 18px; color: white;">
                                Mail : wassali.express@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="social-item side-block col-md-12 text-center">
                <a target="_blank" href="https://www.instagram.com/wassalitunisie/?hl=fr">
                    <span class="fa-stack fa-lg">
                        <i class="fa fa-square-o fa-stack-2x"></i>
                        <i class="fa fa-instagram fa-stack-1x"></i>
                    </span>
                </a>
                <a target="_blank" href="https://www.facebook.com/wassali.tn">
                    <span class="fa-stack fa-lg">
                        <i class="fa fa-square-o fa-stack-2x"></i>
                        <i class="fa fa-facebook fa-stack-1x"></i>
                    </span>
                </a>
                <!-- <a href="#">
                    <span class="fa-stack fa-lg">
                        <i class="fa fa-square-o fa-stack-2x"></i>
                        <i class="fa fa-twitter fa-stack-1x"></i>
                    </span>
                </a> -->
                <a target="_blank" href="https://www.linkedin.com/company/81465905/admin/">
                    <span class="fa-stack fa-lg">
                        <i class="fa fa-square-o fa-stack-2x"></i>
                        <i class="fa fa-linkedin fa-stack-1x"></i>
                    </span>
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UCTGHaMR7vIdFZ2HIm0fPKHw">
                    <span class="fa-stack fa-lg">
                        <i class="fa fa-square-o fa-stack-2x"></i>
                        <i class="fa fa-youtube fa-stack-1x"></i>
                    </span>
                </a>
            </div>
            <div class="footer-menu col-md-12">
                <p>
                    <a [routerLink]="['/privacy-policy-wassali']">Politique de confidentialité</a>
                    |
                    <a [routerLink]="['/user-agreement-wassali']">Conditions d’utilisation</a>
                </p>
            </div>
            <div class="copy-right col-md-12 text-center">
                <p>&copy; Copyright | Propulsé par <a [routerLink]="['/']"><u><b>eWassali</b></u></a></p>
            </div>
        </div>
    </div>
</div>
<div class="mobilebuttons">
	<div style="background-color:#ffd46d; padding-top:5px" class="col-xs-12 col-sm-4 col-md-4">
		<div class="row"><div class="col-xs-6 col-sm-4"><a href="tel:+21650505260" class="btn link-btn btn-xs"><i class="fa fa-phone"></i> Call Us</a></div><div class="col-xs-6 col-sm-4"><a href="contact.html" class="btn link-btn btn-xs"><i class="fa fa-paper-plane"></i> Write Us</a></div></div>
	</div>
</div>
<div class="gmap-layer" style="border-top: 6px solid #FFD46D">
	<div class="container">
		<div class="row">
			<div class="col-sm-4">
				<div id="callNowDesktopD" class="call-us hidden-xs">
					<span style="padding-left:2px"><a href="tel:+21650505260" class="dark-btn" data-toggle="tooltip" data-placement="bottom" data-original-title="+216 50 505 260"><i class="fa fa-phone-square fa-2x dark-btn"></i></a></span>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="logo-wraper">
					<div class="logo">
						<a href=".">
							<img src="assets/images/logo-taxi-wassalni.png" alt="Taxi Service" class="logoimg">
						</a>
					</div>
				</div>
			</div>
			<!-- <div class="col-sm-3">
				<div class="minimenudiv">
					<div class="minimenu">
						&nbsp;
					</div>
				</div>
			</div> -->
			<div class="col-sm-4 mainnavmenu">
				<div class="header-menu-wrap">
					<nav class="navbar navbar-default">
						<div class="main collapse navbar-collapse">
							<div class="column">
								<div id="dl-menu" class="dl-menuwrapper">
									<a href="#" class="dl-trigger" data-toggle="dropdown" id="menuButton"><i class="fa fa-bars"></i></a>
									<ul class="dl-menu" id="ulMenu">
                                        <li>
                                            <a [routerLink]="['/']">Accueil</a>
                                            <!-- <ul class="dl-submenu ">
                                                <li><a href="index.html">Homepage One</a></li>
                                                <li><a href="index2.html">Homepage Two</a></li>
                                            </ul> -->
                                        </li>
                                        <!-- <li>
                                            <a [routerLink]="['/about-wassali']">À propos de WASSALI</a>
                                        </li> -->
                                        <li>
                                            <a [routerLink]="['/services-wassali']">Les Services de WASSALI</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/grouping-wassali']">Groupage</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/my-demandes-transportor']">Mes demandes</a>
                                        </li>
                                        <!-- <li>
                                            <a href="testimonials.html">Testimonials</a>
                                        </li> -->
                                        <li>
                                            <a [routerLink]="['/inscription-wassali']">Comment s'inscrire</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/ecolo-x-wassali']">Transport plus durable</a>
                                        </li>
                                        <!-- <li>
                                            <a [routerLink]="['/contacts-wassali']">Nos contacts</a>
                                        </li> -->
                                        <!-- <li *ngIf="isConnected==true" class="handel-cursor">
                                            <a (click)="deconnexion()">Déconnexion</a>
                                        </li> -->
                                    </ul>
								</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>
	</div>
</div>

<div id="bookbutton">
    <div class="col-xs-12 bookbuttondiv">
        <a class="btn bookbutton" style="border-radius: 83px;">Passer la course</a>
    </div>
</div>
<div class="custom-header custom-header-img-wassalni"></div>
<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama-fade.svg) bottom center repeat-x">
    &nbsp;</div>
<!-- BODY -->
<div class="container">
    <div class="col-sm-12">
        <div class="row Transfer">
            <p>&nbsp;</p>
            <h1>Politique De Confidentialité</h1>
            <div class="col-sm-12 control-label2">
                <p>&nbsp;</p>
                <p style="text-align:justify">
                    Dernière mise à jour : 21/07/2022.
                    <br><br>
                    Bienvenue sur <b>E-Wassalni</b>.
                    <br><br>
                    L’Application <b>E-Wassanli</b> est opérée par la société WASSALNI EXPRESS , sise à Charguia 1,
                    Carthage, Tunis, 2073, immatriculée au registre du commerce et des sociétés de StartupAct Tunisia
                    sous le numéro 1738199M le 30/10/2021.
                    <br><br>
                    <b>WASSALNI EXPRESS</b> est une société de vente de logiciels spécialisée dans le e-commerce, le
                    transport,
                    la diffusion et l’interaction avec des informations géolocalisées pour vous permettre d’explorer le
                    monde qui vous entoure et, à ce titre, est particulièrement attachée à la protection de vos données
                    personnelles.
                    <br><br>
                    A cet effet, WASSALNI EXPRESS veille à respecter la réglementation en vigueur prévue par le Règlement Général sur la Protection des Données.
                    <br><br>
                    Cette politique de confidentialité vous permet également de connaître vos droits et la manière dont vous pouvez les exercer auprès de WASSALNI EXPRESS.
                    <br><br>
                    Nous sommes chargés de protéger votre vie privée. Cette politique décrit nos pratiques de confidentialité, les informations personnelles que nous collectons, comment nous les utilisons et les partageons, ainsi que vos choix et droits concernant ces derniers.

                    <br><br>
                    Cette politique de confidentialité s'applique à nos services partout où nous les fournissons, et nous nous conformerons aux lois locales en ce qui concerne toutes les pratiques décrites dans cette politique. 

                    <br><br>
                    Notre politique de confidentialité étant susceptible d’être mise à jour à tout moment, nous vous invitons à vous y référer régulièrement afin de prendre connaissance de sa dernière version disponible, les modifications prenant effet immédiatement.

                    <br><br>
                    La présente politique relative à la protection des données à caractère personnel (ci-après « La politique de confidentialité de l’Application E-Wassalni ») informe les utilisateurs accédant, téléchargeant, installant et/ou utilisant l’Application E-Wassalni (« Utilisateur », « Vous », « Votre », « Vos ») sur la collecte et l’utilisation de Vos données à caractère personnel lorsque Vous utilisez l’Application E-Wassalni que ce soit via vos appareils mobiles, tablettes ou autres.

                    <br><br>
                    La Politique de confidentialité de l’Application E-Wassalni s’applique à la collecte de données hors ligne ou en dehors de Nos Services.

                    <br><br>
                    L’Application E-Wassalni peut comporter des liens vers d’autres Sites qui ne sont pas opérés par WASSALNI EXPRESS et dont la politique de protection des données personnelles et d’utilisation de cookies peut différer de celle applicable à ce Site et à Nos services. Vous êtes invités à prendre connaissance de ces politiques pour comprendre comment ces sociétés tierces utilisent Vos données personnelles.
                </p>
                <br><br>

                <h5 style="font-size: 22px;
                line-height: normal;">1. Responsable De Traitement</h5>
                <p style="text-align:justify">Le responsable de traitement est la société : WASSALNI EXPRESS ayant son siège social sis à Charguia 1, Carthage, Tunis, 2073. immatriculée au registre du commerce et des sociétés de StartupAct Tunisia sous le numéro 1738199M le 30/10/2021. 
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">2. Dans Quels Cas Sommes-Nous Susceptibles De Collecter Des Données À
                    Caractère Personnel Vous
                    Concernant ?</h5>
                <p style="text-align:justify">Nous sommes susceptibles de collecter des données à caractère personnel vous concernant à l’occasion de votre utilisation de l’Application Wassalni. Nous ne collectons vos données personnelles que lorsque cela est strictement nécessaire et légal. Nous nous engageons à ce titre à ne collecter que le minimum d’informations personnelles nécessaires aux fins couvertes par la présente politique de confidentialité.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">Utilisation De L’Application <b>E-Wassalni
</b></h5>
                <p style="text-align:justify">
                    Il est nécessaire de vous identifier ou Nous fournir des données à caractère personnel pour utiliser l’Application E-Wassalni. 

                    <br><br>
                    Toutefois, comme la plupart des sites Internet et applications mobiles, l’Application E-Wassalni collecte automatiquement des données concernant votre terminal (comme le nom ou l’adresse que vous utilisez, les dates et heures de connexion,la position de votre appareil, la nature et le contenu des requêtes http…).

                    <br><br>
                     Par la suite, nous sommes susceptibles de collecter et de traiter les informations suivantes, dans le cadre de votre utilisation de l’Application E-Wassalni :<br>
                    – Données d’identification techniques de l’équipement matériel (tablette, Smartphone… que vous utilisez et de ses composants, (ci-après « Votre équipement ») ; 
                    <br>
                    – Données d’utilisation l’Application E-Wassalni (ouverture / fermeture d’écran, consultation de contenus, succès / échec dans les missions);

                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">3. Quelle Est Notre Politique Concernant Les Données Personnelles Des
                    Mineurs ?</h5>
                <p style="text-align:justify"><b>WASSALNI EXPRESS</b>, qui peut être amené à traiter les données personnelles de votre enfant, est particulièrement attaché au respect de la vie privée des mineurs conformément au RGPD et à la « Loi Informatique et Libertés ».
                    <br><br>
                    Dans l’hypothèse où votre enfant serait âgé de moins de quinze (15) ans, vous serez invité, en votre qualité de titulaire de l’autorité parentale, à consentir au traitement des données personnelles de votre enfant. 

                    <br><br>
                    Le cas échéant, WASSALNI EXPRESS s’efforce de vérifier raisonnablement, sur la base de vos déclarations, que le consentement a valablement été donné par la personne exerçant l’autorité parentale.

                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">4. Comment Vos Données À Caractère Personnel Sont-Elles Utilisées ?</h5>
                <p style="text-align:justify">
                    Le traitement de Vos données personnelles a pour objectif principal de Vous permettre d’utiliser Nos Services, et notamment pour : Nous collectons les informations que vous nous fournissez lors de votre utilisation de l’Application et/ou du Service, par exemple : Les informations personnelles que vous saisissez lors de votre inscription au Service, notamment votre nom complet, votre adresse e-mail, votre numéro de téléphone, votre photo de profil, les informations sur le véhicule (numéro d’immatriculation, type de véhicule, année de fabrication, couleur du véhicule) ainsi que données du permis de conduire. Placer des données d’identification technique sur votre terminal est le moyen le plus simple et le plus rapide pour personnaliser et améliorer votre expérience utilisateur.

                    <br><br>
                    Nous utilisons ainsi des données d’identification technique permettant d’optimiser le fonctionnement de l’Application E-Wassalni et adapter la présentation de l’Application E-Wassalni aux préférences d’affichage de votre équipement. Ces données d’identification technique assurent ainsi une utilisation fluide et sur mesure en adaptant notamment la présentation de l’Application aux préférences d’affichage de Votre équipement (photo de profil) lors de vos visites sur l’Application E-Wassalni.

                    <br><br>
                    Ces données d’identification technique ne sont pas indispensables à l’utilisation de l’Application Wassalni mais en les désactivant vous ne pourrez accéder à l’ensemble des fonctions ou fonctionnalités de l’Application E-Wassalni.

                    <br><br>
                    Pour l’exploitation de l’application E-Wassalni nous utilisons des données d’identification technique pour aider à analyser les comportements et les besions. Ils permettent ainsi d’analyser le trafic, les tendances, les usages et réaliser et générer de statistiques, ainsi que des données globales sur l’utilisation de l’Application E-Wassalni, ce qui Nous permet d’améliorer Votre utilisation, ainsi que la conception et le contenu de l’Application pour répondre à vos besoins.

                </p><br>

                <h5 style="font-size: 22px;
                line-height: normal;">5. Quelle Est Notre Utilisation Des Données De Localisation ?</h5>
                <p style="text-align:justify">
                    L’Application E-Wassalni peut reposer sur des services de localisation. Dans ce cas et dans la mesure où vous choisissez d’utiliser de tels services, l’Application E-Wassalni collecte et traite, avec votre accord, vos données de localisation pour vous fournir le Service.

                    <br><br>
                    Par exemple, dans le cadre de l’utilisation de l’Application E-Wassalni, vous avez la possibilité de vous localiser pour afficher votre position sur une carte, pour explorer des chauffeurs ou missions autour de vous ou pour détecter votre proximité avec un véhicule positionné dans le monde réel. 

                    <br><br>
                    Si vous accédez au Service via un appareil mobile, nous pouvons accéder, collecter, surveiller et/ou stocker à distance vos Données de localisation, qui peuvent inclure vos coordonnées GPS. (y compris les données sur le point de départ et d’arrivée du passager point) ou des informations similaires concernant l’emplacement d’un appareil Nos services peuvent utiliser différentes technologies pour déterminer la localisation, comme le GPS, le Wi-Fi ou d’autres données basées sur le réseau telles que l’adresse IP. Pour savoir quelle technologie est utilisée et quelles données sont traitées, Nous vous invitons à consulter les conditions générales d’utilisation de nos Services. L’Application E-Wassalni est susceptible d’utiliser la fonction de géolocalisation de votre système d’exploitation mobile ou de votre navigateur Internet pour déterminer votre position. 

                    <br><br>
                    L’Application E-Wassalni accède ainsi, avec votre accord explicite, à votre position. Vos données de localisation telles qu’utilisées pour le fonctionnement de l’Application E-Wassalni ne sont pas conservées, ni sur votre terminal, ni sur un serveur d’aucune sorte. Nous ne partagerons pas vos informations de géolocalisation avec des tiers sans votre consentement. 
                    <br><br>
                    Lorsque vous utilisez l’Application E-Wassalni pour la première fois et que celui-ci est configuré de telle sorte que la connaissance de votre position est nécessaire, l’Application E-Wassalni vous demande si vous souhaitez ou non partager votre position avec le service. Si vous refusez, votre position n’est pas partagée et vous ne pourrez pas utiliser le service dans son intégralité.

                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">6. Pour Quelle Durée Conservons-Nous Vos Données Personnelles ?</h5>
                <p style="text-align:justify">
                    Conformément à la législation et la réglementation en vigueur, nous ne conservons vos données personnelles que pour une durée strictement nécessaire aux finalités poursuivies ci-dessus. 

                    <br><br>
                    A ce titre, nous vous informons que la durée de conservation peut varier en fonction de la finalité du service.

                    <br><br>
                    Catégories de données personnelles Données personnelles concernant l’utilisation de l’Application E-Wassalni Statistiques de mesure d’audience et données de fréquentation brutes de l’Application E-Wassalni Durée de conservation toute la durée de l’utilisation de l’Application E-Wassalni

                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">7. Avec Qui Sommes-Nous Susceptibles De Partager Vos Données Personnelles ?
                </h5>
                <p style="text-align:justify">
                    Nous pouvons être conduits à communiquer des données à caractère personnel Vous concernant aux tiers intervenants pour Notre compte ou à Nos clients .

                    <br><br>
                    Par ailleurs, Nous pouvons être contraints de communiquer Vos données à caractère personnel afin d’assurer le respect d’une obligation légale ou réglementaire, d’une décision de justice, ou si cette communication est nécessaire afin d’assurer la défense ou l’exercice d’un droit en justice, sur le territoire national ou à l’étranger.

                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">8. Transfert De Données À Caractère Personnel Hors De L’UE</h5>
                <p style="text-align:justify">
                    Pour l’exploitation de l’Application E-Wassalni nous utilisons Google Analytics, un service d’analyse Web et Mobile fourni par la société Google Inc. située aux États-Unis (« Google »).

                    <br><br>
                    Google Analytics s’appuie notamment sur les données d’identification technique, pour aider à analyser les comportements des utilisateurs de l’Application E-Wassalni. 

                    <br><br>
                    Ils permettent ainsi d’analyser le trafic, les tendances, les usages et réaliser et générer de statistiques, ainsi que des données globales sur l’utilisation de l’Application E-Wassalni
.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">9. Comment Vos Données Personnelles Sont-Elles Protégées ?</h5>
                <p style="text-align:justify">
                    <b>WASSALNI EXPRESS</b> prend toutes les mesures organisationnelles et techniques qui s’imposent pour protéger vos données personnelles dans le respect des obligations et exceptions applicables en vertu des lois en vigueur, afin de protéger les données contre toute manipulation accidentelle ou intentionnelle, perte, destruction, communication ou contre tout accès non autorisé à vos données.

                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">10. Quels Sont Les Cas Dans Lesquels Nous Sommes Susceptibles De Vous
                    Contacter ?</h5>
                <p style="text-align:justify">
                    Nous pouvons être amenés à vous demander périodiquement de fournir des informations sur vos expériences (par exemple, noter l’Application E-Wassalni / des nouvelles fonctionnalités), lesquelles serviront à évaluer et améliorer la qualité de Nos services. Vous n’êtes en aucun cas et en aucune circonstance obligé de fournir de telles données. Nous pouvons également vous contacter pour vous informer des éventuelles anomalies et problèmes liés à Nos services. 
</p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">11. Comment Accéder À Vos Données Personnelles Et Les Modifier, Ou Exercer
                    Votre Droit D’opposition ?
                </h5>
                <p style="text-align:justify">
                    Vous disposez d’un droit d’accès, de correction et de suppression de vos données personnelles, ainsi que d’opposition pour motif légitime au traitement de vos données. Vous pouvez exercer ces droits via la fonction « Contactez-nous » de notre site, ou en nous envoyant un message à l’adresse suivante : 
<b>eewassalni@gmail.com</b>.</p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">12. Notifications</h5>
                <p style="text-align:justify">
                    Votre appareil mobile ainsi que Nos services sont susceptibles d’être paramétrés afin que vous soyez informés d’appels, de discussions et de messages entrants lorsque vous n’êtes pas en train d’utiliser notre application.

                    <br><br>
                    En tant que passager, vous acceptez de recevoir certaines notifications push, SMS ou e-mails de notre part. Vous pouvez modifier ou refuser de recevoir des communications envoyées à des fins de marketing, ou certaines autres communications en envoyant un e-mail à <b>eewassalni@gmail.com</b>.

                    Veuillez noter que malgré toutes les préférences marketing, nous pouvons vous envoyer des e-mails administratifs concernant le site Web, l’application ou le service, y compris, par exemple, des avis de mise à jour de la politique de confidentialité.

                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">13. Modification De La Présente Politique</h5>
                <p style="text-align:justify">
                    Nous nous réservons le droit de modifier la présente Politique de protection des données personnelles à tout moment. Par conséquent, nous vous recommandons de consulter régulièrement cette Politique afin de vous informer de toute mise à jour de son contenu.
</p>
                <br>

                <!-- <h5 style="font-size: 22px;
                line-height: normal;">14. Comment Nous Contacter ?</h5>
                <p style="text-align:justify">
                    Vous avez la possibilité de nous contacter pour toute question relative à l’utilisation de vos
                    données, ou pour exercer votre droit d’accès, de modification ou de suppression de Vos données à
                    l’adresse suivante mail suivante : <b>wassali.express@gmail.com</b>.</p>
                <br> -->

                <p>&nbsp;</p>
            </div>
        </div>
    </div>
</div>
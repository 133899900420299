import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-get-location',
  templateUrl: './get-location.component.html',
  styleUrls: ['./get-location.component.css']
})
export class GetLocationComponent implements OnInit {

  // title: string = 'AGM project';
  // latitude: number;
  // longitude: number;
  zoom: number;
  // address: string;
  position: marker = null
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(public dialogRef: MatDialogRef<GetLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(async () => {
      this.geoCoder = new google.maps.Geocoder;
      await this.setCurrentLocation();
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // this.latitude = position.coords.latitude;
        // this.longitude = position.coords.longitude;
        this.zoom = 15;

        this.getAddress(position.coords.latitude, position.coords.longitude);
      });
    }
  }

  markerDragEnd(m: marker, $event: google.maps.MouseEvent) {
    // console.log($event);
    // this.latitude = $event.latLng.lat();
    // this.longitude = $event.latLng.lng();
    this.getAddress($event.latLng.lat(), $event.latLng.lng());
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.position = {
            lat: latitude,
            lng: longitude,
            label_target: results[0].formatted_address,
            main_target: results[0].address_components[results[0].address_components.length-1].long_name,
            sencondary_target: results[0].address_components[results[0].address_components.length-1].long_name
          }
          this.data.position = this.position
          // this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

}

interface marker {
  lat: number;
  lng: number;
  sencondary_target: string;
  label_target: string;
  main_target: string;
}

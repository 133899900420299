<!-- <div id="preloader">
    <div class="preloader-container">
        <div class="item item-1"></div>
        <div class="item item-2"></div>
        <div class="item item-3"></div>
        <div class="item item-4"></div>
    </div>
</div> -->
<div class="mobilebuttons">
    <div style="background-color:#fff; padding-top:10px" class="col-xs-12 col-sm-4 col-md-4">
        <div class="row">
            <div class="col-xs-6 col-sm-4"><a href="tel:+21650505260" class="btn link-btn btn-xs"><i
                        class="fa fa-phone"></i> Kalamni</a></div>
            <div class="col-xs-6 col-sm-4">
                <a *ngIf="isConnected==false" class="btn link-btn btn-xs" [routerLink]="['/login']">
                    Se connecter
                </a>
                <a *ngIf="isConnected==true" class="btn link-btn btn-xs">
                    <i class="fa fa-paper-plane"></i>
                    Message</a>
            </div>

        </div>
    </div>
</div>
<div class="gmap-layer">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div id="callNowDesktopD" class="call-us hidden-xs">
                    <span style="padding-left:2px"><a href="tel:+21650505260" class="dark-btn-tel" data-toggle="tooltip"
                            data-placement="bottom" data-original-title="+216 50 505 260"><i
                                class="fa fa-phone-square fa-2x dark-btn-tel"></i></a></span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="logo-wraper" style="z-index:4">
                    <div class="logo">
                        <a [routerLink]="['/']">
                            <img src="assets/images/logo-taxi.png" alt="camion wassali" class="logoimg">
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-1">
                <div class="minimenudiv" style="z-index:40">
                    <div class="minimenu" style="z-index:40">
                        &nbsp;
                    </div>
                </div>
            </div> -->
            <div class="col-sm-3">
                <div class="minimenudiv" style="z-index:40">
                    <div class="minimenu connexion text-center handel-cursor white-text" style="z-index:40"
                        *ngIf="isConnected==false">
                        <div (click)="connecte()">&nbsp; Se connecter &nbsp;</div>
                    </div>
                    <div class="profile-user" style="z-index:40" *ngIf="isConnected==true">
                        <div class="connexion text-center black-text">
                            <img width="50" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                            ((user?.profile_user!=null && user?.profile_user!='') 
                            ? user?.profile_user : 'profile.jpg')}}" alt="client wassali" style="border: 3px solid #013274;
                            border-radius: 50%;
                            height: 50px;">
                            &nbsp; {{user?.prenom_user}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 mainnavmenu" style="z-index:30">
                <div class="header-menu-wrap">
                    <nav class="navbar navbar-default">
                        <div class="main  collapse navbar-collapse">
                            <div class="column">
                                <div id="dl-menu" class="dl-menuwrapper">
                                    <a class="dl-trigger" data-toggle="dropdown" id="menuButton" style="cursor: pointer;"><i
                                            class="fa fa-bars"></i></a>
                                    <ul class="dl-menu" id="ulMenu">
                                        <li>
                                            <a [routerLink]="['/']">Accueil</a>
                                            <!-- <ul class="dl-submenu ">
                                                <li><a href="index.html">Homepage One</a></li>
                                                <li><a href="index2.html">Homepage Two</a></li>
                                            </ul> -->
                                        </li>
                                        <!-- <li>
                                            <a [routerLink]="['/about-wassali']">À propos de WASSALI</a>
                                        </li> -->
                                        <li>
                                            <a [routerLink]="['/services-wassali']">Les Services de WASSALI</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/grouping-wassali']">Groupage</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/my-demandes-transportor']">Mes demandes</a>
                                        </li>
                                        <!-- <li>
                                            <a href="testimonials.html">Testimonials</a>
                                        </li> -->
                                        <li>
                                            <a [routerLink]="['/inscription-wassali']">Comment s'inscrire</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/ecolo-x-wassali']">Transport plus durable</a>
                                        </li>
                                        <!-- <li>
                                            <a [routerLink]="['/contacts-wassali']">Nos contacts</a>
                                        </li> -->
                                        <li *ngIf="isConnected==true" class="handel-cursor">
                                            <a (click)="deconnexion()">Déconnexion</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { TopComponent } from './components/shared/top/top.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';
import { CauseCanceledDemandeComponent } from './components/shared/dialog/cause-canceled-demande/cause-canceled-demande.component';
import { GetLocationComponent } from './components/shared/dialog/get-location/get-location.component';
import { NumTelDemandeComponent } from './components/shared/dialog/num-tel-demande/num-tel-demande.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { QRCodeModule } from 'angularx-qrcode';
import { InscriptionWassaliComponent } from './pages/inscription-wassali/inscription-wassali.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { AboutWassaliComponent } from './pages/about-wassali/about-wassali.component';
import { EcoloXWassaliComponent } from './pages/ecolo-x-wassali/ecolo-x-wassali.component';
import { GroupingWassaliComponent } from './pages/grouping-wassali/grouping-wassali.component';
import { UserAgreementComponent } from './pages/user-agreement/user-agreement.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { DetailsDemandeComponent } from './pages/details-demande/details-demande.component';
import { FactureGroupingComponent } from './pages/facture-grouping/facture-grouping.component';
import { MyDemandesComponent } from './pages/my-demandes/my-demandes.component';
import { ServicesWassaliComponent } from './pages/services-wassali/services-wassali.component';
import { ForgetPasswordComponent } from './authentications/forget-password/forget-password.component';
import { InscriptionComponent } from './authentications/inscription/inscription.component';
import { LoginComponent } from './authentications/login/login.component';
import { GuardGuard } from './guard/guard.guard';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { SafePipe } from './pipes/safe/safe.pipe';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { UserAgreementWassalniComponent } from './pages/WASSALNI/user-agreement-wassalni/user-agreement-wassalni.component';
import { PrivacyPolicyWassalniComponent } from './pages/WASSALNI/privacy-policy-wassalni/privacy-policy-wassalni.component';
// import { 
//   MatDatepickerModule,
//   MatNativeDateModule,
//   MatInputModule
// } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    AppComponent,
    BaseLayoutComponent,
    HomeComponent,
    TopComponent,
    FooterComponent,
    CauseCanceledDemandeComponent,
    GetLocationComponent,
    NumTelDemandeComponent,
    InscriptionWassaliComponent,
    ContactsComponent,
    AboutWassaliComponent,
    EcoloXWassaliComponent,
    GroupingWassaliComponent,
    UserAgreementComponent,
    PrivacyPolicyComponent,
    DetailsDemandeComponent,
    FactureGroupingComponent,
    MyDemandesComponent,
    ServicesWassaliComponent,
    ForgetPasswordComponent,
    InscriptionComponent,
    LoginComponent,
    SafePipe,
    UserAgreementWassalniComponent,
    PrivacyPolicyWassalniComponent
  ],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBxjG4cCRExwv0MQnbkWzv3IFsBXxe3MQE',
      libraries: ['places']
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgSelectModule,
    SocialLoginModule,
    MatDialogModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    NgxGoogleAnalyticsModule.forRoot('G-5RDZ0M102Z'),
    NgxGoogleAnalyticsRouterModule,
    IvyCarouselModule,
    QRCodeModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule
  ],
  providers: [Meta,
    GuardGuard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2169170619915043')

          }
        ]
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="google-image">
    <div id="map">
        <div #map id="map-canvas"></div>
        <div class="map-loader-container" *ngIf="!mapLoaded">
            <div class="map-loader"></div>
        </div>
    </div>
</div>


<div class="booking-form-wrapper" style="z-index: 51">
    <div class="col-sm-12">
        <div class="row">
            <div class="form-wrap" style="border-radius: 10px;">
                <!-- <div class="form-headr"></div> -->
                <h2 style="line-height: 100%; padding:10px !important"> Mise en relation avec les transporteurs
                    <br> Vous pouvez recevoir plusieurs offres...
                </h2>

                <div class="form-select">
                    <div class="col-md-12">
                        <!-- <div class="row"> -->
                        <div class="services-content equal" style="background-color: white;">
                            <div class="row" style="width: 100%;">
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div class="row">
                                                <div class="services-vehicle-home">
                                                    <img class="img-driver-proposition"
                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/driver-trans/driver-vehicule/' + demande?.acceptedBy?.driver_id?.info_vehicule?.photo_vehicule}}"
                                                        alt="proposition transporteur wassali">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="services-text-proposition">
                                                    <h6 class="mat-h6-response description-text">
                                                        {{demande?.acceptedBy?.driver_id?.info_vehicule?.type_camion}}</h6>
                                                    <h6 class="mat-h6-response description-text">
                                                        {{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user + " " +
                                                        demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}</h6>
                                                    {{demande?.acceptedBy?.driver_id?.evaluate}} <span
                                                        class="fa fa-star checked description-text"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="services-text-proposition text-right">
                                                    <h6 class="mat-h6-response description-text" style="color: #f44336;
                                                    font-size: 26px;
                                                    font-weight: bold;">{{demande?.acceptedBy?.proposition + " TND"}}
                                                    </h6>
                                                    <h6 class="mat-h6-response description-text">
                                                    </h6>
                                                    <h6 class="mat-h6-response description-phone">
                                                        <i class="fa fa-phone"></i>
                                                        {{demande?.acceptedBy?.driver_id?.user_transportor?.tel_user}} 
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                    <div *ngIf="demande?.etat_demande == 'accepted' || demande?.etat_demande == 'started'" class="form-button col-md-12" style="padding:0 20px 0 20px;">
                        <button type="submit" class="btn form-btn btn-lg btn-block"
                            style="background: #d7dee9; color: black !important;"
                            (click)="showCanceledDemande()">Annuler la demande</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="bookbutton">
    <div class="col-xs-12 bookbuttondiv">
        <a [routerLink]="['/']" class="btn bookbutton" style="border-radius: 83px;">Passer la demande</a>
    </div>
</div>
<div class="custom-header custom-header-demandes"></div>
<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama.png) bottom center repeat-x">
    &nbsp;</div>
<div class="container">
    <div class="row services-container">
        <div class="col-sm-12">
            <div class="row Transfer">

                <p>&nbsp;</p>
                <h2>Mes demandes</h2>
                <h5 style="font-size: 22px;
                line-height: normal;">Tous mes demandes de <b>Wassali</b></h5>
                <p>&nbsp;</p>

                <div class="col-sm-12" *ngFor="let demande of listDemandes">
                    <div class="row" (click)="goToDetails(demande)">
                        <div class="label-item" style="border-bottom: 1px solid #ccc;">
                            <div class="containt-font img-circle">
                                <img src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                ? demande?.user_transportor?.profile_user : 'profile.jpg')
                                 }}"
                                    alt="Demandes wassali" width="62" height="62" style="border-radius: 50%; border: 3px solid #013274;"/>
                            </div>
                            <div class="col-sm-8">
                                <div class="row">
                                    <div class="containt-text" style="width: 100%;">
                                        <h4>{{demande?.user_location?.label_target}}</h4>
                                        <h4>{{demande?.user_destination?.label_target}}</h4>
                                        <h4 style="color: red; font-weight: 700;" *ngIf="demande?.price!=null">
                                            {{demande?.price + " TND"}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="row">
                                    <!-- <div class="containt-text"> -->
                                        <h5 class="text-right text-{{statusDemandes[demande?.etat_demande]?.color}}">
                                            {{statusDemandes[demande?.etat_demande]?.label}}
                                        </h5>
                                        <!-- <button type="submit" class="btn form-btn btn-lg btn-block"
                                    (click)="printBL()">Imprimer</button> -->
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- <div class="col-sm-2 testimonials-photo-div">
                        <div class="row">
                            <div class="testimonials-photo-wrap">
                                <div class="testimonials-photo">
                                    <img style="border-radius: 50%;"
                                        alt="John Doe" width="150px" height="150px" /></div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-sm-8">
                        <div class="row">
                            <div class="testimonials-text">
                                <h6 class="mat-h6-response">{{demande?.user_location?.label_target}}</h6>
                                <h6 class="mat-h6-response">{{demande?.user_location?.sencondary_target}}</h6>
                                <br />
                                <h6 class="mat-h6-response">{{demande?.user_destination?.label_target}}</h6>
                                <h6 class="mat-h6-response">{{demande?.user_destination?.sencondary_target}}</h6>
                                <br />
                                <h6 class="mat-h6-response" style="color: red;">
                                        <b>{{demande?.price}}</b>
                                </h6>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-sm-2">
                        <div class="row">
                            <div class="testimonials-text">
                                <br />
                                <h7><b class="text-right">{{getStatusDemande(demande?.etat_demande)}}</b></h7>
                            </div>
                        </div>
                    </div> -->
                </div>

                <!-- <div class="testimonials-content equal">
                    <div class="col-sm-2 testimonials-photo-div">
                        <div class="row">
                            <div class="testimonials-photo-wrap">
                                <div class="testimonials-photo"><img src="assets/images/testimonials/02.jpg"
                                        alt="John Doe" width="150px" height="150px" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-10">
                        <div class="row">
                            <div class="testimonials-text">
                                <p align="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                                    qui officia deserunt mollit anim id est laborum.</p>
                                <p align="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                                <br />
                                <h7><b>- James David, ABC Corporation CEO</b></h7>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="testimonials-content equal">
                    <div class="col-sm-2 testimonials-photo-div">
                        <div class="row">
                            <div class="testimonials-photo-wrap">
                                <div class="testimonials-photo"><img src="assets/images/testimonials/03.jpg"
                                        alt="John Doe" width="150px" height="150px" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-10">
                        <div class="row">
                            <div class="testimonials-text">
                                <p align="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                                    qui officia deserunt mollit anim id est laborum.</p>
                                <p align="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                                <br />
                                <h7><b>- Ava Sophia, ABC Entertainment Human Resources</b></h7>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="testimonials-content equal">
                    <div class="col-sm-2 testimonials-photo-div">
                        <div class="row">
                            <div class="testimonials-photo-wrap">
                                <div class="testimonials-photo"><img src="assets/images/testimonials/04.jpg" alt="John Doe"
                                        width="150px" height="150px" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-10">
                        <div class="row">
                            <div class="testimonials-text">
                                <p align="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                                    qui officia deserunt mollit anim id est laborum.</p>
                                <p align="justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                                <br />
                                <h7><b>- John Robert, ABC Production General Manager</b></h7>
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
        <!-- <div class="text-center">
            <ul class="pagination">
                <li><a href="#"><i class="fa fa-angle-left"></i></a></li>
                <li class="active"><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#">5</a></li>
                <li><a href="#"><i class="fa fa-angle-right"></i></a></li>
            </ul>
        </div> -->
    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './authentications/forget-password/forget-password.component';
import { InscriptionComponent } from './authentications/inscription/inscription.component';
import { LoginComponent } from './authentications/login/login.component';
import { GuardGuard } from './guard/guard.guard';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { AboutWassaliComponent } from './pages/about-wassali/about-wassali.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { DetailsDemandeComponent } from './pages/details-demande/details-demande.component';
import { EcoloXWassaliComponent } from './pages/ecolo-x-wassali/ecolo-x-wassali.component';
import { FactureGroupingComponent } from './pages/facture-grouping/facture-grouping.component';
import { GroupingWassaliComponent } from './pages/grouping-wassali/grouping-wassali.component';
import { HomeComponent } from './pages/home/home.component';
import { InscriptionWassaliComponent } from './pages/inscription-wassali/inscription-wassali.component';
import { MyDemandesComponent } from './pages/my-demandes/my-demandes.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ServicesWassaliComponent } from './pages/services-wassali/services-wassali.component';
import { UserAgreementComponent } from './pages/user-agreement/user-agreement.component';
import { PrivacyPolicyWassalniComponent } from './pages/WASSALNI/privacy-policy-wassalni/privacy-policy-wassalni.component';
import { UserAgreementWassalniComponent } from './pages/WASSALNI/user-agreement-wassalni/user-agreement-wassalni.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      // { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: '',
        data: {
          title: 'eWASSALI Tunisie | Transport Express',
          descrption: 'Wassali est une plateforme qui met en relation un client avec un transporteur sur toute la Tunisie, visant l’optimisation des retours à vide des camions.',
          keywords: 'ewassali, wassalny, tawssila, wassali, wassali transport, Transporteur Tunisie, transport tunisie, Transporteur tunisie Prix, Transporteur sfax, Transporteur Sousse, Transporteur Tunis, Transporteur Djerba, Transporteur Bizerte, déménagement prix, tournées à vide, retour à vide, optimisation des retours à vide, transport de marchandises retour',
          robots: 'index, follow',
          ogUrl: "/",
          // ogTitle: 'Description of First Component for social media',
        },
        component: HomeComponent
      },
      {
        path: 'my-demandes-transportor',
        data: {
          title: 'Vos demandes effectuaient avec Wassali',
          descrption: 'Veuillez trouver vos demandes de transports effectuées avec Wassali.',
          keywords: 'wassali, ewassaly, wassalny, tawssila, wassaly, wassalii, wassali transport, transport tunisie, demande de transport, transport wassali, Transport, Transport routier, Transport terrestre, Transport Sfax, Transport sousse, Transport tunis, Transport Djerba, Transport Bizerte, Service Transport, transport pas cher tunisie, transport prix tunisie, expéditeur, déménageurs, camion transporteur, transport routier',
          robots: 'index, follow',
          ogUrl: "/my-demandes-transportor",
          // ogTitle: 'Description of First Component for social media',
        },
        component: MyDemandesComponent, canActivate: [GuardGuard],
      },
      {
        path: 'details-demande-transportor',
        data: {
          title: 'Votre demande de transport en cours',
          descrption: 'Wassali vous offre une solution de tracking qui vous permet de suivre votre transporteur en temps réel.',
          keywords: 'ewassaly, wassalny, tawssila, wassali, wassali transport express, transport tunisie, wassaly, transporteur Tunisie, suivre transporteur, service transport, service transport Tunisie prix, transport sfax prix, transport sousse prix, transport djerba prix',
          robots: 'index, follow',
          ogUrl: "/details-demande-transportor",
          // ogTitle: 'Description of First Component for social media',
        },
        component: DetailsDemandeComponent, canActivate: [GuardGuard],
      },
      {
        path: 'privacy-policy-wassali',
        data: {
          title: 'Politique de confidentialité - Wassali',
          robots: 'index, follow',
          ogUrl: "/privacy-policy-wassali",
        }, component: PrivacyPolicyComponent,
      },
      {
        path: 'user-agreement-wassali',
        data: {
          title: "Conditions d'utilisation - Wassali",
          robots: 'index, follow',
          ogUrl: "/user-agreement-wassali",
        }, component: UserAgreementComponent,
      },
      { path: 'contacts-wassali', component: ContactsComponent, },
      {
        path: 'about-wassali',
        data: {
          title: 'À propos Wassali',
          descrption: 'Vous êtes à Tunis, Sfax, Sousse, Bizerte ou même à Djerba, Wassali vous connecte avec le transporteur qui vous convient avec le prix qui vous convient.',
          robots: 'index, follow',
          ogUrl: "/about-wassali",
          // ogTitle: 'Description of First Component for social media',
        },
        component: AboutWassaliComponent,
      },
      {
        path: 'services-wassali',
        data: {
          title: 'Les services proposés par Wassali',
          descrption: 'Wassali une solution parfaite pour tous vos transports, elle met à votre disposition une panoplie de service qui répond parfaitement à vos besoins.',
          robots: 'index, follow',
          keywords: 'ewassali, wassali, wassali transport, Transporteur Tunisie, transport tunisie, remorquage tunisie, remorquage prix tunisie, remorquage tunisie prix, service remorquage tunisie, service remorquage, romorquage, sos remorquage, tunisie remorquage, remorquage tunis, remorquage sfax, remorquage sousse, remorquage hammamet, Transport, Transport routier, Transport terrestre, Transport Sfax, Transport sousse, Transport tunis, Transport Djerba, Transport Bizerte, Service Transport, transport pas cher tunisie, transport prix tunisie, expéditeur, déménageurs, camion transporteur, transport routier, déménagement prix',
          ogUrl: "/services-wassali",
          // ogTitle: 'Description of First Component for social media',
        },
        component: ServicesWassaliComponent,
      },
      {
        path: 'ecolo-x-wassali',
        data: {
          title: 'Wassali pour un Transport écologique',
          descrption: 'Découvrez la solution wassali pour un environnement meilleur avec un transport routier responsable allant du retour à vide jusqu’au groupage et des solutions pour réduire l’impact du transport de marchandises sur notre environnement.',
          robots: 'index, follow',
          keywords: 'écologie, économie, économique, social, sociétal, transport tunisie, mobilité, mobilité durable, écomobilité, développement durable, environnement, transport durable, transport écologique, transport digitalisé, transport digital',
          ogUrl: "/ecolo-x-wassali",
        },
        component: EcoloXWassaliComponent,
      },
      {
        path: 'grouping-wassali',
        data: {
          title: 'Wassali | Groupage de marchandise avec Wassali',
          descrption: "Wassali offre une solution de groupage de marchandises sur tout le territoire tunsien et ce en raison d'économiser vos transactions et optimiser le chargement des camions.",
          keywords: 'transport tunisie, groupage transport, groupage transport tunisie, groupage marchandise, groupage transport de marchandise, groupage transport particulier, transport groupage prix, groupage wassali transport, groupage transport tunis, groupage transport nabeul, groupage transport sousse, groupage transport sfax, groupage transport djerba, ewassaly, wassalny, tawssila, wassali, wassali transport express, wassaly, groupage transport, groupage transport tunisie, groupage marchandise, groupage transport de marchandise, groupage transport particulier, transport groupage prix, groupage wassali transport, groupage transport tunis, groupage transport nabeul, groupage transport sousse, groupage transport sfax, groupage transport djerba',
          robots: 'index, follow',
          ogUrl: "/grouping-wassali",
          // ogTitle: 'Description of First Component for social media',
        },
        component: GroupingWassaliComponent, canActivate: [GuardGuard],
      },
      {
        path: 'inscription-wassali',
        data: {
          title: 'Expediteur ou Transporteur ? Créer votre compte Wassali',
          descrption: 'Rejoignez Wassali pour faciliter votre vie et bénéficier d’un service flexible, optimisé et garantie.',
          robots: 'index, follow',
          keywords: 'ewassali, wassali, wassali transport, Transporteur Tunisie, transport tunisie, application wassali, groupage transport tunis ,Transport Sfax, Transport sousse, Transport tunis, Transport Djerba, Transport Bizerte, Service Transport, transport pas cher tunisie, transport prix tunisie',
          ogUrl: "/inscription-wassali",
        },
        component: InscriptionWassaliComponent,
      },
    ]
  },
  { path: '', redirectTo: '', pathMatch: 'full' },

  {
    path: 'facture-grouping',
    data: {
      title: 'eWASSALI Groupage | Facture Groupage Wassali',
      // descrption: 'Wassali est une plateforme qui met en relation un client avec un transporteur sur toute la Tunisie, visant l’optimisation des retours à vide des camions.',
      keywords: 'ewassali, wassali, wassali transport, facture, Transporteur Tunisie, transport tunisie, Transporteur tunisie Prix, Transporteur sfax, Transporteur Sousse, Transporteur Tunis, Transporteur Djerba, Transporteur Bizerte, déménagement prix',
      robots: 'index, follow',
      ogUrl: "/facture-grouping",
      // ogTitle: 'Description of First Component for social media',
    },
    component: FactureGroupingComponent, canActivate: [GuardGuard]
  },

  {
    path: 'login',
    data: {
      title: 'eWASSALI Tunisie | Transport Express',
      descrption: 'Wassali est une plateforme qui met en relation un client avec un transporteur sur toute la Tunisie, visant l’optimisation des retours à vide des camions.',
      keywords: 'ewassali, wassali, wassali transport, Transporteur Tunisie, transport tunisie, Transporteur tunisie Prix, Transporteur sfax, Transporteur Sousse, Transporteur Tunis, Transporteur Djerba, Transporteur Bizerte, déménagement prix',
      robots: 'index, follow',
      ogUrl: "/login",
      // ogTitle: 'Description of First Component for social media',
    },
    component: LoginComponent
  },
  {
    path: 'forget-password',
    data: {
      title: 'eWASSALI Tunisie | Transport Express',
      descrption: 'Wassali est une plateforme qui met en relation un client avec un transporteur sur toute la Tunisie, visant l’optimisation des retours à vide des camions.',
      keywords: 'ewassali, wassali, wassali transport, Transporteur Tunisie, transport tunisie, Transporteur tunisie Prix, Transporteur sfax, Transporteur Sousse, Transporteur Tunis, Transporteur Djerba, Transporteur Bizerte, déménagement prix',
      robots: 'index, follow',
      ogUrl: "/forget-password",
      // ogTitle: 'Description of First Component for social media',
    },
    component: ForgetPasswordComponent
  },
  {
    path: 'sign-up',
    data: {
      title: 'eWASSALI Tunisie | Transport Express',
      descrption: 'Wassali est une plateforme qui met en relation un client avec un transporteur sur toute la Tunisie, visant l’optimisation des retours à vide des camions.',
      keywords: 'ewassali, wassali, wassali transport, Transporteur Tunisie, transport tunisie, Transporteur tunisie Prix, Transporteur sfax, Transporteur Sousse, Transporteur Tunis, Transporteur Djerba, Transporteur Bizerte, déménagement prix',
      robots: 'index, follow',
      ogUrl: "/sign-up",
      // ogTitle: 'Description of First Component for social media',
    },
    component: InscriptionComponent
  },

  


      //////////////////////// BEGIN PAGE WASSALNI WEB SITE //////////////////////
      {
        path: 'privacy-policy-wassalni',
        data: {
          title: 'Politique de confidentialité - Wassalni',
          robots: 'index, follow',
          ogUrl: "/privacy-policy-Wassalni",
        }, component: PrivacyPolicyWassalniComponent,
      },
      {
        path: 'user-agreement-wassalni',
        data: {
          title: "Conditions d'utilisation - Wassalni",
          robots: 'index, follow',
          ogUrl: "/user-agreement-Wassalni",
        }, component: UserAgreementWassalniComponent,
      },
      //////////////////////// END PAGE WASSALNI WEB SITE //////////////////////
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-wassali',
  templateUrl: './about-wassali.component.html',
  styleUrls: ['./about-wassali.component.css']
})
export class AboutWassaliComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

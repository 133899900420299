<div id="bookbutton">
    <div class="col-xs-12 bookbuttondiv">
        <a [routerLink]="['/']" class="btn bookbutton" style="border-radius: 83px;">Passer la demande</a>
    </div>
</div>
<div class="custom-header custom-header-img"></div>
<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama.png) bottom center repeat-x">
    &nbsp;</div>
<!-- BODY -->
<div class="container">
    <div class="col-sm-12">
        <div class="row Transfer">
            <p>&nbsp;</p>
            <h1>Conditions D’utilisation</h1>
            <div class="col-sm-12 control-label2">
                <p>&nbsp;</p>

                <h5 style="font-size: 22px;
                line-height: normal;">Application <b>Wassali</b></h5>
                <p style="text-align:justify">
                    Date de dernière mise à jour : 14/01/2022
                    <br><br>
                    Ces conditions d’utilisation constituent un accord (l’« Accord ») entre vous et l’application
                    <b>Wassali</b> (« <b>Wassali</b> » ou « nous » et leurs pronoms associés) qui régit votre utilisation de
                    l’application <b>Wassali</b> et du site Web.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">1. Acceptez Les Conditions D’utilisation</h5>
                <!-- <h5 style="font-size: 22px;
                line-height: normal;">1.1. Important :</h5> -->
                <p style="text-align:justify">
                    1.1. Important : En utilisant ce service, vous reconnaissez avoir lu, compris, accepté et accepté ces termes et
                    conditions. Vous acceptez également les déclarations que vous faites vous-même ci-dessous. Si vous
                    n’acceptez pas ou n’êtes pas soumis aux Conditions d’utilisation (telles que définies ci-dessous) et
                    souhaitez cesser d’utiliser le Service, veuillez ne pas continuer à utiliser cette application ou ce
                    service.
                    <br><br>
                    1.2. Ces Conditions D’utilisation (Collectivement Les « Conditions D’utilisation
                    » Ou Le Présent « Accord ») Constituent Un Accord Juridique Entre Vous Et <b>Wassali</b> Et Ses Filiales Et
                    Sociétés Affiliées (« <b>Wassali</b> »).
                    <br><br>
                    Aux fins d’utilisation du Service (tel que défini ci-dessous), vous devez accepter les Conditions
                    d’utilisation énoncées ci-dessous. En utilisant les applications mobiles et les sites Web mis à
                    votre disposition par <b>Wassalni Express</b> (l’« Application »), et en téléchargeant, en installant ou en
                    utilisant tout logiciel connexe fourni par <b>Wassalni Express</b> (le « Logiciel »), vous reconnaissez et
                    acceptez expressément d’être lié par les Conditions d’utilisation et de toutes modifications et
                    ajouts futurs à ceux-ci, qui sont publiés de temps à autre via l’application.
                    <br><br>
                    En installant <b>Wassali</b> sur votre appareil mobile ou votre ordinateur, vous acceptez
                    inconditionnellement toutes les règles, conditions et informations publiées sur l’application
                    <b>Wassali</b> elle-même et sur le site Web, y compris, mais sans s’y limiter : ces conditions
                    d’utilisation et cette politique de confidentialité ainsi que le Directives d’utilisation de
                    l’application mobile mobile « <b>Wassali</b> ».
                    <br><br>
                    1.3. <b>Wassali</b> Se Réserve Le Droit De Modifier, Altérer Et Changer Les Conditions
                    D’utilisation Ou Ses Politiques Relatives Au Service À Tout Moment Comme Bon Lui Semble.
                    <br><br>
                    Ces modifications, substitutions ou changements aux Conditions d’utilisation ou à ses politiques
                    relatives au Service entreront en vigueur immédiatement après la publication d’une version mise à
                    jour dans l’Application. Vous acceptez d’être responsable de la révision régulière des conditions
                    d’utilisation ainsi que des conditions d’utilisation applicables à tout pays dans lequel vous
                    utilisez le service, et de continuer à utiliser le service après que de tels changements, que vous
                    les ayez révisés ou non, constituent votre acceptation et votre acceptation de ces changements. Vous
                    acceptez également que l’utilisation du service dans un autre pays soit soumise aux conditions
                    d’utilisation du pays alternatif, qui peuvent être trouvées dans l’application.
                </p>
                <br>
                
                <h5 style="font-size: 22px;
                line-height: normal;">L’application <b>Wassali</b> Ne Fournit Pas De Services Aux Particuliers Dans Les États
                    Membres De L’Union Européenne Et De L’Espace Économique Européen.</h5><br><br>
                <h5 style="font-size: 22px;
                line-height: normal;">1.5. Chaque Service De Transport Fourni Par Un Chauffeur Partenaire À Un Passager
                    Constitue Un Accord Distinct Entre Eux.</h5>
                <p style="text-align:justify">
                    <b>Wassalni Express</b> est une entreprise technologique qui ne fournit pas de services de transport
                    directement et n’est pas un fournisseur de services de transport. La fourniture de services de
                    transport est limitée aux prestataires de services tiers et leur acceptation vous est limitée. Le
                    service fourni par la Société est de vous mettre en contact avec des fournisseurs de services de
                    transport, mais il ne fournit pas et n’est pas destiné à fournir des services de transport ou tout
                    travail qui pourrait de quelque manière être considéré comme le travail d’un fournisseur de services
                    de transport. La Société n’assume aucune responsabilité légale ou légale pour les actes ou omissions
                    de tout prestataire de services de transport ou l’omission des services de transport qui vous sont
                    fournis.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">2. Adminisibilités</h5>
                <p style="text-align:justify">
                    2.1. En utilisant le Service, vous déclarez et garantissez expressément que vous avez légalement le
                    droit d’accepter les Conditions d’utilisation et que vous avez dix-huit (18) ans. Sans limiter la
                    nature générale de ce qui précède, le Service n’est pas disponible pour les personnes de moins de
                    dix-huit (18) ans ou pour les personnes à qui il est interdit de conclure une relation contractuelle
                    pour quelque raison que ce soit.
                    <br><br>
                    En utilisant le Service, vous déclarez et garantissez également que vous avez le droit, l’autorité
                    et la capacité d’utiliser le Service et d’être lié par les Conditions d’utilisation. Vous confirmez
                    également que toutes les informations que vous fournissez sont vraies et exactes. Votre utilisation
                    du Service est strictement pour vous-même, et vous vous engagez à ne pas autoriser d’autres
                    personnes à utiliser votre identité ou votre statut d’utilisateur, et à ne pas céder ou transférer
                    votre compte d’utilisateur à une autre personne ou entité.
                    <br><br>
                    Lorsque vous utilisez le Service, vous acceptez de vous conformer à toutes les lois applicables, que
                    ce soit dans votre pays d’origine ou dans le pays, l’état ou la ville dans lequel vous vous trouvez
                    lors de l’utilisation du Service.
                    <br><br>
                    2.2. Vous ne pouvez accéder au Service qu’en utilisant des moyens autorisés. Il est de votre
                    responsabilité de vérifier et de vous assurer que vous téléchargez le bon logiciel pour votre
                    appareil. <b>Wassali</b> n’est pas responsable si vous n’avez pas d’appareil compatible ou si vous
                    téléchargez la mauvaise version du logiciel sur votre appareil. <b>Wassali</b> se réserve le droit de vous
                    interdire d’utiliser le Service si vous utilisez l’Application ou le Logiciel avec un appareil
                    incompatible ou non autorisé ou à des fins autres que l’utilisation prévue du Logiciel.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">3. Prix Et Paiements</h5>
                <p style="text-align:justify">
                    Le système de paiement <b>Wassali</b> est un système par lequel un passager soumet une proposition de prix
                    de course et ne confirme pas une demande de course à moins que le transporteur n’accepte ce prix
                    affiché.
                    <br><br>
                    Le « tarif moyen du voyage » que nous pouvons vous afficher est le tarif moyen proposé par les
                    transporteurs au grand public pour la distance exacte ou approximative que vous avez spécifiée. Ces
                    prix dépendent de l’heure à laquelle vous avez demandé le trajet et de la région que vous avez
                    sélectionnée et le service demandé. Nous n’offrons aucune garantie quant à l’exactitude ou
                    l’exhaustivité des données utilisées ou fournies
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">4. Licence Et Limitations</h5>
                <p style="text-align:justify">
                    4.1. licence. Sous réserve de votre respect des présentes Conditions d’utilisation, l’Application
                    <b>Wassali</b> vous accorde une licence restreinte, non exclusive, non transférable, non cessible et
                    révocable pour : (a) accéder et utiliser l’Application sur votre appareil personnel uniquement dans
                    le but d’utiliser les Services ; et (b) accéder et afficher tout contenu ou matériel pouvant être
                    disponible via les Services, qui, dans tous les cas, sont destinés à votre usage personnel et non
                    commercial uniquement. Tous les droits qui ne vous sont pas expressément accordés dans les présentes
                    sont réservés à <b>Wassali</b> Corporation et aux concédants de licence de l’application <b>Wassali</b>.
                    <br><br>
                    4.2. Vous ne pouvez utiliser le Logiciel ou l’Application qu’à des fins personnelles et vous ne
                    pouvez pas utiliser le Logiciel ou l’Application pour :
                    <br><br>
                    (i) envoyer du spam ou d’autres messages répétitifs ou non sollicités ;
                    <br><br>
                    (ii) transmettre ou stocker du matériel contrefait, obscène, menaçant, diffamatoire, illégal ou
                    délictuel, y compris, sans s’y limiter, du matériel préjudiciable aux enfants ou qui enfreint les
                    droits à la vie privée d’un tiers ;
                    <br><br>
                    (iii) envoyer du matériel contenant des virus logiciels, des vers, des chevaux de Troie ou d’autres
                    codes informatiques, fichiers, scripts, agents ou programmes malveillants ;
                    <br><br>
                    (iv) interférer avec ou perturber l’intégrité, les performances ou les données du Logiciel ou de
                    l’Application ;
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">5. Propriété Intellectuelle</h5>
                <p style="text-align:justify">
                    Le Service comprend du contenu (tel que la conception, les images, les sons, le texte, les bases de
                    données, le code informatique, les marques déposées et non déposées et d’autres éléments similaires)
                    qui est détenu ou sous licence par <b>Wassali</b> et est protégé par le droit d’auteur, marque, brevet,
                    secret commercial et autres lois. <b>Wassali</b> et ses concédants de licence, le cas échéant, détiennent
                    tous les droits, titres et intérêts, y compris tous les droits de propriété intellectuelle liés au
                    Logiciel ou à l’Application, et par extension le Service et toute demande, recommandation ou autre
                    information faite par vous ou toute autre partie en rapport avec avec le Service.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">6.Activités Interdites</h5>
                <p style="text-align:justify">
                    Dans le cadre de votre utilisation de l’Application <b>Wassali</b> et de votre participation aux Services,
                    vous vous engagez à ne pas :
                    <br><br>
                    A. Se faire passer pour une personne ou une entité frauduleusement<br>
                    B. traquer, menacer, harceler ou porter des armes<br>
                    C. Violation de toute loi, statut, décret ou règlement<br>
                    D. Interférer avec ou perturber les services, serveurs ou réseaux connectés à l’application <b>Wassali</b><br>
                    E. Publier des informations ou interagir sur l’application ou les services Wassli d’une manière
                    fausse, inexacte, trompeuse (directement ou par omission ou défaut de mise à jour des informations),
                    diffamatoire, diffamatoire, abusive, obscène, vulgaire, abusive ou directionnelle sexuelle,
                    menaçante, harcelante , ou illégal.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">7. Protection Des Données Personnelles</h5>
                <p style="text-align:justify">
                    7.1. Vous acceptez et consentez à ce que <b>Wassali</b> utilise et traite vos données personnelles aux fins
                    et de la manière spécifiées ci-dessous.
                    <br><br>
                    7.2. Aux fins du présent accord, les données personnelles désignent les informations vous concernant
                    par lesquelles vous pouvez être identifié, y compris, mais sans s’y limiter, votre nom, votre numéro
                    de carte d’identité, votre adresse, votre numéro de téléphone ou les informations de votre carte de
                    crédit ou de débit. , date de naissance, adresse e-mail ou toute information vous concernant que
                    vous avez fournie à <b>Wassali</b> sur les formulaires d’inscription, formulaires de candidature ou autres
                    formulaires similaires ou toute information vous concernant qui a été ou peut être collectée,
                    stockée, utilisée et traitée par <b>Wassali</b> à partir du moment dans le temps et comprend des données
                    personnelles sensibles.
                    <br><br>
                    La fourniture de vos données personnelles est volontaire. Toutefois, si vous ne fournissez pas vos
                    données personnelles à <b>Wassali</b>, votre accès à l’Application peut être incomplet et <b>Wassali</b> ne pourra
                    pas traiter vos données personnelles aux fins décrites ci-dessous et cela peut empêcher <b>Wassali</b> de
                    vous permettre d’utiliser les services.
                    <br><br>
                    7.3. Vous acceptez que l’application <b>Wassali</b> puisse envoyer des notifications push, des e-mails et
                    communiquer avec vous par téléphone ou SMS (y compris via un système de numérotation automatique) à
                    l’un des numéros de téléphone fournis par vous ou en votre nom en relation avec votre compte
                    <b>Wassali</b>. , y compris à des fins de marketing. Vous comprenez que vous n’êtes pas tenu de fournir ce
                    consentement comme condition d’achat d’une propriété, de biens ou de services.
                    <br><br>
                    7.4 La politique de confidentialité de <b>Wassali</b> située à l’adresse
                    https://ewassali.tn/privacy-policy-wassali définit la collecte et l’utilisation des données
                    personnelles en relation avec les services.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">8. Indemnisation</h5>
                <p style="text-align:justify">
                    En acceptant les conditions d’utilisation lors de l’utilisation du service, vous acceptez de
                    défendre, d’indemniser et de tenir <b>Wassali</b> et ses dirigeants, administrateurs, membres, employés,
                    avocats et agents contre toute réclamation, coût, dommage, perte, responsabilités et dépenses (y
                    compris les honoraires et frais d’avocat) découlant de ce qui suit ou en relation avec celui-ci :<br>
                    (a) votre utilisation du Service, du Logiciel ou de l’Application, vos interactions avec des
                    fournisseurs de services de transfert tiers, des fournisseurs de services, des partenaires,
                    annonceurs ou sponsors, ou<br>
                    (b) votre violation ou violation de l’une des « Conditions d’utilisation » ou de toute loi ou
                    réglementation applicable, qu’elle soit ou non mentionnée dans les présentes ou<br>
                    (c) votre violation des droits d’un tiers, y compris les fournisseurs de services de transport tiers
                    avec lesquels vous vous coordonnez via le service, ou<br>
                    (d) votre utilisation ou mauvaise utilisation du service, du logiciel ou de l’application.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">9. EXCLUSION DE GARANTIES</h5>
                <p style="text-align:justify">
                    9.1. <b>Wassali</b> NE FAIT AUCUNE REPRÉSENTATION OU GARANTIE QUANT À LA FIABILITÉ, L’OPPORTUNITÉ, LA
                    QUALITÉ, LA PERTINENCE, LA DISPONIBILITÉ, L’EXACTITUDE OU L’EXHAUSTIVITÉ DES SERVICES. <b>Wassali</b> NE
                    REPRÉSENTE NI NE GARANTIT QUE (A) L’UTILISATION DU SERVICE, DE L’APPLICATION OU DU LOGICIEL SERA
                    SÉCURISÉE, OPPORTUNE, ININTERROMPUE OU SANS ERREUR
                    <br><br>
                    9.2. Le Service, l’Application ou le Logiciel peuvent être soumis à des limitations, des retards et
                    d’autres problèmes inhérents à l’utilisation d’Internet et des communications électroniques, y
                    compris si l’appareil que vous ou le fournisseur de services de transport tiers utilisez est
                    défectueux, déconnecté, hors de portée, éteint ou ne fonctionne pas. La Société n’est pas
                    responsable des retards, défauts de livraison, dommages ou pertes résultant de tels problèmes.
                </p>
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">10. Limitation De Responsabilité Légale</h5>
                <p style="text-align:justify">
                    10.1. Toute réclamation de votre part contre <b>Wassali</b> ne sera en aucun cas limitée au montant total
                    de tous les montants effectivement payés ou dus par vous en utilisant le Service au cours de
                    l’événement à l’origine de ces réclamations. En aucun cas, la Société ou ses concédants de licence
                    ne seront responsables envers vous ou quiconque de toute perte directe, indirecte, punitive,
                    économique, future spéciale, punitive, accessoire, consécutive ou autre de quelque nature que ce
                    soit (y compris les blessures corporelles, la détresse émotionnelle, la perte de données , biens,
                    revenus, profits, usufruit ou tout autre avantage économique).
                    Vous ne serez pas une entreprise pour vous-même ou pour toute personne pour laquelle vous avez
                    réservé le Service, y compris, sans s’y limiter, les pertes, dommages ou blessures résultant ou liés
                    de quelque manière que ce soit au Service, à l’Application ou au Logiciel, y compris, sans s’y
                    limiter , l’utilisation ou l’impossibilité d’utiliser le Service, l’Application ou le Logiciel, ou
                    toute confiance de votre part dans l’exhaustivité, l’exactitude ou l’existence de toute publicité,
                    ou à la suite de toute relation ou transaction entre vous et un fournisseur de services tiers,
                    l’annonceur ou le sponsor dont la publicité apparaît sur le Site ou le Service, l’Application ou le
                    Logiciel y fait référence, même si la Société ou ses concédants ont préalablement déclaré la
                    possibilité de tels dommages.<br>
                    La Société n’évalue pas et ne surveillera pas l’adéquation, la légalité, la capacité, le mouvement
                    ou l’emplacement de tout fournisseur de services tiers, y compris ces fournisseurs de services
                    tiers, annonceurs ou sponsors, et vous renoncez et libérez expressément la Société de toute
                    responsabilité ou Réclamations ou dommages résultant de ou liés à des fournisseurs de services
                    tiers, y compris des transporteurs tiers, des annonceurs ou des sponsors. <b>Wassali</b> ne sera pas partie
                    aux litiges ou négociations dans les litiges entre vous et nos fournisseurs de services tiers, y
                    compris les transporteurs tiers, les annonceurs ou les sponsors.
                    <br><br>
                    10.2. L’entière responsabilité de la qualité des services de transport tiers déterminée par
                    l’utilisation du Service incombe au fournisseur de services de transport tiers, qui vous fournit en
                    fin de compte ces services de transport. Vous comprenez qu’en utilisant le Service, vous pouvez être
                    exposé à un transport potentiellement dangereux, abusif, nocif pour les mineurs, dangereux ou
                    autrement répréhensible, et que vous utilisez le Service à vos risques et périls.
                    <br><br>
                    10.3. Services et contenus tiers : les Services peuvent être mis à disposition ou accessibles via
                    des services et contenus tiers (y compris la publicité) qu’Wassali ne contrôle pas. Vous
                    reconnaissez que différentes conditions d’utilisation ou politiques de confidentialité peuvent
                    s’appliquer à votre utilisation de ces services et contenus tiers. <b>Wassali</b> n’approuve pas ces
                    services et contenus tiers et en aucun cas <b>Wassali</b> ne sera responsable des produits ou services de
                    ces fournisseurs de services tiers.
                    <br><br>
                    10.4. Accès au réseau et aux appareils : Vous êtes responsable de l’accès au réseau de données
                    nécessaire pour utiliser les Services. Des tarifs et frais de données mobiles et de messagerie
                    peuvent s’appliquer si vous accédez ou utilisez les Services à partir d’un appareil de réseau sans
                    fil et que vous êtes responsable de ces tarifs et frais. Vous êtes également responsable de l’achat
                    et de la mise à jour des appareils compatibles qui sont nécessaires pour accéder et utiliser les
                    Services et les Applications et pour effectuer toute mise à jour de ceux-ci. <b>Wassali</b> ne garantit pas
                    que les Services, ou une partie de ceux-ci, fonctionneront sur des appareils particuliers. De plus,
                    les Services peuvent connaître des dysfonctionnements ou des retards résultant de l’utilisation
                    d’Internet et des communications électroniques.
                </p>
                <br>

                <p>&nbsp;</p>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-my-demandes',
  templateUrl: './my-demandes.component.html',
  styleUrls: ['./my-demandes.component.css']
})
export class MyDemandesComponent implements OnInit {

  listDemandes: any = []
  statusDemandes: any = [];

  constructor(private transServices: TransportorsService,) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )

    this._pendingDemandesDriver()
  }

  _pendingDemandesDriver() {
    this.transServices.pendingDemandesDriver().then((res: any) => {
      // console.log("eeeeeeeexx")
      // console.log(res)
      if (res) {
        this.listDemandes = res
      }
    }).catch((e) => {
      // console.log("eeeeeeeexx")
      // console.log(e)
    });
  }

  getStatusDemande(status) {
    if (status == "created") {
      return "En attente";
    } else if (status == "accepted") {
      return "Validé";
    } else if (status == "started") {
      return "En cours de préparation";
    } else if (status == "arrived") {
      return "Prête";
    } else if (status == "finish") {
      return "Terminé";
    } else {
      return "Annulé";
    }
  }

  goToDetails(demande) {
    if(demande.etat_demande == "accepted" || demande.etat_demande == "started" || demande.etat_demande == "arrived") {
      location.href = '/details-demande-transportor?demande=' + demande._id;
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UsersService } from '../users-wassali/users.service';

@Injectable({
  providedIn: 'root'
})
export class TransportorsService {

  constructor(private http: HttpClient,
    private usersService: UsersService,
    private router: Router) { }

  goToNewTab(idGrouping, route) {
    const queryParams = {
      grouping: idGrouping
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

  ///// FOUNIX
  async allTypePalettes() {
    return await new Promise((slv) => {
      this.http.post(environment.apiTypesPalettes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }
  async allFamilies() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllFamilies, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }
  async allSubFamily(idFamily) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllSubFamily, {
        "idFamily": idFamily
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async createGrouping(newGrouping) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCreateGrouping, newGrouping).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async listGrouping() {
    var idUser = await this.usersService.getIdUser()
    return await new Promise((slv) => {
      this.http.post(environment.apiListGrouping, { "idUser": idUser }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  getLabeledStatusGroupage() {
    return new Promise((slv) => {
      this.http.post(environment.apiStatusGroupage, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getOneGrouping(ref) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneGrouping + "/" + ref, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  ///// FOUNIX

  // goToNewTab(idDemande, route) {
  //   const queryParams = {
  //     demande: idDemande
  //   };
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree([route], {
  //       queryParams: queryParams,
  //       // relativeTo: this.activeRoute,
  //       // skipLocationChange: true
  //     })
  //   );

  //   window.open(url, '_blank');
  // }

  // goToRoute(idDemande, route, r) {
  //   const queryParams = {
  //     demande: idDemande
  //   };
  //   this.router.serializeUrl(
  //     this.router.createUrlTree([route], {
  //       queryParams: queryParams,
  //       relativeTo: r,
  //       skipLocationChange: false
  //     })
  //   );
  // }

  async allTypeServices() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllTypeService, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async allTypeCamions() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllTypeCamion, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async statisticUser() {
    return await new Promise((slv) => {
      this.http.post(environment.apiStatisticUser, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async createDemade(demande) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCreateDemande, demande).subscribe(
        (data: any) => {
          // console.log("create demande")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async userHasDemande() {
    var idUser = await this.usersService.getIdUser()
    return await new Promise((slv) => {
      this.http.post(environment.apiUserHasDemande, { idUser: idUser }).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async refuseDemande(idDemande, idResponse) {
    return await new Promise((slv) => {
      this.http.post(environment.apiRefuseDemande, {
        "idDemande": idDemande, "idResponse": idResponse
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async accepteDemande(responseDemande) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAccepteDemande, responseDemande).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async getPropositionsDemandeNotRefused(idDemande) {
    return await new Promise((slv) => {
      this.http.post(environment.apiPendingProposition, {
        "idDemande": idDemande
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async updateDemande(idDemande, newStatus, msg?) {
    var updateDemande = {
      "idDemande": idDemande,
      "newstatus": newStatus
    };
    if (msg != null)
      updateDemande["msgReturned"] = msg;

    // console.log("updateDemande - msg")
    // console.log(updateDemande)
    return await new Promise((slv) => {
      this.http.post(environment.apiUpdateDemande, updateDemande).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async causeCancelDemande() {
    return await new Promise((slv) => {
      this.http.post(environment.apiCauseCancelDemande, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async pendingDemandesDriver() {
    var idUser = await this.usersService.getIdUser()
    return await new Promise((slv) => {
      this.http.post(environment.apiDemandesUser, { "idUser": idUser }).subscribe(
        (data: any) => {
          // console.log("data")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async getOneDemande(demandeId) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneDemande, { "idDemande": demandeId }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  getLabeledStatusDemande() {
    return new Promise((slv) => {
      this.http.post(environment.apiStatusDemandes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getPositionDriver(idDriver) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetPositionDriver, { "idDriver": idDriver }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getDistances(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return {
      totalDistance: d.toFixed(1),
      totalDuration: (((d * 1000) / 15) / 60).toFixed(0)
    };
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }
}


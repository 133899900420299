import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users-wassali/users.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  isConnected: boolean = false
  user: any = null

  constructor(private usersService: UsersService,
    private router: Router,) {
    this.isConnected = this.usersService.isConnected()
  }

  async ngOnInit() {
    await this.getInfosUser()
  }

  async getInfosUser() {
    await this.usersService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.user = res;
      }
    });
  }

  async deconnexion() {
    await this.usersService.deconnexion()
    // this.router.navigate(['/home'])
    window.location.reload()
    this.router.navigate(['/'])
  }

  async connecte() {
    // window.location.reload()
    this.router.navigate(['/login'])
  }
}

<div class="google-image">
    <div id="map">
        <div #map id="map-canvas"></div>
        <div class="map-loader-container" *ngIf="!mapLoaded">
            <div class="map-loader"></div>
        </div>
    </div>
</div>

<div class="booking-form-wrapper" style="z-index: 51" id="priceCalc1">
    <div class="col-sm-12">
        <div class="row">
            <div class="form-wrap" style="border-radius: 10px;">
                <!-- <div class="form-headr"></div> -->
                <div class="minmax"><span id="minimize"><a href="javascript:void(0)" onClick="minmax('min')"
                            class="dark-btn" data-toggle="tooltip" title="Click to Minimize"><span
                                class="fa-stack fa-sm"><i class="fa fa-square-o fa-stack-2x dark-btn"></i><i
                                    class="fa fa-window-minimize fa-stack-1x dark-btn"></i></span></a></span></div>
                <h2 style="line-height: 100%; padding:10px !important; text-transform: uppercase;
                font-family: Circular,'Helvetica Neue',Helvetica,Arial,sans-serif;
                text-shadow: 0 1px 1px #49515e;
                font-weight: 700;
                text-rendering: optimizelegibility; margin-top: 12px;"> Comment prendre WASSALI ? </h2>
                <p id="maximize" style="text-align:center; display:none; margin-top:-10px"><a href="javascript:void(0)"
                        onClick="minmax('max')" class="dark-btn" data-toggle="tooltip" title="Click to Maximize"
                        data-placement="bottom"><span class="fa-stack fa-sm"><i
                                class="fa fa-square-o fa-stack-2x dark-btn"></i><i
                                class="fa fa-window-maximize fa-stack-1x dark-btn"></i></span></a></p>
                <div class="form-select">
                    <form action="javascript:void(0);" method="post" name="fm" id="fm">
                        <!-- From -->
                        <div class="col-sm-6 booking-fields bf3">
                            <div class="row input-group input-group-lg select2-bootstrap-append">
                                <input type="text" class="form-control" name="search-from"
                                    aria-describedby="search-from" [(ngModel)]="fromPlace"
                                    (input)="_fetchAllPlaceFrom($event.target.value)" placeholder="Lieu de départ"
                                    autofocus>
                                <ul class="list-group list-group-flush list-places"
                                    *ngIf="listPlacesFrom.length > 0 && fromPlace!=''">
                                    <li class="list-group-item select2-results__option select2-results__message"
                                        *ngFor="let c of listPlacesFrom" appHighlight [content]="c"
                                        [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                                        (click)="selectPlaceFrom($event)">
                                        {{c.description}}
                                    </li>
                                </ul>

                                <span class="input-group-btn">
                                    <button (click)="showLocation()" class="btn form-btn"
                                        style="margin-top:0.5px; padding-bottom:30px"><i
                                            class="fa fa-map-marker"></i></button>
                                </span>
                            </div>
                            <input type="hidden" id="startVal" value="">
                        </div>
                        <!-- From -->
                        <!-- Where -->
                        <div class="col-sm-6 booking-fields bf4">
                            <div class="row input-group input-group-lg select2-bootstrap-append">
                                <input type="email" class="form-control" name="search-where"
                                    aria-describedby="search-where" [(ngModel)]="wherePlace"
                                    (input)="_fetchAllPlaceWhere($event.target.value)" placeholder="Destination"
                                    autofocus>
                                <ul class="list-group list-group-flush list-places"
                                    *ngIf="listPlacesWhere.length > 0 && wherePlace!=''">
                                    <li class="list-group-item select2-results__option select2-results__message"
                                        *ngFor="let c of listPlacesWhere" appHighlight [content]="c"
                                        [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                                        (click)="selectPlaceWhere($event)">
                                        {{c.description}}
                                    </li>
                                </ul>

                                <span class="input-group-btn">
                                    <button (click)="showDestination()" class="btn form-btn"
                                        style="margin-top:0.5px; padding-bottom:30px"><i
                                            class="fa fa-map-marker"></i></button>
                                </span>
                            </div>
                            <input type="hidden" id="endVal" value="">
                        </div>
                        <!-- Where -->
                        <!-- Palette -->
                        <div class="col-sm-12 bf6" id="route" style="margin: 10px 0px; overflow: hidden;"
                            *ngIf="_dataTargetLocation!=null && _dataTargetDestination != null">
                            <div class="demo">
                                <table style="width:100%">
                                    <tr style="margin-top: auto;">
                                        <td style="width:23%; color: white; font-size: 20px;"
                                            *ngFor="let pal of allPalettes">
                                            <input type="radio" id="palette" name="palette" value="{{pal._id}}"
                                                (change)="getTypePalette($event)">
                                            {{pal.name}}
                                        </td>
                                        <td style="width:23%; color: white; font-size: 20px;">
                                            <input type="radio" id="palette" name="palette" value="other"
                                                (change)="getTypePalette($event)">
                                            Autre
                                        </td>
                                        <td style="width:31%; color: white; font-size: 20px;">
                                            <input type="number"
                                                class="select2-selection select2-selection--single form-control input-lg"
                                                name="quantity" min="1" placeholder="Quantité" [(ngModel)]="quantity"
                                                (input)="calculPricePalette()">
                                        </td>
                                    </tr>
                                    <!-- <tr *ngFor="let pal of allPalettes" style="margin-top: auto;">
                                        <td style="width:10%">
                                            <input type="radio" id="palette" name="palette" value="{{pal._id}}"
                                                (change)="getTypePalette($event)">
                                        </td>
                                        <td style="width:22.5%; color: white; font-size: 20px;">
                                            {{pal.name}}
                                        </td>
                                        <td style="width:22.5%; color: white; font-size: 20px;">
                                            Longueur: {{pal.longueur}}
                                        </td>
                                        <td style="width:22.5%; color: white; font-size: 20px;">
                                            Largeur: {{pal.largeur}}
                                        </td>
                                        <td style="width:22.5%; color: white; font-size: 20px;">
                                            Hauteur: {{pal.hauteur}}
                                        </td>
                                    </tr> -->
                                    <!-- <tr *ngIf="selectedPalette!=null && selectedPalette._id=='other'"> -->
                                    <!-- <td style="width:10%">
                                            <input type="radio" id="palette" name="palette" value="other"
                                                (change)="getTypePalette($event)">
                                        </td>
                                        <td style="width:22.5%; color: white; font-size: 20px;">
                                            Autre
                                        </td> -->
                                    <!-- <td style="width:40%; color: white; font-size: 20px;">
                                            <input type="number" class="form-control" name="other_longueur"
                                                placeholder="Longueur" [(ngModel)]="other_longueur"
                                                (input)="inputOtherLongueur($event.target.value)" [disabled]="selectedPalette == null || (selectedPalette != null && 
                                             selectedPalette?._id!=null && selectedPalette?._id != 'other')">
                                        </td>
                                        <td style="width:40%; color: white; font-size: 20px;">
                                            <input type="number" class="form-control" name="other_largeur"
                                                placeholder="Largeur" [(ngModel)]="other_largeur"
                                                (input)="inputOtherLargeur($event.target.value)" [disabled]="selectedPalette == null || (selectedPalette != null && 
                                             selectedPalette?._id!=null && selectedPalette?._id != 'other')">
                                        </td>
                                        <td style="width:40%; color: white; font-size: 20px;">
                                            <input type="number" class="form-control" name="other_hauteur"
                                                placeholder="Hauteur" [(ngModel)]="other_hauteur"
                                                (input)="inputOtherHauteur($event.target.value)" [disabled]="selectedPalette == null || (selectedPalette != null && 
                                             selectedPalette?._id!=null && selectedPalette?._id != 'other')">
                                        </td> -->
                                    <!-- </tr> -->
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-12 bf7" id="other" style="margin: 10px 0px; overflow: hidden;"
                            *ngIf="selectedPalette!=null && selectedPalette._id=='other'">
                            <div class="demo">
                                <table style="width:100%">
                                    <tr>
                                        <td style="width:33%; color: white; font-size: 20px;">
                                            <div class="container-quantity">
                                                <input type="number"
                                                    class="select2-selection select2-selection--single form-control input-lg"
                                                    name="other_longueur" style="padding-right: 35px;"
                                                    placeholder="Longueur" [(ngModel)]="other_longueur" min="1"
                                                    (input)="inputOtherLongueur($event.target.value)" [disabled]="selectedPalette == null || (selectedPalette != null && 
                                     selectedPalette?._id!=null && selectedPalette?._id != 'other')">
                                                <span class="cm">cm</span>
                                            </div>
                                        </td>
                                        <td style="width:33%; color: white; font-size: 20px;">
                                            <div class="container-quantity">
                                                <input type="number"
                                                    class="select2-selection select2-selection--single form-control input-lg"
                                                    name="other_largeur" style="padding-right: 35px;"
                                                    placeholder="Largeur" [(ngModel)]="other_largeur" min="1"
                                                    (input)="inputOtherLargeur($event.target.value)" [disabled]="selectedPalette == null || (selectedPalette != null && 
                                     selectedPalette?._id!=null && selectedPalette?._id != 'other')">
                                                <span class="cm">cm</span>
                                            </div>
                                        </td>
                                        <td style="width:34%; color: white; font-size: 20px;">
                                            <div class="container-quantity">
                                                <input type="number"
                                                    class="select2-selection select2-selection--single form-control input-lg"
                                                    name="other_hauteur" style="padding-right: 35px;"
                                                    placeholder="Hauteur" [(ngModel)]="other_hauteur" min="1"
                                                    (input)="inputOtherHauteur($event.target.value)" [disabled]="selectedPalette == null || (selectedPalette != null && 
                                     selectedPalette?._id!=null && selectedPalette?._id != 'other')">
                                                <span class="cm">cm</span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <!-- Palette -->
                        <!-- Carousel -->
                        <!-- <div class="col-md-12 bf6" id="route" style="text-align: center; overflow: hidden;"
                            *ngIf="allCamion.length > 0 && _dataTargetLocation!=null && _dataTargetDestination != null">
                            <div class="demo">
                                <carousel class="carousel-html-content" style="z-index:0;" [cellWidth]="300">
                                        <div class="carousel-cell" *ngFor="let camion of allCamion"
                                        (click)="selectCamion(camion)"
                                        [class]="(dataServiceDemande!=null && dataServiceDemande.name==camion.name) ? 'camion-selected' : ''">
                                        <div class="row" style="width: 100%;">
                                            <div class="col-md-12">
                                                <div class="col-sm-12 tariffs-header-home">
                                                    <div class="tariffs-vehicle-wrap-home">
                                                        <div class="tariffs-vehicle">
                                                            <img src="{{camion.image}}" alt="Moto Taxi" width="146">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 tariffs-vehicle-div">
                                                    <div class="text-center">
                                                        <h6 class="mat-h6-home">{{camion?.name}}</h6>
                                                    </div>
                                                    <div
                                                        class="description-service description-service-border text-center">
                                                        {{camion?.service}}<br>
                                                        <div class="col-md-12" id="route"
                                                            style="text-align: center; overflow: hidden;">
                                                            <span id="aPoint"
                                                                style="font-size:18px; font-weight:bold">{{camion?.masse?.min}}
                                                            </span>
                                                            <img src="assets/images/direct-arrow.svg"
                                                                style="width: 12px; height: 12px;" alt="Direction">
                                                            <span id="bPoint"
                                                                style="font-size:18px; font-weight:bold">{{camion?.masse?.max}}
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="center"
                                            *ngIf="dataServiceDemande!=null && dataServiceDemande.name==camion.name">
                                            <img style="width: 40px;" src="assets/images/check-mark.png">
                                        </div>

                                    </div>
                                </carousel>
                            </div>
                        </div> -->
                        <!-- Carousel -->
                        <!-- Family Type -->
                        <div class="col-sm-4 booking-fields bf1"
                            *ngIf="dataPaletteIsValid() == true && quantity!=null && quantity!=''">
                            <div class="row">
                                <ng-select [items]="listFamilies" name="family" bindLabel="name"
                                    placeholder="Selectionner la famille" [(ngModel)]="dropdownTypeFamily"
                                    (change)="selectFamily();">
                                </ng-select>
                            </div>
                        </div>
                        <!-- Family Type -->
                        <!-- Sub Family Type -->
                        <div class="col-sm-4 booking-fields bf2"
                            *ngIf="dataPaletteIsValid() == true && quantity!=null && quantity!=''">
                            <div class="row">
                                <ng-select [items]="listSubFamilies" name="subFamily" bindLabel="name"
                                    placeholder="Selectionner sous famille" [(ngModel)]="dropdownTypeSubFamily"
                                    [disabled]="dropdownTypeFamily==null">
                                </ng-select>
                            </div>
                        </div>
                        <!-- Sub Family Type -->
                        <!-- Sub Family Type -->
                        <div class="col-sm-4 booking-fields bf8"
                            *ngIf="dataPaletteIsValid() == true && quantity!=null && quantity!=''">
                            <div class="row">
                                <ng-select [items]="plastifications" name="plast" bindLabel="label"
                                    placeholder="Plastifier ou non" [(ngModel)]="isPlastification">
                                </ng-select>
                            </div>
                        </div>
                        <!-- Sub Family Type 
                        [class]="(isPlastification == null) ? 'not-displayed-date-time-picker' : ''"
                    -->
                        <div class="col-sm-5 booking-fields bf5 text-center"
                            style="text-align:center; overflow: hidden; display: block;margin-top: 10px;"
                            *ngIf="dataPaletteIsValid() == true && quantity!=null && quantity!=''">
                            <div class="row">
                                <span style="color:#ffffff; font-size: 20px;">Prélevé le payement:
                                    &nbsp;&nbsp;&nbsp;</span>
                                <input type="checkbox" id="withPayment" [(ngModel)]="withPayment" name="withPayment">
                            </div>
                        </div>
                        <!-- Date Time 
                            [class]="(dataPaletteIsValid() == true && quantity!=null && quantity!='') ? '' : 'not-displayed-date-time-picker'"
                        
                        *ngIf="dataPaletteIsValid() == true && quantity!=null && quantity!=''"-->
                        <div class="col-sm-5 booking-fields bf5"
                        *ngIf="dataPaletteIsValid() == true && quantity!=null && quantity!=''">
                            <div class="row">
                                <table style="width:100%">
                                    <tr>
                                        <td style="width:60%">
                                            <!-- <input class="form-control daypicker input-lg" placeholder="Planifier"
                                                autocomplete="off" id="datepicker" name="datepicker" /> -->
                                            <div class="row">
                                                <input class="form-control input-lg" name="datepickerrr" matInput
                                                    [min]="startDate" [matDatepicker]="picker" placeholder="Planifier" 
                                                    [(ngModel)]="valueDatepicker">
                                                <span class="cm" style="top: 2px; width: 50%;">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </span>
                                            </div>

                                        </td>
                                        <td style="width:40%">
                                            <div class="row">
                                                <!-- <input class="form-control hourpicker input-lg" placeholder="Heure"
                                                autocomplete="off" id="timepicker" name="timepicker" /> -->
                                                <ng-select [items]="listTimepicker" name="timepicker" bindLabel="value"
                                                    placeholder="Heure" [(ngModel)]="valueTimepicker">
                                                </ng-select>
                                                <span class="hour-picker"></span>
                                                <!-- <ng-select  name="timepicker" bindLabel="value"
                                                placeholder="Heure" appendTo="body"
                                                    [searchable]="true" [clearable]="true"
                                                    [(ngModel)]="valueTimepicker">
                                                    <ng-option [value]="item.value" class="form-control hourpicker input-lg"
                                                        *ngFor="let item of listTimepicker">
                                                        {{item.value}}
                                                    </ng-option>
                                                </ng-select> -->
                                                <!-- <span class="hourpicker" style="top: 2px; width: 50%;">
                                                    <i class="fas fa-times"></i>
                                                </span> -->
                                            </div>

                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <!-- <div class="col-sm-4 booking-fields bf5">
                            <div class="row">
                                <input class="form-control input-lg" name="datepickerrr" matInput [min]="startDate"
                                    [matDatepicker]="picker" placeholder="Planifier">
                                <span class="cm" style="top: 0px;">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </span>
                            </div> -->
                        <!-- <div class="row">
                                <mat-form-field>
                                    <mat-label>Enter a date range</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                      <input matStartDate matInput placeholder="Start date">
                                      <input matEndDate matInput placeholder="End date">
                                    </mat-date-range-input>
                                    <input class="form-control" name="datepickerrr" matInput [min]="startDate"
                                        [matDatepicker]="picker" placeholder="Pick a date">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>
                            </div> -->
                        <!-- </div> -->


                        <!-- Date Time -->
                        <!-- <div class="col-md-12" id="mapLoader"
                            style="display:none; text-align:center; padding:10px 0 10px 0"
                            *ngIf="dataServiceDemande != null">
                            <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                        </div> -->
                        <!-- <div class="col-md-12" id="bookStatus"
                            style="display:none; text-align:center; color:#c30; font-weight:bold; margin:20px 5px 10px 5px">
                        </div> -->
                        <!-- <div class="col-md-12" style="text-align: center; color: rgb(204, 51, 0); font-weight: bold; 
                        margin: 20px 5px 10px; overflow: hidden;">
                            <h4>Select Ride Type</h4>
                        </div> -->

                        <div class="col-md-12"
                            style="text-align: center; color: rgb(204, 51, 0); font-weight: bold; margin: 5px 5px 10px; overflow: hidden; display: block;"
                            *ngIf="msgValidation != ''">
                            <h7>{{ msgValidation }}</h7>
                        </div>

                        <!-- <input type="hidden" id="costVal" value="">
                        <div class="col-md-12" id="route" style="display:none; text-align:center">
                            <h5 id="aPoint">&nbsp;</h5> <img src="assets/images/bidirectional-arrows.svg" width="12"
                                height="12" id="imgDirection" alt="Direction">
                            <h5 id="bPoint">&nbsp;</h5>
                        </div>
                        <input type="hidden" id="slideVal" value="slide"> -->
                        <!-- <div class="col-md-12" style="text-align:center; overflow: hidden; display: block;" *ngIf="priceTotal!=null">
                            <h2 style="padding:0 !important">
                                <span style="color:#ffffff">Prix Total: </span> {{priceTotal | number:'0.3'}}
                                <span class="count" id="aboneSpan">TND</span>
                            </h2>
                        </div> -->
                        <div *ngIf="msgValidation == ''" class="form-button col-md-12" id="bookingButton"
                            style="padding:0 20px 0 20px;">
                            <button type="submit" class="btn form-btn btn-lg btn-block"
                                (click)="_createGroupage()">Passer le groupage</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama.png) bottom center repeat-x">
    &nbsp;</div>
<!-- BODY -->
<div class="label-white">
    <div class="container">
        <div class="row">
            <div class="col-sm-12" *ngFor="let group of listGroupings">
                <div class="row">
                    <div class="label-item">
                        <div class="containt-font img-circle">
                            <img src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + ((group?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                            ? group?.user_transportor?.profile_user : 'profile.jpg')}}" alt="Client groupage wassali"
                                width="62" height="62" style="border-radius: 50%; border: 3px solid #013274;" />
                        </div>
                        <div class="col-sm-7">
                            <div class="row">
                                <div class="containt-text" style="width: 100%;">
                                    <h4>{{group?.user_location?.label_target}}</h4>
                                    <h4>{{group?.user_destination?.label_target}}</h4>
                                    <!-- <h4 style="color: red; font-weight: 700;">{{group?.price + " TND"}}</h4> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="row">
                                <div class="containt-text">
                                    <h4 class="text-center text-{{statusGroupage[group?.etat_grouping]?.color}}">
                                        {{statusGroupage[group?.etat_grouping]?.label}}
                                    </h4>
                                    <button type="submit" class="btn form-btn btn-lg btn-block"
                                        *ngIf="group?.etat_grouping== 'created' || group?.etat_grouping== 'validated'"
                                        (click)="goToNewTab(group._id, '/facture-grouping')">Imprimer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
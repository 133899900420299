<div id="bookbutton">
    <div class="col-xs-12 bookbuttondiv">
        <a [routerLink]="['/']" class="btn bookbutton" style="border-radius: 83px;">Passer la demande</a>
    </div>
</div>
<div class="custom-header custom-header-ecolo"></div>
<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama.png) bottom center repeat-x">
    &nbsp;</div>
<!-- BODY -->
<div class="container">
    <div class="col-sm-12">
        <div class="row Transfer">
            <p>&nbsp;</p>
            <h2>WASSALI dans une ère de mobilité durable</h2>
            <div class="col-sm-12 control-label2">
                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">Wassali pour un Transport écologique.</h5>
                <br><br>
                <p style="text-align:center">
                    Découvrez la solution wassali pour un environnement meilleur avec un transport routier responsable
                    allant du retour à vide jusqu’au groupage et des solutions pour réduire l’impact du transport de
                    marchandises sur notre environnement.
                    <br><br>
                    Préserver l’environnement est notre souci à tous wassali opère dans la mobilité
                    durable afin de réduire les émissions de CO2 et en maximisant le taux de remplissage à travers le
                    retour à vide et le groupage.
                </p>
                <!-- <br><br><br><br>


                <h5 style="font-size: 18px;
                line-height: normal; color: rebeccapurple;">Axe écologique :</h5>
                <p style="text-align:justify">
                    Wassali vous permet de planifier et d’optimiser vos retours à vide à travers notre flotte de
                    transporteurs.</p>
                <br><br>

                <h5 style="font-size: 18px;
                line-height: normal; color: rebeccapurple;">Axe économique :</h5>
                <p style="text-align:justify">
                    Afin d’optimiser vos couts wassali met à votre disposition des offres de transports au moindre tarif
                </p> -->

                <br><br>

                <!-- <p>&nbsp;</p> -->
            </div>
        </div>
    </div>
</div>

<div class="label-white">
    <div class="container">
        <div class="row">
            <!-- <h2 style="font-size: 45px; text-align: center;">
                Expediteur ou Transporteur ?</h2>
            <h4 style="font-size: 20px; text-align: center;">Rejoignez Wassali pour faciliter votre vie et bénéficier
                d’un service flexible, optimisé et garantie.</h4> -->
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/écolo.png" alt="Écolo wassali">
                            </div>
                            <h3>Axe écologique</h3>
                            <p>En identifiant et en optimisant les retours à vide, Wassali met à votre disposition une alternative concrète
                            face aux enjeux éologiques.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/sociale.png" alt="Sociale wassali">
                            </div>
                            <h3>Axe Social</h3>
                            <p>Améliorer les conditions de travail des chauffeurs, apporter plus de transparence 
                                et de simplicité pour les expéditeurs.</p>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/économique.png" alt="Économique wassali">
                            </div>
                            <h3>Axe économique</h3>
                            <p>Pour mieux gérer vos couts, Wassali met à votre service un transport au moindre tarif 
                                et qui vous permet de gagner en terme de temps et d'énérgie .</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/digital.png" alt="Digital wassali">
                            </div>
                            <h3>Transformation digitale</h3>
                            <p>Nous avons digitalisé le processus d'expédition de marchandise, de la création de votre commande jusqu'à 
                                la livraison de votre marchandises en ayant accès à un système de tracking digitalisés en temps réel.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <h2 mat-dialog-title>Install Angular</h2> -->
<mat-dialog-content class="mat-typography">
    <div class="jconfirm jconfirm-light jconfirm-open">
        <div class="jconfirm-bg"
            style="opacity: 0.8; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
        </div>
        <div class="jconfirm-scrollpane">
            <div class="jc-bs3-container container">
                <div
                    class="jc-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="jconfirm-box-container col-md-12" style="transform: translate(0px, 0px);">
                        <div class="jconfirm-box jconfirm-hilight-shake jconfirm-type-orange jconfirm-type-animated hilight"
                            role="dialog" aria-labelledby="jconfirm-box92057" tabindex="-1"
                            style="margin-top: 50px; margin-bottom: 50px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1); transition-property: all, margin;">
                            <div class="jconfirm-closeIcon" style="display: none;">×</div>
                            <div class="jconfirm-title-c"><span class="jconfirm-icon-c"><i
                                        class="fa fa-map-o"></i></span><span class="jconfirm-title">Sélectionner à partir de la carte</span></div>
                            <div class="jconfirm-content-pane"
                                style="height: 440px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="jconfirm-content" id="jconfirm-box92057" style="">
                                    <div>
                                        <p align="center" id="notif" class="notif" style="color: rgb(204, 51, 0);"><span
                                                class="text-success"><b>Cliquez sur le bouton TERMINÉ après votre sélection.</b></span></p>
                                        <div id="mapcontainer" style="height: 405px;">
                                            <div id="map2" style="height: 405px; position: relative; overflow: hidden;">
                                                <!-- <agm-map [latitude]="position.lat" [longitude]="position.lng"
                                                    [zoom]="zoom" [disableDefaultUI]="false"
                                                     (mapClick)="mapClicked($event)">
                                                    <agm-marker [latitude]="position.lat" [longitude]="position.lng"
                                                        [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">
                                                        <agm-info-window [isOpen]="true">
                                                            <strong>{{position?.address}}</strong>
                                                        </agm-info-window>
                                                    </agm-marker>
                                                </agm-map> -->

                                                <agm-map [latitude]="position?.lat" [longitude]="position?.lng" [zoom]="zoom"
                                                    [disableDefaultUI]="false">

                                                    <agm-marker
                                                        [latitude]="position?.lat" [longitude]="position?.lng"
                                                        [markerDraggable]="true"
                                                        (dragEnd)="markerDragEnd(position, $event)">

                                                        <agm-info-window [isOpen]="true" *ngIf="position!=null && position.label_target != null">
                                                            <strong>{{position.label_target}}</strong>
                                                        </agm-info-window>
                                                    </agm-marker>
                                                </agm-map>
                                            </div>
                                        </div>
                                    </div><input id="selectedLoc" type="hidden"
                                        value="nnamed Road, V4WP+6MG, Cebalat Ben Ammar, Tunisie">
                                    <style type="text/css">
                                        /* Always set the map height explicitly to define the size of the div
     * element that contains the map. */
                                        #map2 {
                                            height: 100%;
                                        }

                                        /* Optional: Makes the sample page fill the window. */
                                        html,
                                        body {
                                            height: 100%;
                                            margin: 0;
                                            padding: 0;
                                        }

                                        .centerMarker {
                                            position: absolute;
                                            /*url of the marker*/
                                            background: url("assets/images/marker.svg") no-repeat;
                                            /*center the marker*/
                                            top: 50%;
                                            left: 50%;
                                            z-index: 1;
                                            /*fix offset when needed*/
                                            margin-left: -17px;
                                            margin-top: -50px;
                                            /*size of the image*/
                                            height: 50px;
                                            width: 34px;
                                            cursor: pointer;
                                        }

                                        .gm-ui-hover-effect {
                                            display: none !important;
                                        }
                                    </style>

                                    <span></span>
                                </div>
                            </div>
                            <div class="jconfirm-buttons">
                                <button type="button" class="btn btn-dark" mat-button
                                    [mat-dialog-close]="true">Terminé</button>
                                <button type="button" class="btn btn-default" mat-button
                                    mat-dialog-close>Annuler</button>
                            </div>
                            <div class="jconfirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
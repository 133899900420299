<div id="bookbutton">
    <div class="col-xs-12 bookbuttondiv">
        <a [routerLink]="['/']" class="btn bookbutton" style="border-radius: 83px;">Passer la demande</a>
    </div>
</div>
<div class="custom-header custom-header-groupage"></div>
<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama.png) bottom center repeat-x">
    &nbsp;</div>
<!-- BODY -->
<div class="container">
    <div class="row services-container">
        <div class="col-sm-12">
            <div class="row Transfer">

                <p>&nbsp;</p>
                <h2>Les services de WASSALI</h2>
                <!-- <h5>Our vehicles suitable for your needs</h5> -->
                <p>&nbsp;</p>

                <div class="services-content equal">
                    <div class="col-sm-2 services-vehicle-div">
                        <div class="row">
                            <div class="services-vehicle-wrap">
                                <div class="services-vehicle"><img src="assets/images/porteur.png" alt="Service déménagement ewassali"
                                        width="146px" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-10">
                        <div class="row">
                            <div class="services-text">
                                <h6>Service déménagement</h6>
                                <p align="justify">Wassali offre une solution de déménagement, professionnel, flexible,
                                    Wassali vous permet de choisir entre plusieurs transporteurs.</p>
                                <p align="justify">Le client choisit les services dont il a besoin : le démontage,
                                    l’emballement, le déménagement, le remontage et l’aménagement.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="services-content-footer"></div>
                </div>

                <div class="services-content equal">
                    <div class="col-sm-2 services-vehicle-div">
                        <div class="row">
                            <div class="services-vehicle-wrap">
                                <div class="services-vehicle"><img src="assets/images/stafette.png" alt="Service transport de marchandise"
                                        width="146px" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-10">
                        <div class="row">
                            <div class="services-text">
                                <h6>Service transport de marchandise</h6>
                                <p align="justify">Que vous soyez un particulier ou un professionnel, wassali vous
                                    permet d’expédier tout type de marchandise confondus en toute sécurité, grâce à son
                                    portefeuille de transporteurs qualifiés.</p>
                                <p align="justify">Réduisez le coût de votre transport avec des tarifs compétitifs,
                                    grâce à l'optimisation de nos véhicules en retour à vide.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="services-content-footer"></div>
                </div>
            </div>

            <div class="row">

                <div class="services-content equal">
                    <div class="col-sm-2 services-vehicle-div">
                        <div class="row">
                            <div class="services-vehicle-wrap">
                                <div class="services-vehicle"><img src="assets/images/moto.png"
                                        alt="Service coursier ewassali" width="146px" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-10">
                        <div class="row">
                            <div class="services-text">
                                <h6>Service coursier</h6>
                                <p align="justify">Vous avez oublié un objet chez vous ? vous n’avez pas le temps de
                                    faire la queue pour payer une facture ?</p>
                                <p align="justify">Wassali met à votre disposition un service coursier qui va vous
                                    permettre de remédier à ces problèmes à travers un coursier qui va faire ses petites
                                    courses à votre place. </p>
                            </div>
                        </div>
                    </div>
                    <div class="services-content-footer"></div>
                </div>

                <div class="services-content equal">
                    <div class="col-sm-2 services-vehicle-div">
                        <div class="row">
                            <div class="services-vehicle-wrap">
                                <div class="services-vehicle"><img src="assets/images/remorquage.png"
                                        alt="Service remorquage wassali" width="146px" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-10">
                        <div class="row">
                            <div class="services-text">
                                <h6>Service remorquage</h6>
                                <p align="justify">Votre véhicule est tombé en panne, wassali vous offre une solution de
                                    dépannage et remorquage. Vous pouvez suivre la position de votre remorqueur en temps
                                    réel.</p>
                            </div>
                        </div>
                    </div>
                    <div class="services-content-footer"></div>
                </div>

            </div>
        </div>
    </div>
</div>
<div id="allContent">
  <div id="preloader">
      <div class="preloader-container">
          <div class="item item-1"></div>
          <div class="item item-2"></div>
          <div class="item item-3"></div>
          <div class="item item-4"></div>
      </div>
  </div>

  <router-outlet></router-outlet>

</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecolo-x-wassali',
  templateUrl: './ecolo-x-wassali.component.html',
  styleUrls: ['./ecolo-x-wassali.component.css']
})
export class EcoloXWassaliComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

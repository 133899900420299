<div id="bookbutton">
    <div class="col-xs-12 bookbuttondiv">
        <a [routerLink]="['/']" class="btn bookbutton" style="border-radius: 83px;">Passer la demande</a>
    </div>
</div>
<div class="custom-header custom-header-inscription"></div>
<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama.png) bottom center repeat-x">
    &nbsp;</div>
<!-- BODY -->
<!-- <div class="container">
    <div class="col-sm-12">
        <div class="row Transfer">
            <p>&nbsp;</p>
            <h2>Rejoignez Wassali pour faciliter votre vie et bénéficier
            </h2>
            <div class="col-sm-12 control-label2">
                <p>&nbsp;</p>

                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">Rejoignez Wassali pour faciliter votre vie et bénéficier d’un service flexible,
                    optimisé et garantie.</h5>
                <br>
                <p style="text-align:justify">
                    Rejoignez e wassali pour faciliter vos échanges.
                    <br><br>
                    Vous êtes transporteur ou expéditeur e wassali met à votre disposition une panoplie de service
                    (service déménagement, transport de marchandise, service coursier…)
                </p>
                <br><br><br>

                <h5 style="font-size: 22px;
                line-height: normal;">1. Transporteur :</h5>
                <h5 style="font-size: 18px;
                line-height: normal; color: rebeccapurple;">Devenir Transporteur Wassali</h5>
                <br>
                <p style="text-align:justify">
                    Devenez transporteur Wassali et générez des revenus avec Wassali quand vous le souhaitez. Profitez
                    de cette opportunité et devenez transporteur Wassali.
                    <br><br>
                    Augmentez vos revenus, gérez au mieux votre activité en rejoignant notre communauté de transporteurs
                    qualifiés.
                </p>

                <br><br><br>

                <h5 style="font-size: 22px;
                line-height: normal;">2. Client :</h5>
                <h5 style="font-size: 18px;
                line-height: normal; color: rebeccapurple;">Devenir Client Wassali</h5>
                <br>
                <p style="text-align:justify">
                    Besoin d’un transporteur pour votre déménagement, vos transports de marchandise ou transport de
                    biens (électroménager ou meuble), Wassali vous offre un service unique avec le meilleur prix de
                    transport en Tunisie.
                    <br><br>
                    Wassali vous permet de réserver immédiatement vos transports en toute sécurité et au meilleur prix.
                </p>

                <br><br>

                <p>&nbsp;</p>
            </div>
        </div>
    </div>
</div> -->

<div class="label-white">
    <div class="container">
        <div class="row">
            <!-- <h2 style="font-size: 45px; text-align: center;">
                Expediteur ou Transporteur ?</h2>
            <h4 style="font-size: 20px; text-align: center;">Rejoignez Wassali pour faciliter votre vie et bénéficier
                d’un service flexible, optimisé et garantie.</h4> -->
            <h2 style="text-align:center">Rejoignez Wassali!
            </h2>
            <h4 style="text-align:center">Afin de faciliter votre vie et bénéficier d’un service flexible,
                optimisé et garantie.</h4>

            <br><br><br>
            <h5 style="font-size: 22px;
                line-height: normal; color: #013274;"><b>1. Devenir client :</b></h5>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/inscription-wassali.png" alt="Inscription wassali">
                            </div>
                            <!-- <h3>Axe écologique</h3>
                            <p>En identifiant et en optimisant les retours à vide, Wassali met à votre disposition une alternative concrète
                            face aux enjeux éologiques.</p> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/demande-wassali.png" alt="Demande wassali">
                            </div>
                            <!-- <h3>Axe Social</h3>
                            <p>Améliorer les conditions de travail des chauffeurs, apporter plus de transparence 
                                et de simplicité pour les expéditeurs.</p> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/déménagement-wassali.png" alt="Déménagement ewassali">
                            </div>
                            <!-- <h3>Axe économique</h3>
                            <p>Pour mieux gérer vos couts, Wassali met à votre service un transport au moindre tarif 
                                et qui vous permet de gagner en terme de temps et d'énérgie .</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <br><br><br>
            <h5 style="font-size: 22px;
                line-height: normal; color: #013274;"><b>2. Devenir Transporteur WASSALI :</b></h5><br>
            <p style="line-height: 32px;
            text-align: center;
            font-size: 20px;">
                Rejoignez la communauté de transporteur Wassali ! <br>
                <b>Scannez le code QR</b> pour télécharger l'application et regardez la vidéo pour compléter l'inscription de
                votre compte transporteur sur l'application.
            </p>
            <br><br>
            <div class="col-sm-6">
                <div class="row">
                    <iframe width="100%" height="370" [src]="videoProduct|safe: 'resourceUrl'" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/qrcode.png" alt="Demande ewassali">
                            </div>
                            <!-- <h3>Axe Social</h3>
                <p>Améliorer les conditions de travail des chauffeurs, apporter plus de transparence 
                    et de simplicité pour les expéditeurs.</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
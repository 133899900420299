<div id="body">
    <header class="clearfix">
        <div id="logo">
            <img src="assets/images/logo-phoenix-black.png" alt="logo ewassali">
        </div>
        <div id="company">
            <h2 class="name">Bon de Livraison N° {{grouping?.ref_grouping}}</h2>
            <div><b>Date : </b>{{grouping?.creation_date_grouping | date:'d MMM y'}}</div>
            <div><b>Devise : TND</b></div>
        </div>

    </header>
    <main>
        <div id="details" class="clearfix">
            <div id="client">
                <!-- <h2 class="name">{{grouping.client_cmd?.company_name}}</h2> -->
                <table style="margin-bottom: 5px;">
                    <tbody>
                        <tr>
                            <td class="header header-bold">Nom de<br>l'expéditeur: </td>
                            <td class="header">{{grouping.user_transportor?.prenom_user}}
                                {{grouping.user_transportor?.nom_user}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Adresse: </td>
                            <td class="header">{{grouping.user_transportor?.email_user}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Tél: </td>
                            <td class="header">{{grouping.user_transportor?.tel_user}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="invoice">
                <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="grouping._id"
                    [errorCorrectionLevel]="'M'">
                </qrcode>
                <!-- <table style="margin-bottom: 2px;">
                    <tbody>
                        <tr>
                            <td class="header header-bold"><b>Nom du client: </b></td>
                            <td class="header">{{cmd.user_destination?.nom_destination}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Adresse : </td>
                            <td class="header">{{cmd.user_destination?.adresse_destination}}
                                {{cmd.destination_cmd}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Téléphone : </td>
                            <td class="header">{{cmd.user_destination?.numtel_destination}}</td>
                        </tr>
                    </tbody>
                </table> -->
            </div>
        </div>
        <div id="invoice" style="margin-bottom: 20px;">
            <table style="margin-bottom: 2px;">
                <tbody>
                    <tr>
                        <td class="header header-bold"><b>Type de colis: </b></td>
                        <td class="header"><b>Groupage</b></td>
                    </tr>
                    <tr>
                        <td class="header header-bold">Localisation : </td>
                        <td class="header"><b>{{grouping.user_location?.label_target}}
                                {{grouping.destination_cmd}}</b></td>
                    </tr>
                    <tr>
                        <td class="header header-bold">Destination : </td>
                        <td class="header"><b>{{grouping.user_destination?.label_target}}</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <table border="0" cellspacing="0" cellpadding="0">
            <thead>
                <tr style="border: 1px solid;">
                    <th class="title-table"></th>
                    <th class="title-table">Palette</th>
                    <th class="title-table">Service</th>
                    <th class="title-table">Sous service</th>
                    <th class="title-table">Quantite</th>
                    <!-- <th class="title-table">Prix unitaire</th>
                    <th class="title-table">Montant Total HT</th> -->
                    <!-- <th class="title-table">Frais <br>de livraison</th>
                    <th class="title-table">Frais<br>livreur</th> -->
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="ref">#</td>
                    <td class="desc">
                        {{grouping?.palette?.name}}<br>
                        <!-- <b *ngIf="cmd.isExchange==true">Echange ({{prd.quantite}} article(s))</b> -->
                    </td>
                    <td class="ref">{{grouping?.sub_family?.family?.name}}</td>
                    <td class="unit">{{grouping?.sub_family?.name}}</td>
                    <td class="qty"><b>{{grouping?.quantity}}</b></td>
                    <!-- <td class="ref">{{grouping?.palette?.price*((100-19)/100) |
                        number:'0.3'}} TND</td>
                    <td class="qty"><b>{{(grouping?.palette?.price*((100-19)/100))*grouping.quantity |
                        number:'0.3'}} TND</b></td> -->
                    <!-- <td class="total">{{cmd?.client_cmd?.fraisDelivery?.frais_interne |
                        number:'0.3'}} DT</td>
                    <td class="total">{{userTracking.frais_livraison}} DT</td> -->
                </tr>


                <!-- <tr>
                    <td colspan="6" style="border: none; height: 30px;"></td>
                </tr>
                <tr>
                    <td colspan="6" style="border: none; height: 20px;"></td>
                </tr>
                <tr class="tfooter">
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Montant HT</td>
                    <td><b>{{((grouping?.price*(100-19))/100) |
                        number:'0.3'}} TND</b></td>
                </tr>
                <tr class="tfooter">
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">TVA 19%</td>
                    <td><b>{{((grouping?.price*19/100)) |
                        number:'0.3'}} TND</b></td>
                </tr>
                <tr class="tfooter">
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Montant TTC</td>
                    <td><b>{{grouping?.price |
                        number:'0.3'}} TND</b></td>
                </tr> -->
            </tbody>
        </table>

        <!-- <table border="0" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td class="signature">Signature de livreur</td>
                    <td class="signature">Service financier</td>
                </tr>
            </tbody>
        </table> -->
    </main>
</div>
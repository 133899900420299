<div class="body-login img js-fullheight" style="background-image: url('assets/images/bg.gif'); height: 100%;">
    <section class="ftco-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center mb-5">
                    <!-- <h2 class="heading-section">Login #10</h2> -->
                    <img src="assets/images/logo_wassali_blanc.png" style="width: 100%;" alt="loge wassali">
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="login-wrap p-0">
                        <h3 class="mb-4 text-center">Réinitialiser votre mot de passe</h3>
                        <form action="#" class="signin-form">
                            <div class="form-group">
                                <input type="email" name="email" class="form-control" placeholder="Username" required [(ngModel)]="email">
                            </div>
                            <!-- <div class="form-group">
                                <input id="password-field" type="text" class="form-control" placeholder="Password" required="">
                                <span toggle="#password-field" class="fa fa-fw field-icon toggle-password fa-eye-slash"></span>
                                </div> -->
                            <div class="form-group">
                                <button type="submit" class="form-control btn btn-primary submit px-3"
                                (click)="forgetPassWord()">Envoyer le lien</button>
                            </div>
                        </form>
                        <!-- <p class="w-100 text-center">&mdash; Ou &mdash;</p>
                        <div class="social d-flex text-center">
                            <a href="#" class="px-2 py-2 mr-md-1 rounded"><span class="ion-logo-facebook mr-2"></span>
                                Facebook</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users-wassali/users.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.css']
})
export class InscriptionComponent implements OnInit {

  passwordIsChecked: boolean = false

  firstName: string = ""
  lastName: string = ""
  numTel: string = ""
  email: string = ""
  password: string = ""

  msgErrorLogin: string = null;

  constructor(private usersService: UsersService,
    private router: Router,) { }

  ngOnInit(): void {
  }

  checkPassword() {
    this.passwordIsChecked = !this.passwordIsChecked;
  }

  signUP() {
    if (this.firstName != "" && this.lastName != "" && this.email.includes('@')
      && this.email != "" /*&& this.imageUrl != ""*/ && this.password != ''
      && this.numTel != "" && this.numTel.toString().length == 8) {
      const formData: any = new FormData();

      var newUser = {
        prenom: this.firstName,
        nom: this.lastName,
        numtel: this.numTel,
        username: this.email,
        password: this.password
      }
      for (var key in newUser) {
        formData.append(key, newUser[key]);
      }

      this.usersService.inscription(formData).then(async (res: any) => {
        // console.log("ressssss : ")
        // console.log(res)
        if (res) {
          if (res.response == true) {
            let route = await this.usersService.getAfterRoute()
            // console.log("rouuuuutr")
            // console.log(route)
            // window.location.reload()
            location.href = route
            // this.router.navigate([route])
            this.usersService.removeRoute()
          } else {
            this.msgErrorLogin = res.msg
            this.password = ""
          }
        }
        else {
          this.msgErrorLogin = "Impossible d'authentifié"
          this.password = ""
        }
      })
    } else {
      this.msgErrorLogin = "Veuillez vérifier vos coordonnées !"
      this.password = ""
    }
  }

}

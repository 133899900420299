<div id="bookbutton">
    <div class="col-xs-12 bookbuttondiv">
        <a [routerLink]="['/']" class="btn bookbutton" style="border-radius: 83px;">Passer la demande</a>
    </div>
</div>
<div class="custom-header custom-header-img"></div>
<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama.png) bottom center repeat-x">
    &nbsp;</div>
<!-- BODY -->
<div class="container">
    <div class="col-sm-12">
        <div class="row Transfer">
            <p>&nbsp;</p>
            <h2>À propos de WASSALI</h2>
            <div class="col-sm-12 control-label2">
                <p>&nbsp;</p>

                <br>

                <h5 style="font-size: 22px;
                line-height: normal;">Vous êtes à Tunis, Sfax, Sousse, Bizerte ou même à Djerba, Wassali vous connecte
                    avec le transporteur qui vous convient avec le prix qui vous convient.</h5>
                <br><br>
                <p style="text-align:justify">
                    eWASSALI transport express est une plateforme qui met en relation un expéditeur avec un
                    transporteur grace à notre flotte de véhicules. e- Wassali vous assure un service flexible qui
                    s’adapte à vos besoins en termes de qualité, cout et délai.
                </p>
                <br><br><br>

                <h5 style="font-size: 22px;
                line-height: normal;">Nos partenaires : </h5>

                <br>
                <div class="row">
                    <div class="col-sm-6">
                        <img src="assets/images/logo-fedex.png" alt="partenariat wassali fedex" class="center">
                    </div>
                    <div class="col-sm-6">
                        <img src="assets/images/logo-phoenix-black.png" alt="partenariat wassali phoenix" class="center">
                    </div>
                </div>
                
                <br><br>
                
                <p>&nbsp;</p>
            </div>
        </div>
    </div>
</div>
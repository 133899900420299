<div class="body-login img js-fullheight" style="background-image: url('assets/images/bg.gif'); height: 100%;">
    <section class="ftco-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center mb-5">
                    <!-- <h2 class="heading-section">Login #10</h2> -->
                    <img src="assets/images/logo_wassali_blanc.png" style="width: 100%;" alt="logo wassali">
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="login-wrap p-0">
                        <h3 class="mb-4 text-center">Vous êtes nouveau sur Wassali?</h3>
                        <form action="#" class="signin-form">
                            <div class="form-group">
                                <input type="text" name="login" class="form-control" placeholder="Username" required
                                    [(ngModel)]="login" [class]="(login=='') ? 'input-border' : ''">
                            </div>
                            <div class="form-group">
                                <input name="password" id="password-field" [(ngModel)]="password"
                                    [type]="(passwordIsChecked==false) ? 'password' : 'text'" class="form-control"
                                    placeholder="Password" required [class]="(password=='') ? 'input-border' : ''">
                                <span (click)="checkPassword()"
                                    [class]="(passwordIsChecked==false) ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw field-icon toggle-password fa-eye-slash'"></span>
                            </div>
                            <!-- <div class="form-group">
                                <input id="password-field" type="text" class="form-control" placeholder="Password" required="">
                                <span toggle="#password-field" class="fa fa-fw field-icon toggle-password fa-eye-slash"></span>
                                </div> -->
                            <div class="form-group">
                                <button type="submit" class="form-control btn btn-primary submit px-3"
                                    (click)="authentification()">Se connecter</button>
                            </div>
                            <div class="msg-error text-center p-t-20">
                                <label class="txt1 msg-error-color " *ngIf="msgErrorLogin!=null">
                                    {{msgErrorLogin}}
                                </label>
                            </div>
                            <div class="form-group d-md-flex">
                                <div class="w-50">
                                    <!-- <label class="checkbox-wrap checkbox-primary">Remember Me
                                        <input type="checkbox" checked>
                                        <span class="checkmark"></span>
                                    </label> -->
                                </div>
                                <div class="w-50 text-md-right">
                                    <a href="#" style="color: #fff" [routerLink]="['/forget-password']">Mot de passe
                                        oublié ?</a>
                                </div>
                            </div>
                        </form>
                        <div class="form-group">
                            <button type="submit" class="form-control btn btn-signup submit px-3"
                                [routerLink]="['/sign-up']">S'inscrire</button>
                        </div>
                        <p class="w-100 text-center">&mdash; OU &mdash;</p>
                        <div class="social d-flex text-center">
                            <!-- <fb:login-button class="px-2 py-2 mr-md-1 rounded" scope="public_profile,email" onlogin="loginFB();">
                            </fb:login-button> -->
                            <a class="px-2 py-2 mr-md-1 rounded" (click)="loginFB();"><span class="fa fa-facebook"></span>
                                Facebook</a>
                            <!-- <a href="#" class="px-2 py-2 ml-md-1 rounded"> Twitter</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<script src="assets/js/login/jquery.min.js"></script>
<script src="assets/js/login/popper.js"></script>
<script src="assets/js/login/bootstrap.min.js"></script>
<script src="assets/js/login/main.js"></script>
import { Component, ElementRef, KeyValueDiffers, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { CauseCanceledDemandeComponent } from 'src/app/components/shared/dialog/cause-canceled-demande/cause-canceled-demande.component';
import { GetLocationComponent } from 'src/app/components/shared/dialog/get-location/get-location.component';
import { NumTelDemandeComponent } from 'src/app/components/shared/dialog/num-tel-demande/num-tel-demande.component';
import { GoogleService } from 'src/app/services/google/google.service';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import { UsersService } from 'src/app/services/users-wassali/users.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  nowDate = new Date();
  startDate = new Date(this.nowDate.setDate(this.nowDate.getDate() + 1));

  listTimepicker: any = [
    { "value": "00:00" },
    { "value": "00:30" },
    { "value": "01:00" },
    { "value": "01:30" },
    { "value": "02:00" },
    { "value": "02:30" },
    { "value": "03:00" },
    { "value": "03:30" },
    { "value": "04:00" },
    { "value": "04:30" },
    { "value": "05:00" },
    { "value": "05:30" },
    { "value": "06:00" },
    { "value": "06:30" },
    { "value": "07:00" },
    { "value": "07:30" },
    { "value": "08:00" },
    { "value": "08:30" },
    { "value": "09:00" },
    { "value": "09:30" },
    { "value": "10:00" },
    { "value": "10:30" },
    { "value": "11:00" },
    { "value": "11:30" },
    { "value": "12:00" },
    { "value": "12:30" },
    { "value": "13:00" },
    { "value": "13:30" },
    { "value": "14:00" },
    { "value": "14:30" },
    { "value": "15:00" },
    { "value": "15:30" },
    { "value": "16:00" },
    { "value": "16:30" },
    { "value": "15:00" },
    { "value": "15:30" },
    { "value": "16:00" },
    { "value": "16:30" },
    { "value": "17:00" },
    { "value": "17:30" },
    { "value": "18:00" },
    { "value": "18:30" },
    { "value": "19:00" },
    { "value": "19:30" },
    { "value": "20:00" },
    { "value": "20:30" },
    { "value": "21:00" },
    { "value": "21:30" },
    { "value": "22:00" },
    { "value": "22:30" },
    { "value": "23:00" },
    { "value": "23:30" },
  ]
  valueTimepicker: any = null
  valueDatepicker: string = null

  permission: string = null
  statisticsUser: any = null

  fromPlace: string = ""
  wherePlace: string = ""
  listPlacesFrom: any = []
  listPlacesWhere: any = []

  /////// data google
  mapLoaded: boolean = false;
  map: google.maps.Map = null as any;
  center: google.maps.LatLngLiteral = null as any;

  source: google.maps.LatLngLiteral = null as any;
  markerStart: google.maps.Marker = null
  _dataTargetLocation: marker = null

  destination: google.maps.LatLngLiteral = null as any;
  destinationMarker: google.maps.Marker = null
  _dataTargetDestination: marker = null

  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    zoom: 14
  }

  ds: google.maps.DirectionsService = null as any;
  dr: google.maps.DirectionsRenderer = null as any;
  /////--------------

  private geoCoder;

  allServices: any = []
  allCamion: any = []
  dropdownTypeService: any = null
  dropdownTypeProduit: any = null
  dataServiceDemande: any = null
  isFridged: boolean = false

  msgValidation = ""
  differ: any;

  demande: any = null
  userHasDemande: boolean = false

  subscription: Subscription;

  videoProduct: string = "https://assets.marketbey.tn/Wassali/videos/video-wassali.mp4"

  constructor(private transServices: TransportorsService,
    private usersService: UsersService,
    private googleService: GoogleService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private differs: KeyValueDiffers,
    private route: ActivatedRoute,
    private router: Router,) {
    // Listen to click events in the component
    // var planifiedDate = (<HTMLInputElement>document.getElementById("datepicker"));

    // var rectangle = this.renderer.createElement('ui-datepicker-div'); /*dynamically create element*/
    // this.renderer.appendChild(this.elementRef.nativeElement, rectangle);
    // renderer.listen(rectangle, 'click', (event) => {
    //   // Do something with 'event'
    //   // if (event != null) {
    //   // if (event.target.id != null && event.target.id == "datepicker") {
    //   console.log("event")
    //   console.log(event)
    //   console.log(event.target.value)
    //   // this._fetchAllPlace(event.target.value)
    //   // this.myFunction()
    //   // }
    //   // }

    // })

    this.differ = this.differs.find({}).create();
    // this.showPermission()
  }

  async ngOnInit() {
    // document.getElementById("amine").addEventListener("change", this.myFunction);
    // this.elementRef.nativeElement.querySelector('amine')
    //                               .addEventListener('change', this.onClick.bind(this));

    // location.href = ''

    await this.statisticUser()

    this.allTypeServices()
    this.allTypeCamions()

    this.geoCoder = new google.maps.Geocoder;
    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer({
      map: null,
      suppressMarkers: true
    });
    this.map = await this._initMap();

    await this._userHasDemande()

    await this._loadDemande()
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.demande != null)
        this._getDemande()
    });

    await this.getDemandeFromStorage()
  }

  async statisticUser() {
    await this.transServices.statisticUser().then((res: any) => {
      if (res) {
        this.statisticsUser = res
      }
    }).catch((e) => {
      // resolve(null)
    });
  }

  async getDemandeFromStorage() {
    var demande = await this.usersService.getDemandeFromStorage()
    // console.log("demandeeeeeeeeeeeeee : ")
    // console.log(demande)
    if (demande != null) {
      if (this.map == null) {
        this.map = await this._initMap()
      }
      // set location
      let location = demande.location
      this.fromPlace = location.label_target
      this._dataTargetLocation = location
      this.source = {
        lat: location.lat,
        lng: location.lng
      };

      // console.log("source : ")
      // console.log(this.source)
      this.markerStart = new google.maps.Marker({
        position: this.source,
        icon: {
          url: 'assets/images/destination_map_marker.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });
      // this.markerStart.setPosition(this.source)
      // this.markerStart.setMap(this.map)
      // console.log("markerStart : ")
      // console.log(this.markerStart)

      // console.log("maaaaaaaaaaaap : ")
      // console.log(this.map)
      // this.setLocation(location.lat, location.lng)
      // if (this.destination != null) {
      //   this.setRoutePolyline()
      // }

      // set destination
      let destination = demande.destination
      // console.log("destination : ")
      // console.log(this.destination)
      this.wherePlace = destination.label_target
      this._dataTargetDestination = destination
      await this.setDestination(destination.lat, destination.lng)
      this.setRoutePolyline()

      // set camion
      this.dataServiceDemande = demande.typeCamion
      await this.allTypeServices()
      this.allServices = this.allServices.filter(service => service.vehicules.includes(this.dataServiceDemande._id));

      // set service
      this.dropdownTypeService = demande.typeService

      // set product
      this.dropdownTypeProduit = demande.typeProduct
    } else {
      this.markerStart = await this.initLocation()
    }
  }

  async _createDemande() {
    var idUser = await this.usersService.getIdUser()
    var newDemande = {
      idUser: idUser,
      location: JSON.stringify(this._dataTargetLocation),
      destination: JSON.stringify(this._dataTargetDestination),
      typeService: this.dropdownTypeService.service,
      typeCamion: this.dataServiceDemande.name,
      typeProduct: this.dropdownTypeProduit.product,

      poid: JSON.stringify({ "min": "", "max": "" }).toString(),
      volume: JSON.stringify({ "min": "", "max": "" }).toString(),
      comment: "",
      photo_products: ""
    }

    if (this.isFridged == true) {
      newDemande["isFridged"] = this.isFridged
    }

    // var planifiedDate = (<HTMLInputElement>document.getElementById("datepicker"));
    // console.log("this.planifiedTransportDate")
    // console.log(planifiedDate.value)
    // var planifiedTime = (<HTMLInputElement>document.getElementById("timepicker"));
    // console.log("this.planifiedTransportTimeee")
    // console.log(planifiedTime.value)
    if (this.valueDatepicker != null && this.valueDatepicker != "") {
      var datePlanification = new Date(this.valueDatepicker)
      if (this.valueTimepicker != null && this.valueTimepicker.value != null && this.valueTimepicker.value != "") {
        var timers: any = this.valueTimepicker.value.split(':')
        datePlanification.setHours(timers[0])
        datePlanification.setMinutes(timers[1])
      }
      newDemande["isPlanified"] = true
      newDemande["planificationDate"] = datePlanification.getTime()
    }

    // console.log("dattttte : ")
    // console.log(datePlanification)
    // console.log("milisccccd")
    // console.log(datePlanification.getTime())

    const currentUser = this.usersService.currentUserValue;
    if (currentUser) {
      // console.log("new demande")
      // console.log(newDemande)
      var user: any = await this.usersService.getUserInfos()
      if (user.tel_user != null && user.tel_user != "") {
        newDemande["flux"] = "web"
        this.transServices.createDemade(newDemande).then(async (res: any) => {
          if (res && res.response && res.response == true) {
            // console.log("resssssssss : ")
            // console.log(res)
            this.demande = res.data
            this.userHasDemande = true
            this.usersService.removeDemandeFromStorage()
            // initi value
            this._dataTargetDestination = null
            this.wherePlace = ""
            this.dropdownTypeService = null
            this.dataServiceDemande = null
            this.dropdownTypeProduit = null

            // await this._clearDataMaps()
            // await this.initLocation()
          }
        }).catch((e) => {
          // console.log("eeeeeeeexx")
          // console.log(e)
        });
      } else {
        const confirmDialog = this.dialog.open(NumTelDemandeComponent, {
          data: {
            numTel: null
          }
        });

        confirmDialog.afterClosed().subscribe(async (result) => {
          if (result === true) {
            var data = confirmDialog.componentInstance.data

            await this.usersService.saveNumTelProfile(data.numTel)

            this.transServices.createDemade(newDemande).then(async (res: any) => {
              if (res && res.response && res.response == true) {
                this.demande = res.data
                this.userHasDemande = true
                this.usersService.removeDemandeFromStorage()
                this._dataTargetDestination = null
                this.wherePlace = ""
                this.dropdownTypeService = null
                this.dataServiceDemande = null
                this.dropdownTypeProduit = null
              }
            }).catch((e) => {
              // console.log("eeeeeeeexx")
              // console.log(e)
            });
          }
        });
      }
    } else {
      var theDemande = {
        location: this._dataTargetLocation,
        destination: this._dataTargetDestination,
        typeService: this.dropdownTypeService,
        typeCamion: this.dataServiceDemande,
        typeProduct: this.dropdownTypeProduit,
      }
      this.usersService.saveDemandeInStorage(theDemande, "/")
      this.router.navigate(['/login'])
    }
  }
  async _clearDemande() {
    this.demande = null
    this.userHasDemande = false
    this.usersService.removeDemandeFromStorage()
    // initi value
    this._dataTargetDestination = null
    this.wherePlace = ""
    this.dropdownTypeService = null
    this.dataServiceDemande = null
    this.dropdownTypeProduit = null

    await this._clearDataMaps()
    this.markerStart = await this.initLocation()
  }

  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        // console.log('item changed', item);
        if (item.key == "_dataTargetDestination") {
          this.isValide()
        }
        if (item.key == "dataServiceDemande") {
          this.isValide()
        }
        if (item.key == "dropdownTypeService") {
          this.isValide()
        }
        if (item.key == "dropdownTypeProduit") {
          this.isValide()
        }
      });
    }
  }

  isValide() {
    // console.log("isValiddd")
    // console.log(this._dataTargetLocation)
    if (this._dataTargetLocation == null || this._dataTargetDestination == null) {
      if (this._dataTargetLocation == null) {
        this.msgValidation = "Précisez votre position !!!"
      } else if (this._dataTargetDestination == null) {
        this.msgValidation = "Précisez votre destination !!!"
      }
    } else if (this.dataServiceDemande == null) {
      this.msgValidation = "Selectionnez le camion !!!"
    } else if (this.dropdownTypeService == null || this.dropdownTypeProduit == null) {
      if (this.dropdownTypeService == null) {
        this.msgValidation = "Selectionnez le service !!!"
      } else if (this.dropdownTypeProduit == null) {
        this.msgValidation = "Selectionnez le produit !!!"
      }
    } else {
      this.msgValidation = ""
    }
  }

  async selectCamion(camion) {
    // console.log(camion)
    this.dropdownTypeService = null
    this.dropdownTypeProduit = null
    if (!(this.dataServiceDemande != null && this.dataServiceDemande._id == camion._id)) {
      this.dataServiceDemande = camion
      this.isFridged = false
    }
    await this.allTypeServices()
    this.allServices = this.allServices.filter(service => service.vehicules.includes(this.dataServiceDemande._id));
  }
  
  _cancelDemande(msg) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.transServices.updateDemande(this.demande._id, "canceled", msg).then((res: any) => {
        if (res) {
          if (res.response && res.response == true)
            resolve(true)
          else
            resolve(null)
        } else
          resolve(null)
      }).catch((e) => {
        resolve(null)
      });
    });
    return promise
  }
  pendingProposition() {
    return this.demande.driver_responses.filter(response => response.etat_response != "refused");
  }
  _loadDemande() {
    if (this.demande != null) {
      // console.log("demmmmande+++")
      const source = interval(1000);
      this.subscription = source.subscribe(val => {
        this._getDemande()
      });
    }
  }

  _getDemande() {
    // console.log("demmmmande+++")
    this.transServices.getPropositionsDemandeNotRefused(this.demande._id).then((res: any) => {
      if (res) {
        this.demande = res
      }
    }).catch((e) => {
      // console.log("eeeeeeeexx")
      // console.log(e)
    });
  }

  _refuseDemande(idResponse) {
    this.transServices.refuseDemande(this.demande._id, idResponse).then((res: any) => {
      if (res) {
        this.demande = res
      }
    }).catch((e) => {
      // console.log("eeeeeeeexx")
      // console.log(e)
    });
  }

  _accepteDemande(theResponse, idDemande) {
    // console.log("theResponse")
    // console.log(theResponse)
    var responseDemande = {
      "idDemande": this.demande["_id"],
      "idDriver":
        theResponse["driver_id"]
        ["_id"],
      "idResponse": theResponse["_id"],
      "position": JSON.stringify({
        "lat": theResponse["position"]
        ["lat"],
        "lng": theResponse["position"]
        ["lng"]
      }),
      "proposition":
        theResponse["proposition"],
      "acceptedBy": "client",
    };
    this.transServices.accepteDemande(responseDemande).then(async (res: any) => {
      if (res) {
        this.demande = res

        // this.router.navigate(['/details-demande', { "demande": idDemande }]);
        location.href = '/details-demande-transportor?demande=' + idDemande;
      }
    }).catch((e) => {
      // console.log("eeeeeeeexx")
      // console.log(e)
    });
  }

  _userHasDemande() {
    this.transServices.userHasDemande().then((res: any) => {
      // console.log("res _userHasDemande")
      // console.log(res)
      if (res) {
        if (res.response && res.response == true) {
          this.userHasDemande = res.response
          this.demande = res.data
          if (this.demande.etat_demande != "created") {
            location.href = '/details-demande-transportor?demande=' + this.demande._id;
          }
        } else
          this.userHasDemande = false
      } else
        this.userHasDemande = false
    }).catch((e) => {
      // console.log("eeeeeeeexx")
      // console.log(e)
    });
  }

  _fetchDetailsPlace(placeId) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.googleService.getPlaceDetailFromId(placeId).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res)
        }
      }).catch((e) => {
        resolve(null)
      });
    });
    return promise
  }
  _fetchAllPlace(place) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.googleService.allSearchPlace(place).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res.predictions)

          // var listPlace = null
          // if (this.tagClicked == "where") {
          //   listPlace = (<HTMLInputElement>document.getElementById("select2-end-results"));
          // } else if (this.tagClicked == "from") {
          //   listPlace = (<HTMLInputElement>document.getElementById("select2-start-results"));
          // }

          // if (res.predictions != null && res.predictions.length > 0) {
          //   const child = (<HTMLInputElement>document.getElementById("not_found"));
          //   // listPlace.removeChild(child)

          //   res.predictions.forEach(element => {
          //     listPlace.insertAdjacentHTML('beforeend',
          //       '<li class="select2-results__option--highlighted" '
          //       + 'aria-selected="true" (click)="test()">' + element.description + '</li>')
          //   });
          // } else {
          //   listPlace.insertAdjacentHTML('beforeend',
          //     '<li id="not_found" class="select2-results__option select2-results__message">Not found...</li>');

          // }
        }
      }).catch((e) => {
        resolve([])
      });
    });
    return promise
  }

  async _fetchAllPlaceFrom(place) {
    this.listPlacesWhere = []
    this.listPlacesFrom = await this._fetchAllPlace(place)
  }
  async selectPlaceFrom(event) {
    // window.scrollTo(0, 0);
    // console.log("eventttt : ")
    // console.log(event.target.content)
    var detailsPlaceFrom = await this._fetchDetailsPlace(event.target.content.place_id)
    this.fromPlace = event.target.content.description
    this._dataTargetLocation = {
      label_target: event.target.content.description,
      main_target: (event.target.content.structured_formatting != null && event.target.content.structured_formatting.main_text != null)
        ? event.target.content.structured_formatting.main_text : event.target.content.description,

      sencondary_target: (event.target.content.structured_formatting != null && event.target.content.structured_formatting.secondary_text != null)
        ? event.target.content.structured_formatting.secondary_text : event.target.content.description,

      lat: detailsPlaceFrom.geometry.location.lat,
      lng: detailsPlaceFrom.geometry.location.lng,
    }
    this.setLocation(detailsPlaceFrom.geometry.location.lat, detailsPlaceFrom.geometry.location.lng)
    if (this.destination != null) {
      this.setRoutePolyline()
    }
    this.listPlacesFrom = []
  }

  async _fetchAllPlaceWhere(place) {
    this.listPlacesFrom = []
    this.listPlacesWhere = await this._fetchAllPlace(place)
  }
  async selectPlaceWhere(event) {
    // window.scrollTo(0, 0);
    // console.log("eventttt : ")
    // console.log(event.target.content)
    var detailsPlaceWhere = await this._fetchDetailsPlace(event.target.content.place_id)
    this.wherePlace = event.target.content.description
    this._dataTargetDestination = {
      label_target: event.target.content.description,
      main_target: (event.target.content.structured_formatting != null && event.target.content.structured_formatting.main_text != null)
        ? event.target.content.structured_formatting.main_text : event.target.content.description,
      sencondary_target:
        (event.target.content.structured_formatting != null && event.target.content.structured_formatting.secondary_text != null)
          ? event.target.content.structured_formatting.secondary_text : event.target.content.description,
      lat: detailsPlaceWhere.geometry.location.lat,
      lng: detailsPlaceWhere.geometry.location.lng,
    }
    this.setDestination(detailsPlaceWhere.geometry.location.lat, detailsPlaceWhere.geometry.location.lng)
    this.setRoutePolyline()
    this.listPlacesWhere = []
  }

  async allTypeServices() {
    await this.transServices.allTypeServices().then((res: any) => {
      if (res) {
        // console.log("resssssssss services : ")
        // console.log(res)
        this.allServices = res;
      }
    });
  }

  async allTypeCamions() {
    await this.transServices.allTypeCamions().then((res: any) => {
      if (res) {
        // console.log("resssssssss camion : ")
        // console.log(res)
        this.allCamion = res;
      }
    });
  }

  clearService() {
    this.dropdownTypeProduit = null
  }

  getAddress(latitude, longitude): any {
    let promise = new Promise<any>((resolve, reject) => {
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        // console.log("getAddress")
        // console.log(results);
        // console.log(status);
        if (status === 'OK') {
          if (results[0]) {
            resolve({
              lat: latitude,
              lng: longitude,
              label_target: results[0].formatted_address,
              main_target: results[0].address_components[results[0].address_components.length - 1].long_name,
              sencondary_target: results[0].address_components[results[0].address_components.length - 1].long_name
            })
            // this.address = results[0].formatted_address;
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
    });
    return promise
  }

  // async _initMap() {
  //   this.ds = new google.maps.DirectionsService();
  //   this.dr = new google.maps.DirectionsRenderer({
  //     map: null,
  //     suppressMarkers: true
  //   });

  //   await navigator.geolocation.getCurrentPosition(async (position) => {

  //     this.source = {
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude
  //     };
  //     console.log("this.source")
  //     console.log(this.source)

  //     this._dataTargetLocation = await this.getAddress(this.source.lat, this.source.lng);
  //     this.fromPlace = this._dataTargetLocation.label_target
  //     // console.log("this._dataTargetLocation")
  //     // console.log(this._dataTargetLocation)

  //     // this.destination = {
  //     //   lat: 36.8064948,
  //     //   lng: 10.1815316
  //     // };

  //     // initialize the map container
  //     this.map = new google.maps.Map((document as any).getElementById('map-canvas'), {
  //       ...this.options,
  //       center: {
  //         lat: this.source.lat,
  //         lng: this.source.lng
  //       }
  //     });

  //     this.map.addListener('tilesloaded', () => {
  //       this.mapLoaded = true;
  //     });

  //     // adding a marker
  //     this.markerStart = new google.maps.Marker({
  //       position: this.source,
  //       icon: {
  //         url: 'assets/images/destination_map_marker.png',
  //         // anchor: new google.maps.Point(35, 10),
  //         // scaledSize: new google.maps.Size(100, 100)
  //       },
  //       map: this.map
  //     });

  //     // var destinationMarker = new google.maps.Marker({
  //     //   position: this.destination,
  //     //   icon: {
  //     //     url: 'assets/images/destination_map_marker2.png',
  //     //     // anchor: new google.maps.Point(35, 10),
  //     //     // scaledSize: new google.maps.Size(100, 100)
  //     //   },
  //     //   map: this.map
  //     // });

  //     // this.setRoutePolyline();
  //   });

  //   // this.getAddress(this.source.lat, this.source.lng);
  //   // console.log("this._dataTargetLocation")
  //   // console.log(this._dataTargetLocation)
  // }

  async initLocation() {
    let promise = new Promise<any>(async (resolve, reject) => {
      await navigator.geolocation.getCurrentPosition(async (position) => {
        // console.log("positionnnnnnnn + ")
        // console.log(position)
        this.source = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        // console.log("this.source")
        // console.log(this.source)

        this._dataTargetLocation = await this.getAddress(this.source.lat, this.source.lng);
        this.fromPlace = this._dataTargetLocation.label_target

        this.map.setCenter(this.source)
        await this.isValide()

        // adding a marker
        resolve(new google.maps.Marker({
          position: this.source,
          icon: {
            url: 'assets/images/destination_map_marker.png',
            // anchor: new google.maps.Point(35, 10),
            // scaledSize: new google.maps.Size(100, 100)
          },
          map: this.map
        }))
      });

      // })
      // console.log("promise + ")
      // console.log(promise)
      // if(promise != null) {
      await this.isValide()
    });

    return promise
  }

  showPermission() {
    // Notification.requestPermission(function (result) {
    //   if (result === 'denied') {
    //     console.log('Permission wasn\'t granted. Allow a retry.');
    //     return;
    //   } else if (result === 'default') {
    //     console.log('The permission request was dismissed.'); 
    //     return;
    //   }
    //   console.log('Permission was granted for notifications');
    // });

    // navigator.geolocation.getCurrentPosition(function(position) { 
    //   console.log('Geolocation permissions granted');
    //   console.log('Latitude:' + position.coords.latitude);
    //   console.log('Longitude:' + position.coords.longitude); 
    // });

    // Check for Notifications API permissions
    navigator.permissions.query({ name: 'geolocation' }).then(function (p) {
      // updatePermission('notifications', p.state);
      console.log(p.state)
      if (p.state === 'denied' || p.state === "prompt") {
        Notification.requestPermission(function (permission) {
          console.log(permission)
          // If the user accepts, let's create a notification
          // if (permission === "granted") {
          //   let notification = new Notification("Hi there!");
          // }
        });
      }
      p.onchange = function () {
        // updatePermission('notifications', this.state);
      };
    });

    // navigator.permissions.query({ name: 'geolocation' })
    //   .then(function (permissionStatus) {
    //     console.log('geolocation permission state is ', permissionStatus.state);

    //     permissionStatus.onchange = function () {
    //       console.log('geolocation permission state has changed to ', this.state);
    //     };
    //   });
  }
  handlePermission() {
    let promise = new Promise<any>(async (resolve, reject) => {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        // console.log('Permission result');
        // console.log(result);
        // if (result.state == 'granted') {
        //   console.log('Permission ' + result.state);
        // } else if (result.state == 'prompt') {
        //   console.log('Permission ' + result.state);
        //   // navigator.geolocation.getCurrentPosition(revealPosition,positionDenied,geoSettings);
        // } else if (result.state == 'denied') {
        //   console.log('Permission ' + result.state);
        // }
        // result.onchange = function() {
        //   report(result.state);
        // }
        resolve(result.state)

        result.onchange = () => {
          // console.log('geolocation permission state has changed to ', result.state);
          this.permission = result.state;
          // console.log("this.permission")
          // console.log(this.permission)
        };
      });
    });
    return promise
  }

  async _initMap() {
    this.permission = await this.handlePermission()
    // console.log()
    // await this.handlePermission()
    let promise = new Promise<any>(async (resolve, reject) => {

      await navigator.geolocation.getCurrentPosition(async (position) => {

        var center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        // this._dataTargetLocation = await this.getAddress(this.source.lat, this.source.lng);
        // this.fromPlace = this._dataTargetLocation.label_target
        // console.log("this._dataTargetLocation")
        // console.log(this._dataTargetLocation)

        // this.destination = {
        //   lat: 36.8064948,
        //   lng: 10.1815316
        // };

        // initialize the map container
        var map = new google.maps.Map((document as any).getElementById('map-canvas'), {
          ...this.options,
          center: center
        });

        map.addListener('tilesloaded', () => {
          this.mapLoaded = true;
        });

        // adding a marker
        // this.markerStart = new google.maps.Marker({
        //   position: this.source,
        //   icon: {
        //     url: 'assets/images/destination_map_marker.png',
        //     // anchor: new google.maps.Point(35, 10),
        //     // scaledSize: new google.maps.Size(100, 100)
        //   },
        //   map: this.map
        // });

        // var destinationMarker = new google.maps.Marker({
        //   position: this.destination,
        //   icon: {
        //     url: 'assets/images/destination_map_marker2.png',
        //     // anchor: new google.maps.Point(35, 10),
        //     // scaledSize: new google.maps.Size(100, 100)
        //   },
        //   map: this.map
        // });
        resolve(map)
        // this.setRoutePolyline();
      });
    });
    return promise
    // this.getAddress(this.source.lat, this.source.lng);
    // console.log("this._dataTargetLocation")
    // console.log(this._dataTargetLocation)
  }

  centerMap() {
    this.map.setCenter(this.source)
  }

  setLocation(latitude, longitude) {
    this.source = {
      lat: latitude,
      lng: longitude
    };
    if (this.markerStart == null) {
      this.markerStart = new google.maps.Marker({
        position: this.source,
        icon: {
          url: 'assets/images/destination_map_marker.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });
    } else {
      this.markerStart.setPosition(this.source)
    }
    // console.log("this.markerStart")
    // console.log(this.markerStart)
    if (this.destination == null) {
      this.centerMap()
    }
  }

  setDestination(latitude, longitude) {
    this.destination = {
      lat: latitude,
      lng: longitude
    };
    if (this.destinationMarker == null) {
      this.destinationMarker = new google.maps.Marker({
        position: this.destination,
        icon: {
          url: 'assets/images/destination_map_marker2.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });
    } else
      this.destinationMarker.setPosition(this.destination)
  }

  setRoutePolyline() {
    // console.log("setRoutePolyline - this.source")
    // console.log(this.source)
    // console.log("setRoutePolyline - this.destination")
    // console.log(this.destination)
    let request = {
      origin: this.source,
      destination: this.destination,
      travelMode: google.maps.TravelMode.DRIVING
    };

    this.ds.route(request, (response, status) => {
      this.dr.setOptions({
        suppressPolylines: false,
        map: this.map
      });

      if (status == google.maps.DirectionsStatus.OK) {
        this.dr.setDirections(response);
      }
    })
  }

  showCanceledDemande() {
    window.scrollTo(0, 0);
    const confirmDialog = this.dialog.open(CauseCanceledDemandeComponent, {
      data: {
        causeDescription: null
      }
    });

    confirmDialog.afterClosed().subscribe(async (result) => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        var updateDemande = await this._cancelDemande(data.causeDescription)
        if (updateDemande != null && updateDemande == true) {
          this.demande = null
          this.userHasDemande = false

          await this._clearDataMaps()

          this.markerStart = await this.initLocation()
        }
        // this.fromPlace = data.position.label_target
        // this._dataTargetLocation = data.position
        // this.setLocation(data.position.lat, data.position.lng)
        // if (this.destination != null) {
        //   this.setRoutePolyline()
        // }
        // console.log("this._dataTargetLocation")
        // console.log(this._dataTargetLocation)
      }
    });
  }

  _clearDataMaps() {
    this._dataTargetLocation = null
    if (this.markerStart != null)
      this.markerStart.setMap(null)
    this.markerStart = null

    this._dataTargetDestination = null
    if (this.destinationMarker != null)
      this.destinationMarker.setMap(null)
    this.destinationMarker = null

    this.dr.setMap(null)
  }

  showLocation() {
    window.scrollTo(0, 0);
    const confirmDialog = this.dialog.open(GetLocationComponent, {
      data: {
        position: null
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        this.fromPlace = data.position.label_target
        this._dataTargetLocation = data.position
        this.setLocation(data.position.lat, data.position.lng)
        if (this.destination != null) {
          this.setRoutePolyline()
        }
        // console.log("this._dataTargetLocation")
        // console.log(this._dataTargetLocation)
      }
    });
  }

  showDestination() {
    window.scrollTo(0, 0);
    const confirmDialog = this.dialog.open(GetLocationComponent, {
      data: {
        position: null
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        this.wherePlace = data.position.label_target
        this._dataTargetDestination = data.position
        this.setDestination(data.position.lat, data.position.lng)
        this.setRoutePolyline()
      }
    });
  }

}

interface marker {
  lat: number;
  lng: number;
  label_target: string;
  main_target: string;
  sencondary_target: string;
}

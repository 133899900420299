// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var baseURLAPI = 'https://logistic.marketbey.tn/api/v0/';

var assetsBaseURL = "https://assets.marketbey.tn/Wassali/";

export const environment = {
  production: false,

  transportorUsers: assetsBaseURL + "users-trans/",
  productsTransportorUsers:
    assetsBaseURL + "users-trans/products/",
  driverTransportor:
    assetsBaseURL + "driver-trans/driver-licence/",
  cardTransportor: assetsBaseURL + "driver-trans/driver-card/",
  vehiculeTransportor:
    assetsBaseURL + "driver-trans/driver-vehicule/",

  // Authentication
  apiAuthentification: baseURLAPI + "transportor/auth",
  apiInscription: baseURLAPI + "transportor/inscrip",
  apiSaveNumTel: baseURLAPI + "transportor/save-num-tel",
  apiSocialAuth: baseURLAPI + "transportor/socialauth",
  apiResetpassword: baseURLAPI + "transportor/mdpoublie",
  apiInfosUser: baseURLAPI + "transportor/infos",

  // get data from BACK
  apiAllTypeService: baseURLAPI + 'transportor/get-services',
  apiAllTypeCamion: baseURLAPI + 'transportor/type-camions',

  // Demandes
  apiUserHasDemande: baseURLAPI + "transportor/has-demande",
  apiCreateDemande: baseURLAPI + "transportor/create-demande",
  apiRefuseDemande: baseURLAPI + "transportor/refuse-demande",
  apiAccepteDemande: baseURLAPI + "transportor/accepte-demande",
  apiPendingProposition:
    baseURLAPI + "transportor/pending-propositions",
  apiUpdateDemande: baseURLAPI + "transportor/update-demande",
  apiCauseCancelDemande:
    baseURLAPI + "transportor/cause-cancel-demande",
  apiDemandesUser:
    baseURLAPI + "transportor/demandes-user",
  apiOneDemande: baseURLAPI + 'transportor/get-demande',
  apiGetPositionDriver:
    baseURLAPI + "transportor/get-position-driver",
  apiStatusDemandes: baseURLAPI + 'transportor/liststatus',
  apiStatisticUser: baseURLAPI + 'transportor/statistic-user',

  // SEARCH GOOGLE
  apiSearchPlace: baseURLAPI + 'gooogle/get-places',
  apiDetailsPlaceGoogle: baseURLAPI + "gooogle/details-place",
  apiDirectionsGoogle: baseURLAPI + "gooogle/get-directions",
  apiDistancesGoogle: baseURLAPI + "gooogle/get-distances",

  ////// FOUNIX
  apiTypesPalettes: baseURLAPI + 'founix/types-palettes',
  apiAllFamilies: baseURLAPI + 'founix/all-families',
  apiAllSubFamily: baseURLAPI + 'founix/all-sub-family',
  apiCreateGrouping: baseURLAPI + 'founix/create-grouping',
  apiListGrouping: baseURLAPI + 'founix/list-grouping',
  apiStatusGroupage: baseURLAPI + 'founix/liststatus',
  apiOneGrouping: baseURLAPI + 'founix/one-grouping',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

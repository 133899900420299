import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-cause-canceled-demande',
  templateUrl: './cause-canceled-demande.component.html',
  styleUrls: ['./cause-canceled-demande.component.css']
})
export class CauseCanceledDemandeComponent implements OnInit {

  listCause: any = []
  // causeDescription: string = ""
  // otherCauseDescription: string = ""

  constructor(public dialogRef: MatDialogRef<CauseCanceledDemandeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private transServices: TransportorsService,) { }

  ngOnInit(): void {
    this._fetchCauseCanceled()
  }

  _fetchCauseCanceled() {
    this.transServices.causeCancelDemande().then((res: any) => {
      if (res) {
        this.listCause = res
      }
    }).catch((e) => {
      // console.log("eeeeeeeexx")
      // console.log(e)
    });
  }

  getCauseCanceled(event: any) {
    this.data.causeDescription = event.target.value
  }

  _otherCause(event: any) {
    this.data.causeDescription = event.target.value
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inscription-wassali',
  templateUrl: './inscription-wassali.component.html',
  styleUrls: ['./inscription-wassali.component.css']
})
export class InscriptionWassaliComponent implements OnInit {

  videoProduct: string = "https://assets.marketbey.tn/Wassali/videos/explication-inscription.mp4"

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, KeyValueDiffers, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { GetLocationComponent } from 'src/app/components/shared/dialog/get-location/get-location.component';
import { NumTelDemandeComponent } from 'src/app/components/shared/dialog/num-tel-demande/num-tel-demande.component';
import { GoogleService } from 'src/app/services/google/google.service';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import { UsersService } from 'src/app/services/users-wassali/users.service';

@Component({
  selector: 'app-grouping-wassali',
  templateUrl: './grouping-wassali.component.html',
  styleUrls: ['./grouping-wassali.component.css']
})
export class GroupingWassaliComponent implements OnInit {

  nowDate = new Date();
  startDate = new Date(this.nowDate.setDate(this.nowDate.getDate() + 1));

  withPayment: boolean = false
  other_longueur: string = null
  other_largeur: string = null
  other_hauteur: string = null
  quantity: any = null

  msgValidation: any = ""
  differ: any;

  listTimepicker: any = [
    { "value": "00:00" },
    { "value": "00:30" },
    { "value": "01:00" },
    { "value": "01:30" },
    { "value": "02:00" },
    { "value": "02:30" },
    { "value": "03:00" },
    { "value": "03:30" },
    { "value": "04:00" },
    { "value": "04:30" },
    { "value": "05:00" },
    { "value": "05:30" },
    { "value": "06:00" },
    { "value": "06:30" },
    { "value": "07:00" },
    { "value": "07:30" },
    { "value": "08:00" },
    { "value": "08:30" },
    { "value": "09:00" },
    { "value": "09:30" },
    { "value": "10:00" },
    { "value": "10:30" },
    { "value": "11:00" },
    { "value": "11:30" },
    { "value": "12:00" },
    { "value": "12:30" },
    { "value": "13:00" },
    { "value": "13:30" },
    { "value": "14:00" },
    { "value": "14:30" },
    { "value": "15:00" },
    { "value": "15:30" },
    { "value": "16:00" },
    { "value": "16:30" },
    { "value": "15:00" },
    { "value": "15:30" },
    { "value": "16:00" },
    { "value": "16:30" },
    { "value": "17:00" },
    { "value": "17:30" },
    { "value": "18:00" },
    { "value": "18:30" },
    { "value": "19:00" },
    { "value": "19:30" },
    { "value": "20:00" },
    { "value": "20:30" },
    { "value": "21:00" },
    { "value": "21:30" },
    { "value": "22:00" },
    { "value": "22:30" },
    { "value": "23:00" },
    { "value": "23:30" },
  ]
  valueTimepicker: any = null
  valueDatepicker: string = null

  plastifications: any = [
    {
      "label": "Oui",
      "value": true
    },
    {
      "label": "Non",
      "value": false
    }
  ]
  isPlastification: any = null
  allPalettes: any = []
  listFamilies: any = []
  dropdownTypeFamily: any = null
  listSubFamilies: any = []
  dropdownTypeSubFamily: any = null
  selectedPalette: any = null
  priceTotal: any = null

  fromPlace: string = ""
  wherePlace: string = ""
  listPlacesFrom: any = []
  listPlacesWhere: any = []

  /////// data google
  mapLoaded: boolean = false;
  map: google.maps.Map = null as any;
  center: google.maps.LatLngLiteral = null as any;

  source: google.maps.LatLngLiteral = null as any;
  markerStart: google.maps.Marker = null
  _dataTargetLocation: marker = null

  destination: google.maps.LatLngLiteral = null as any;
  destinationMarker: google.maps.Marker = null
  _dataTargetDestination: marker = null

  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    zoom: 14
  }

  ds: google.maps.DirectionsService = null as any;
  dr: google.maps.DirectionsRenderer = null as any;
  /////--------------

  private geoCoder;

  listGroupings: any = []
  statusGroupage: any = []

  subscription: Subscription;

  constructor(private transServices: TransportorsService,
    private usersService: UsersService,
    private googleService: GoogleService,
    private differs: KeyValueDiffers,
    private dialog: MatDialog,) {
    this.differ = this.differs.find({}).create();
  }

  async ngOnInit() {
    this.transServices.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusGroupage = lst
      }
    )

    await this._pendingGrouping()
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      this._pendingGrouping()
    });

    await this.allTypePalettes()
    await this.allFamilies()

    this.geoCoder = new google.maps.Geocoder;
    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer({
      map: null,
      suppressMarkers: true
    });
    this.map = await this._initMap();
    this.markerStart = await this.initLocation()
  }
  _pendingGrouping() {
    this.transServices.listGrouping().then((res: any) => {
      // console.log("eeeeeeeexx")
      // console.log(res)
      if (res) {
        this.listGroupings = res
      }
    }).catch((e) => {
      // console.log("eeeeeeeexx")
      // console.log(e)
    });
  }
  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        // console.log('item changed', item);
        if (item.key == "_dataTargetDestination") {
          this.isValide()
        }
        if (item.key == "selectedPalette") {
          this.isValide()
        }
        if (item.key == "quantity") {
          this.isValide()
        }
        if (item.key == "dropdownTypeFamily") {
          this.isValide()
        }
        if (item.key == "dropdownTypeSubFamily") {
          this.isValide()
        }
        if (item.key == "isPlastification") {
          this.isValide()
        }
      });
    }
  }
  async _createGroupage() {
    // console.log("this.valueDatepicker : ")
    // console.log(this.valueDatepicker)
    // console.log("this.valueTimepicker : ")
    // console.log(this.valueTimepicker)

    // var datePlanification = new Date(this.valueDatepicker)

    // if (this.valueTimepicker != null && this.valueTimepicker.value != null && this.valueTimepicker.value != "") {
    //   var timers: any = this.valueTimepicker.value.split(':')
    //   datePlanification.setHours(timers[0])
    //   datePlanification.setMinutes(timers[1])
    // }

    // console.log("this.datePlanification : ")
    // console.log(datePlanification.getTime())
    ////////////////////////////////////////
    var idUser = await this.usersService.getIdUser()
    var newGrouping = {
      idUser: idUser,
      location: JSON.stringify(this._dataTargetLocation),
      destination: JSON.stringify(this._dataTargetDestination),
      palette: JSON.stringify(this.selectedPalette),
      typeSubFamily: this.dropdownTypeSubFamily._id,
      price: this.priceTotal,
      isPlastified: this.isPlastification.value,
      quantity: this.quantity,
      withPayment: this.withPayment
    }

    // var planifiedDate = (<HTMLInputElement>document.getElementById("datepicker"));
    // console.log("this.planifiedTransportDate")
    // console.log(planifiedDate.value)
    // var planifiedTime = (<HTMLInputElement>document.getElementById("timepicker"));
    // console.log("this.planifiedTransportTimeee")
    // console.log(planifiedTime.value)
    if (this.valueDatepicker != null && this.valueDatepicker != "") {

      var datePlanification = new Date(this.valueDatepicker)

      if (this.valueTimepicker != null && this.valueTimepicker.value != null && this.valueTimepicker.value != "") {
        var timers: any = this.valueTimepicker.value.split(':')
        datePlanification.setHours(timers[0])
        datePlanification.setMinutes(timers[1])
      }

      newGrouping["isPlanified"] = true
      newGrouping["planificationDate"] = datePlanification.getTime()
    }

    const currentUser = this.usersService.currentUserValue;
    if (currentUser) {
      var user: any = await this.usersService.getUserInfos()
      if (user.tel_user != null && user.tel_user != "") {
        this.transServices.createGrouping(newGrouping).then(async (res: any) => {
          if (res && res.response && res.response == true) {
            // console.log("resssssssss : ")
            // console.log(res)
            // this.demande = res.data
            // this.userHasDemande = true
            // this.usersService.removeDemandeFromStorage()
            this._dataTargetDestination = null
            this.wherePlace = ""
            this.dropdownTypeFamily = null
            this.selectedPalette = null
            this.dropdownTypeSubFamily = null
            this.isPlastification = null
            this.quantity = null
            this.valueDatepicker = null
            this.valueTimepicker = null
            this.withPayment = false
            this.priceTotal = null
            await this._clearDataMaps()
            this.markerStart = await this.initLocation()
          }
        }).catch((e) => {
          // console.log("eeeeeeeexx")
          // console.log(e)
        });
      } else {
        const confirmDialog = this.dialog.open(NumTelDemandeComponent, {
          data: {
            numTel: null
          }
        });

        confirmDialog.afterClosed().subscribe(async (result) => {
          if (result === true) {
            var data = confirmDialog.componentInstance.data

            await this.usersService.saveNumTelProfile(data.numTel)

            this.transServices.createGrouping(newGrouping).then(async (res: any) => {
              if (res && res.response && res.response == true) {
                // console.log("resssssssss : ")
                // console.log(res)
                // this.demande = res.data
                // this.userHasDemande = true
                // this.usersService.removeDemandeFromStorage()
                this._dataTargetDestination = null
                this.wherePlace = ""
                this.dropdownTypeFamily = null
                this.selectedPalette = null
                this.dropdownTypeSubFamily = null
                this.isPlastification = null
                this.priceTotal = null
                await this._clearDataMaps()
                this.markerStart = await this.initLocation()
              }
            }).catch((e) => {
              // console.log("eeeeeeeexx")
              // console.log(e)
            });
          }
        });
      }
    }
  }
  _clearDataMaps() {
    this._dataTargetLocation = null
    this.markerStart.setMap(null)
    this.markerStart = null

    this._dataTargetDestination = null
    this.destinationMarker.setMap(null)
    this.destinationMarker = null

    this.dr.setMap(null)
  }
  dataPaletteIsValid() {
    if (this.selectedPalette == null) {
      return false
    } else {
      // console.log("dataPaletteIsValid ++ this.selectedPalette")
      // console.log(this.selectedPalette)
      if (this.selectedPalette._id == null || this.selectedPalette._id == "") {
        return false
      }
      if (this.selectedPalette.name == null || this.selectedPalette.name == "") {
        return false
      }
      if (this.selectedPalette.longueur == null || this.selectedPalette.longueur == "") {
        return false
      }
      if (this.selectedPalette.largeur == null || this.selectedPalette.largeur == "") {
        return false
      }
      if (this.selectedPalette.hauteur == null || this.selectedPalette.hauteur == "") {
        return false
      }
      // if (this.quantity == null || this.quantity == "") {
      //   return false
      // }
    }
    return true
  }
  isValide() {
    // console.log("isValiddd")
    // console.log(this._dataTargetLocation)
    if (this._dataTargetLocation == null || this._dataTargetDestination == null) {
      if (this._dataTargetLocation == null) {
        this.msgValidation = "Précisez votre position !!!"
      } else if (this._dataTargetDestination == null) {
        this.msgValidation = "Précisez votre destination !!!"
      }
    } else if (this.quantity == null || this.quantity == "" || Number(this.quantity) < 1) {
      this.msgValidation = "Précisez la quantité de votre marchandise !!!"
    } else if (!this.dataPaletteIsValid()) {
      this.msgValidation = "Sélectionnez la palette !!!"
    } else if (this.dropdownTypeFamily == null || this.dropdownTypeSubFamily == null
      || this.isPlastification == null) {
      if (this.dropdownTypeFamily == null) {
        this.msgValidation = "Sélectionnez la famille !!!"
      } else if (this.dropdownTypeSubFamily == null) {
        this.msgValidation = "Sélectionnez sous famille !!!"
      } else if (this.isPlastification == null) {
        this.msgValidation = "Sélectionnez la plastification !!!"
      }
    } else {
      this.msgValidation = ""
    }
  }
  async allFamilies() {
    await this.transServices.allFamilies().then((res: any) => {
      if (res) {
        // console.log("resssssssss services : ")
        // console.log(res)
        this.listFamilies = res;
      }
    });
  }
  async allSubFamilies() {
    await this.transServices.allSubFamily(this.dropdownTypeFamily._id).then((res: any) => {
      if (res) {
        // console.log("resssssssss services : ")
        // console.log(res)
        this.listSubFamilies = res;
      }
    });
  }
  selectFamily() {
    this.dropdownTypeSubFamily = null
    this.allSubFamilies()
  }
  async allTypePalettes() {
    await this.transServices.allTypePalettes().then((res: any) => {
      if (res) {
        // console.log("resssssssss services : ")
        // console.log(res)
        this.allPalettes = res;
      }
    });
  }
  calculPricePalette() {
    // console.log("dddd")
    if (this.selectedPalette != null) {
      if (this.selectedPalette._id != null) {
        if (this.selectedPalette._id == "other") {
          if (this.selectedPalette.longueur != null && this.selectedPalette.longueur != ""
            && this.selectedPalette.largeur != null && this.selectedPalette.largeur != ""
            && this.selectedPalette.hauteur != null && this.selectedPalette.hauteur != "") {
            var surfacePalette: any = (this.selectedPalette.longueur / 100) * (this.selectedPalette.largeur / 100)
            // console.log("surfacePaletteeeee")
            // console.log(surfacePalette)
            var percentPalette: any = (surfacePalette.toFixed(3) / 1.2).toFixed(3)
            // console.log("percentPaletteeee")
            // console.log(percentPalette)

            var decimal = Math.floor(percentPalette);
            // console.log("decimal")
            // console.log(decimal)
            var price = decimal * 35
            // console.log("decimal * 35")
            // console.log(price)

            var rest = percentPalette - decimal
            // console.log("rest")
            // console.log(rest)
            /*if (rest > 0 && rest < 0.25) {
              price += (35 / 4)
            } else */if (rest > 0 && rest <= 0.5) {
              price += (35 / 2)
            } else {
              price += 35
            }

            // console.log("priceeeeee")
            // console.log(price)

            this.selectedPalette.price = price

            if (this.quantity != null && this.quantity != "" && Number(this.quantity) > 0) {
              this.priceTotal = this.quantity * price
            } else {
              this.priceTotal = null
            }
          } else {
            this.priceTotal = null
          }
        } else {
          this.priceTotal = this.quantity * this.selectedPalette.price
        }
      }
    }

    // if (this.selectedPalette != null && this.selectedPalette._id != null && this.selectedPalette._id == "other"
    //   && this.selectedPalette.longueur != null && this.selectedPalette.longueur != ""
    //   && this.selectedPalette.largeur != null && this.selectedPalette.largeur != ""
    //   && this.selectedPalette.hauteur != null && this.selectedPalette.hauteur != "") {
    //   var percentPalette = (this.selectedPalette.longueur * this.selectedPalette.largeur) / 1.2

    //   var price = percentPalette * 35
    //   this.selectedPalette.price = price

    //   if (this.quantity != null && this.quantity != "") {
    //     this.priceTotal = this.quantity * price
    //   }
    // }

  }
  inputOtherLongueur(text) {
    if (this.selectedPalette != null
      && this.selectedPalette._id == "other") {
      this.selectedPalette.longueur = text
    }
    this.calculPricePalette()
  }
  inputOtherLargeur(text) {
    if (this.selectedPalette != null
      && this.selectedPalette._id == "other") {
      this.selectedPalette.largeur = text
    }
    this.calculPricePalette()
  }
  inputOtherHauteur(text) {
    if (this.selectedPalette != null
      && this.selectedPalette._id == "other") {
      this.selectedPalette.hauteur = text
    }
    this.calculPricePalette()
  }
  getTypePalette(event: any) {
    this.selectedPalette = event.target.value
    // console.log("resssssssss event.target.value : ")
    // console.log(event.target.value)
    if (event.target.value == "other") {
      this.selectedPalette = {
        _id: "other",
        name: "Autre"
      }
      this.priceTotal = null
    } else {
      this.other_longueur = null
      this.other_largeur = null
      this.other_hauteur = null
      this.selectedPalette = this.allPalettes.find((pal) => pal._id === event.target.value)

      if (this.quantity != null && this.quantity != "") {
        this.priceTotal = this.quantity * this.selectedPalette.price
      }
    }

    // console.log("resssssssss selectedPalette : ")
    // console.log(this.selectedPalette)
  }

  async _initMap() {
    let promise = new Promise<any>(async (resolve, reject) => {

      await navigator.geolocation.getCurrentPosition(async (position) => {

        var center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        // initialize the map container
        var map = new google.maps.Map((document as any).getElementById('map-canvas'), {
          ...this.options,
          center: center
        });

        map.addListener('tilesloaded', () => {
          this.mapLoaded = true;
        });

        resolve(map)
      });
    });
    return promise
  }
  async initLocation() {
    let promise = new Promise<any>(async (resolve, reject) => {
      await navigator.geolocation.getCurrentPosition(async (position) => {
        // console.log("positionnnnnnnn + ")
        // console.log(position)
        this.source = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        // console.log("this.source initLocation")
        // console.log(this.source)

        this._dataTargetLocation = await this.getAddress(this.source.lat, this.source.lng);
        this.fromPlace = this._dataTargetLocation.label_target

        this.map.setCenter(this.source)
        await this.isValide()

        // adding a marker
        resolve(new google.maps.Marker({
          position: this.source,
          icon: {
            url: 'assets/images/destination_map_marker.png',
            // anchor: new google.maps.Point(35, 10),
            // scaledSize: new google.maps.Size(100, 100)
          },
          map: this.map
        }))
      });
      await this.isValide()
    });

    return promise
  }

  getAddress(latitude, longitude): any {
    let promise = new Promise<any>((resolve, reject) => {
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        // console.log(results);
        // console.log(status);
        if (status === 'OK') {
          if (results[0]) {
            resolve({
              lat: latitude,
              lng: longitude,
              label_target: results[0].formatted_address,
              main_target: results[0].address_components[results[0].address_components.length - 1].long_name,
              sencondary_target: results[0].address_components[results[0].address_components.length - 1].long_name
            })
            // this.address = results[0].formatted_address;
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
    });
    return promise
  }
  _fetchAllPlace(place) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.googleService.allSearchPlace(place).then((res: any) => {
        if (res) {
          resolve(res.predictions)
        }
      }).catch((e) => {
        resolve([])
      });
    });
    return promise
  }
  async _fetchAllPlaceFrom(place) {
    this.listPlacesWhere = []
    this.listPlacesFrom = await this._fetchAllPlace(place)
  }
  async selectPlaceFrom(event) {
    var detailsPlaceFrom = await this._fetchDetailsPlace(event.target.content.place_id)
    this.fromPlace = event.target.content.description
    this._dataTargetLocation = {
      label_target: event.target.content.description,
      main_target: (event.target.content.structured_formatting != null && event.target.content.structured_formatting.main_text != null)
        ? event.target.content.structured_formatting.main_text : event.target.content.description,

      sencondary_target: (event.target.content.structured_formatting != null && event.target.content.structured_formatting.secondary_text != null)
        ? event.target.content.structured_formatting.secondary_text : event.target.content.description,

      lat: detailsPlaceFrom.geometry.location.lat,
      lng: detailsPlaceFrom.geometry.location.lng,
    }
    this.setLocation(detailsPlaceFrom.geometry.location.lat, detailsPlaceFrom.geometry.location.lng)
    if (this.destination != null) {
      this.setRoutePolyline()
    }
    this.listPlacesFrom = []
  }
  _fetchDetailsPlace(placeId) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.googleService.getPlaceDetailFromId(placeId).then((res: any) => {
        if (res) {
          resolve(res)
        }
      }).catch((e) => {
        resolve(null)
      });
    });
    return promise
  }
  async _fetchAllPlaceWhere(place) {
    this.listPlacesFrom = []
    this.listPlacesWhere = await this._fetchAllPlace(place)
  }
  async selectPlaceWhere(event) {
    // console.log("markerStart")
    // console.log(this.markerStart)
    var detailsPlaceWhere = await this._fetchDetailsPlace(event.target.content.place_id)
    this.wherePlace = event.target.content.description
    this._dataTargetDestination = {
      label_target: event.target.content.description,
      main_target: (event.target.content.structured_formatting != null && event.target.content.structured_formatting.main_text != null)
        ? event.target.content.structured_formatting.main_text : event.target.content.description,
      sencondary_target:
        (event.target.content.structured_formatting != null && event.target.content.structured_formatting.secondary_text != null)
          ? event.target.content.structured_formatting.secondary_text : event.target.content.description,
      lat: detailsPlaceWhere.geometry.location.lat,
      lng: detailsPlaceWhere.geometry.location.lng,
    }
    this.setDestination(detailsPlaceWhere.geometry.location.lat, detailsPlaceWhere.geometry.location.lng)
    this.setRoutePolyline()
    this.listPlacesWhere = []
  }
  setLocation(latitude, longitude) {
    this.source = {
      lat: latitude,
      lng: longitude
    };
    if (this.markerStart == null) {
      this.markerStart = new google.maps.Marker({
        position: this.source,
        icon: {
          url: 'assets/images/destination_map_marker.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });
    } else {
      this.markerStart.setPosition(this.source)
    }
    // this.markerStart.setPosition(this.source)
    if (this.destination == null) {
      this.centerMap()
    }
  }
  centerMap() {
    this.map.setCenter(this.source)
  }
  setDestination(latitude, longitude) {
    this.destination = {
      lat: latitude,
      lng: longitude
    };
    if (this.destinationMarker == null) {
      this.destinationMarker = new google.maps.Marker({
        position: this.destination,
        icon: {
          url: 'assets/images/destination_map_marker2.png',
        },
        map: this.map
      });
    } else
      this.destinationMarker.setPosition(this.destination)
  }
  setRoutePolyline() {
    let request = {
      origin: this.source,
      destination: this.destination,
      travelMode: google.maps.TravelMode.DRIVING
    };
    // console.log("request - setRoutePolyline")
    // console.log(request)

    this.ds.route(request, (response, status) => {
      this.dr.setOptions({
        suppressPolylines: false,
        map: this.map
      });

      if (status == google.maps.DirectionsStatus.OK) {
        this.dr.setDirections(response);
      }
    })
  }
  showLocation() {
    window.scrollTo(0, 0);
    const confirmDialog = this.dialog.open(GetLocationComponent, {
      data: {
        position: null
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        this.fromPlace = data.position.label_target
        this._dataTargetLocation = data.position
        this.setLocation(data.position.lat, data.position.lng)
        if (this.destination != null) {
          this.setRoutePolyline()
        }
        // console.log("this._dataTargetLocation")
        // console.log(this._dataTargetLocation)
      }
    });
  }

  showDestination() {
    // window.scrollTo(0, 0);
    const confirmDialog = this.dialog.open(GetLocationComponent, {
      data: {
        position: null
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        this.wherePlace = data.position.label_target
        this._dataTargetDestination = data.position
        this.setDestination(data.position.lat, data.position.lng)
        this.setRoutePolyline()
      }
    });
  }

  goToNewTab(idGrouping, route) {
    this.transServices.goToNewTab(idGrouping, route)
  }
}


interface marker {
  lat: number;
  lng: number;
  label_target: string;
  main_target: string;
  sencondary_target: string;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  constructor(private http: HttpClient,) { }

  async allSearchPlace(place) {
    return await new Promise((slv) => {
      this.http.post(environment.apiSearchPlace, {
        "input": place,
      }).subscribe(
        (data: any) => {
          // console.log("data")
          // console.log(data)
          if (data && data.status!=null && data.status=="OK") {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async getPlaceDetailFromId(placeId) {
    return await new Promise((slv) => {
      this.http.post(environment.apiDetailsPlaceGoogle, {
        "placeId": placeId,
      }).subscribe(
        (data: any) => {
          // console.log("result['result']")
          // console.log(data)
          if (data && data.status!=null && data.status=="OK") {
            slv(data['result']);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '../services/users-wassali/users.service';

@Injectable({
  providedIn: 'root'
})
export class GuardGuard implements CanActivate {
  constructor(
    private usersService: UsersService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.usersService.currentUserValue;
    // console.log("currentUser")
    // console.log(currentUser)
    if (currentUser) {
      return true
    }

    this.usersService.saveRoute(state.url)
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
    return false

  }

}

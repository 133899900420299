import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { UsersService } from 'src/app/services/users-wassali/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  passwordIsChecked: boolean = false
  login: string = ""
  password: string = ""

  msgErrorUsername: string = null;
  msgErrorPassword: string = null;
  msgErrorLogin: string = null;

  user: SocialUser;
  loggedIn: boolean;

  constructor(private usersService: UsersService,
    private fbService: SocialAuthService,
    private router: Router,) { }

  ngOnInit(): void {
    const currentUser = this.usersService.currentUserValue;
    if (currentUser) {
      this.router.navigate(['/'])
    }

    this.fbService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
      // console.log("login facebook")
      // console.log(this.user);
    });
  }

  // backClicked() {
  //   // this._location.back();
  //   console.log("this._location")
  //   console.log(this._location)
  // }

  checkPassword() {
    this.passwordIsChecked = !this.passwordIsChecked;
  }

  authentification() {
    // this.initMsgError();
    // if (username.value == "" || password.value == "") {
    //   if (username.value == "") {
    //       this.msgErrorUsername = "Email est requis";
    //     }
    //   if (password.value == "") {
    //     this.msgErrorPassword = "Le mot de passe est requis";
    //   }
    // } else {
    this.usersService.login({ username: this.login, password: this.password }).then(async (res: any) => {
      // console.log("res login : ")
      // console.log(res)
      if (res) {
        if (res.response == true) {
          let route = await this.usersService.getAfterRoute()
          // window.location.reload()
          location.href = route
          // this.router.navigate([route])
          this.usersService.removeRoute()
        } else {
          this.msgErrorLogin = res.msg
          this.password = ""
        }
      } else {
        this.msgErrorLogin = "Impossible d'authentifié"
        this.password = ""
      }
    })
    // }
  }
  // checkLoginState() {
  //   FB.getLoginStatus(function(response) {
  //     statusChangeCallback(response);
  //   });
  // }

  async loginFB() {
    await this.fbService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // console.log("ussssser : ")
    // console.log(this.user)
    if (this.user != null) {
      var creds: any = {
        "last_name": this.user.lastName,
        "first_name": this.user.firstName,
        "username": this.user.email,
        "email": this.user.email
      };

      creds.source = "fb"
      this.usersService.socialInscription(creds).then(async (res: any) => {
        if (res) {
          //console.log("res FB :")
          //console.log(res)
          let route = await this.usersService.getAfterRoute()
          // window.location.reload()
          location.href = route
          // this.router.navigate([route])
          this.usersService.removeRoute()
        }
        else {
          // console.log("erreur")
        }
      })
    }
  }

}

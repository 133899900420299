import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-num-tel-demande',
  templateUrl: './num-tel-demande.component.html',
  styleUrls: ['./num-tel-demande.component.css']
})
export class NumTelDemandeComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NumTelDemandeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }

}

<mat-dialog-content class="mat-typography">
    <div class="jconfirm jconfirm-light jconfirm-open">
        <div class="jconfirm-bg"
            style="opacity: 0.8; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
        </div>
        <div class="jconfirm-scrollpane">
            <div class="jc-bs3-container container">
                <div
                    class="jc-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="jconfirm-box-container col-md-8 col-md-offset-2 col-xs-12"
                        style="transform: translate(0px, 0px);">
                        <div class="jconfirm-box jconfirm-hilight-shake jconfirm-type-orange jconfirm-type-animated"
                            role="dialog" aria-labelledby="jconfirm-box43245" tabindex="-1"
                            style="margin-top: 50px; margin-bottom: 50px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1); transition-property: all, margin;">
                            <div class="jconfirm-closeIcon" style="display: none;">×</div>
                            <div class="jconfirm-title-c"><span class="jconfirm-icon-c"><i
                                        class="fa fa-taxi"></i></span><span class="jconfirm-title">Ajouter votre numéro
                                    de téléphone</span>
                            </div>
                            <div class="jconfirm-content-pane"
                                style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="jconfirm-content" id="jconfirm-box43245">
                                    <div class="informationDiv">
                                        <div class="form-group">
                                            <div id="uyelik">
                                                <!-- <legend>Liste des motifs</legend> -->
                                                <div class="fullname">
                                                    <div class=" col-md-12">
                                                        <div class="row">
                                                            <!-- <div class="col-md-11">
                                                                <div class="row">
                                                                    <label for="fullname" class="control-label">{{cause?.description}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="row"> -->
                                                            <input type="number"
                                                                class="select2-selection select2-selection--single form-control input-lg"
                                                                name="numTel" min="1" placeholder="Numéro de téléphone"
                                                                [(ngModel)]="data.numTel">

                                                                <mat-error style="color: red;font-weight: bold;" *ngIf="data?.numTel==null || data?.numTel?.toString()=='' || data?.numTel?.toString()?.length!=8">Entrez votre numéro
                                                                    de téléphone
                                                                </mat-error>
                                                            <!-- </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div *ngIf="data?.numTel?.toString()!='' && data?.numTel?.toString()?.length==8" class="form-button col-md-12"
                                                        id="bookingButton" style="padding:0 20px 0 20px;">
                                                        <!-- <button type="submit" class="btn form-btn btn-lg btn-block"
                                                            (click)="_createGroupage()">Passer le groupage</button> -->

                                                        <button type="button" class="btn form-btn btn-lg btn-dark" mat-button
                                                            [mat-dialog-close]="true">Passer la
                                                            demande</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><span></span>
                                </div>
                            </div>
                            <!-- <div class="jconfirm-buttons">
                                <button type="button" class="btn btn-dark" mat-button [mat-dialog-close]="true"
                                    *ngIf="data.numTel!=''">Passer la demande</button>
                                <button type="button" class="btn btn-default" mat-button
                                    mat-dialog-close>Annuler</button>
                            </div> -->
                            <!-- <div class="jconfirm-clear"></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
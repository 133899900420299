<mat-dialog-content class="mat-typography">
    <div class="jconfirm jconfirm-light jconfirm-open">
        <div class="jconfirm-bg"
            style="opacity: 0.8; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
        </div>
        <div class="jconfirm-scrollpane">
            <div class="jc-bs3-container container">
                <div
                    class="jc-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="jconfirm-box-container col-md-8 col-md-offset-2 col-xs-12"
                        style="transform: translate(0px, 0px);">
                        <div class="jconfirm-box jconfirm-hilight-shake jconfirm-type-orange jconfirm-type-animated"
                            role="dialog" aria-labelledby="jconfirm-box43245" tabindex="-1"
                            style="margin-top: 50px; margin-bottom: 50px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1); transition-property: all, margin;">
                            <div class="jconfirm-closeIcon" style="display: none;">×</div>
                            <div class="jconfirm-title-c"><span class="jconfirm-icon-c"><i
                                        class="fa fa-taxi"></i></span><span class="jconfirm-title">Motif
                                    d'annulation</span>
                            </div>
                            <div class="jconfirm-content-pane"
                                style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="jconfirm-content" id="jconfirm-box43245">
                                    <div class="informationDiv">
                                        <div class="form-group">
                                            <div id="uyelik">
                                                <!-- <legend><big>SUMMARY</big></legend>
                                                <div class="transfer" style="background:#fcf5a6; padding:5px">
                                                    <span id="aPoint" style="font-size:18px; font-weight:bold">V232+2M
                                                        Oued
                                                        Ellil, Tunisie</span> <img src="images/bidirectional-arrows.svg"
                                                        width="12" height="12" id="imgDirection" alt="Direction"
                                                        class="loaded"> <span id="bPoint"
                                                        style="font-size:18px; font-weight:bold">W5RR+R2J, Raoued,
                                                        Tunisie</span>
                                                    <br>Car (max. 3 persons) - Round Trip<br>Today - Now - <b>$61</b>
                                                </div><br> -->
                                                <legend>Liste des motifs</legend>
                                                <div class="fullname" *ngFor="let cause of listCause">
                                                    <div class=" col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-11">
                                                                <div class="row">
                                                                    <label for="fullname" class="control-label">{{cause?.description}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="row">
                                                                    <input type="radio" id="{{cause.value}}" name="cause" value="{{cause.description}}"
                                                                    (change)="getCauseCanceled($event)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <label for="fullname" class="control-label">Fullname:</label> -->
                                                    <!-- <input type="text" id="fullname" class="form-control"><br> -->
                                                </div>
                                                <!-- <div>
                                                    <div class="phone">
                                                        <label for="phone" class="control-label">Phone:</label>
                                                        <input type="tel" id="phone" class="form-control"
                                                            data-inputmask-mask="0(999)999-99-99" data-val="true"
                                                            data-val-required="Required"><br>
                                                    </div>
                                                    <div class="email">
                                                        <label for="email" class="control-label">E-mail:</label>
                                                        <input type="email" id="email" class="form-control"><br>
                                                    </div>
                                                    <div class="pickup">
                                                        <label for="pickup" class="control-label">Pick-up
                                                            Address:</label>
                                                        <textarea rows="3" id="pickup"
                                                            class="form-control"></textarea><br>
                                                    </div>
                                                    <div class="dropoff">
                                                        <label for="dropoff" class="control-label">Drop-off
                                                            Address:</label>
                                                        <textarea rows="3" id="dropoff"
                                                            class="form-control"></textarea><br>
                                                    </div>
                                                </div> -->
                                                <legend>Autre</legend>
                                                <div class="notes">
                                                    <textarea rows="3" id="notes" class="form-control"  (input)="_otherCause($event)"
                                                        placeholder="If you have a note you want to let us know please write."></textarea>
                                                </div>
                                            </div>


                                        </div>
                                    </div><span></span>
                                </div>
                            </div>
                            <div class="jconfirm-buttons">
                                <button type="button" class="btn btn-dark" mat-button
                                    [mat-dialog-close]="true" *ngIf="data.causeDescription!=null">Terminé</button>
                                <button type="button" class="btn btn-default" mat-button
                                    mat-dialog-close>Annuler</button>
                            </div>
                            <div class="jconfirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
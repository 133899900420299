import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUserW')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  getIdUser() {

    var token = localStorage.getItem('token-wassali')
    // console.log("tokennnnnnnnn : ");

    if (token && token != null) {
      var decoded = jwt_decode(token);
      // console.log("idddddddddddddddddduser")
      // console.log(decoded)
      return decoded["id"]
    } else return null
  }

  getUserInfos() {
    var token = localStorage.getItem('token-wassali')


    //console.log("tokennnnnnnnn : ");
    //console.log(token);

    return new Promise((solve) => {
      if (token && token != null) {
        var decoded = jwt_decode(token);

        this.http.post(environment.apiInfosUser, { token: token, idUser: decoded["id"] }).subscribe((res: any) => {
          // console.log("hajerrrrrrrrrrrrrrrrrrrrrr get info user");
          // console.log(res);
          if (res && res.response && res.data) {
            solve(res.data);
          } else
            solve(true);
        },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            solve(false);
          })
      } else {
        solve(false);
      }
    });

    // let user = localStorage.getItem('userdetails');
    // if (user && JSON.parse(user))
    //   return JSON.parse(user);

    // return null;
  }

  isConnected() {
    var token = localStorage.getItem('token-wassali')
    if (token)
      return true;

    return false
  }

  saveUserCreds(usercreds) {
    localStorage.setItem('token-wassali', JSON.stringify(usercreds.token));
    localStorage.setItem('currentUserW', JSON.stringify(usercreds));
  }

  login(creds) {
    return new Promise((solve) => {
      this.http.post(environment.apiAuthentification, creds).subscribe((res: any) => {
        // console.log("resssssss login");
        // console.log(res);
        if (res) {
          if (res.response && res.response == true && res.data) {
            this.saveUserCreds(res.data);
            this.currentUserSubject.next(res.data);
          }
          solve(res);
        }
      })
    });
  }

  inscription(formData) {
    return new Promise((solve) => {
      this.http.post(environment.apiInscription, formData).subscribe((res: any) => {
        // console.log("resssssss inscription");
        // console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            this.currentUserSubject.next(res.data);
          }
          solve(res);
        }
      })
    });
  }

  async saveNumTelProfile(numTel) {
    var idUser = await this.getIdUser()
    return new Promise((solve) => {
      this.http.post(environment.apiSaveNumTel, {
        "idUser": idUser,
        "numTel": numTel
      }).subscribe((res: any) => {
        // console.log("resssssss inscription");
        // console.log(res);
        if (res) {
          if (res.response && res.data) {
            // this.saveUserCreds(res.data);
          }
          solve(res);
        }
      })
    });
  }

  socialInscription(creds) {
    //console.log("source");
    //console.log(source);
    // if (source == "fb") creds["source"] = "fb";
    //console.log("creds");
    //console.log(creds);
    return new Promise((solve) => {
      this.http.post(environment.apiSocialAuth, creds).subscribe((res: any) => {
        // console.log("FN register service");
        // console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            solve(true);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  forgetPw(email) {
    return new Promise((solve) => {
      this.http.post(environment.apiResetpassword, email).subscribe((res: any) => {
        //console.log("res forget password")
        //console.log(res)
        if (res) {
          solve(res.response);
        }
      })
    });
  }

  deconnexion() {
    localStorage.removeItem('token-wassali');
    localStorage.removeItem('currentUserW');
    this.currentUserSubject.next(null);
  }

  /////// LOCAL STORAGE
  saveRoute(route) {
    localStorage.setItem('after-route', route);
  }
  saveDemandeInStorage(newDemande, route) {
    this.saveRoute(route)
    localStorage.setItem('demande', JSON.stringify(newDemande));
  }

  getDemandeFromStorage() {
    let demande = localStorage.getItem('demande');
    if (demande == null) {
      return null
    } else
      return JSON.parse(demande)
  }

  removeDemandeFromStorage() {
    localStorage.removeItem('demande');
    this.removeRoute()
  }
  removeRoute() {
    localStorage.removeItem('after-route');
  }

  getAfterRoute() {
    let route = localStorage.getItem('after-route');
    if (route == null) {
      return '/'
    } else {
      return route
    }
  }
  /////// LOCAL STORAGE
}

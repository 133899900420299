import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users-wassali/users.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  email: string = ""

  constructor(private usersService: UsersService,
    private router: Router,) { }

  ngOnInit(): void {
  }

  forgetPassWord(){
    if(this.email == ""){
      alert("Veuillez remplir le champs.")
    } else {

      this.usersService.forgetPw({email:this.email}).then((res: any) => {
        if(res){
          // return true
          this.router.navigate(['/login'])
        } else return false
      })
    }
  }

}

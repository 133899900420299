<div class="body-login img js-fullheight" style="background-image: url('assets/images/bg.gif'); height: 100%;">
    <section class="ftco-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center mb-5">
                    <!-- <h2 class="heading-section">Login #10</h2> -->
                    <img src="assets/images/logo_wassali_blanc.png" style="width: 100%;" alt="logo wassali">
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="login-wrap p-0">
                        <h3 class="mb-4 text-center">Créer un nouveau compte</h3>
                        <form action="#" class="signin-form">
                            <div class="form-group">
                                <input type="text" name="firstName" class="form-control" placeholder="Prénom *" required
                                    [(ngModel)]="firstName" [class]="(firstName=='') ? 'input-border' : ''">
                            </div>
                            <div class="form-group">
                                <input type="text" name="lastName" class="form-control" placeholder="Nom de la famille *" required
                                    [(ngModel)]="lastName" [class]="(lastName=='') ? 'input-border' : ''">
                            </div>
                            <div class="form-group">
                                <input type="number" name="numTel" class="form-control" placeholder="Numéro de téléphone *" required
                                    [(ngModel)]="numTel" [class]="(numTel.toString()=='' || numTel.toString().length!=8) ? 'input-border' : ''">
                            </div>
                            <div class="form-group">
                                <input type="email" name="email" class="form-control" placeholder="Email *" required
                                    [(ngModel)]="email">
                            </div>
                            <div class="form-group">
                                <input name="password" id="password-field" [(ngModel)]="password" [type]="(passwordIsChecked==false) ? 'password' : 'text'"
                                    class="form-control" placeholder="Password" required [class]="(password=='') ? 'input-border' : ''">
                                <span (click)="checkPassword()"
                                    [class]="(passwordIsChecked==false) ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw field-icon toggle-password fa-eye-slash'"></span>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="form-control btn btn-signup submit px-3"
                                    (click)="signUP()">Créer un compte</button>
                            </div>
                            <div class="msg-error text-center p-t-20">
                                <label class="txt1 msg-error-color " *ngIf="msgErrorLogin!=null">
                                    {{msgErrorLogin}}
                                </label>
                            </div>
                        </form>
                        <!-- <p class="w-100 text-center">&mdash; Ou &mdash;</p>
                        <div class="social d-flex text-center">
                            <a href="#" class="px-2 py-2 mr-md-1 rounded"><span class="ion-logo-facebook mr-2"></span>
                                Facebook</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="google-image">
    <div id="map">
        <div #map id="map-canvas"></div>
        <div class="map-loader-container" *ngIf="!mapLoaded">
            <div class="map-loader"></div>
        </div>
    </div>
</div>

<div class="booking-form-wrapper" style="z-index: 51" id="priceCalc1"
    *ngIf="permission=='denied' && userHasDemande==false && demande==null">
    <div class="col-sm-12">
        <div class="row">
            <div class="form-wrap" style="border-radius: 10px;">
                <h2 style="line-height: 100%; padding:10px !important; text-transform: uppercase;
                font-family: Circular,'Helvetica Neue',Helvetica,Arial,sans-serif;
                text-shadow: 0 1px 1px #49515e;
                font-weight: 700;
                text-rendering: optimizelegibility; margin-top: 12px;"> La géolocalisation n'est pas
                    activée.<br>Veuillez activer pour utiliser cette fonctionnalité </h2>
            </div>
        </div>
    </div>
</div>

<div class="booking-form-wrapper" style="z-index: 51" id="priceCalc1"
    *ngIf="permission!='denied' && userHasDemande==false && demande==null">
    <div class="col-sm-12">
        <div class="row">
            <div class="form-wrap" style="border-radius: 10px;">
                <!-- <div class="form-headr"></div> -->
                <div class="minmax"><span id="minimize"><a href="javascript:void(0)" onClick="minmax('min')"
                            class="dark-btn" data-toggle="tooltip" title="Click to Minimize"><span
                                class="fa-stack fa-sm"><i class="fa fa-square-o fa-stack-2x dark-btn"></i><i
                                    class="fa fa-window-minimize fa-stack-1x dark-btn"></i></span></a></span></div>
                <h2 style="line-height: 100%; padding:10px !important; text-transform: uppercase;
                font-family: Circular,'Helvetica Neue',Helvetica,Arial,sans-serif;
                text-shadow: 0 1px 1px #49515e;
                font-weight: 700;
                text-rendering: optimizelegibility; margin-top: 12px;"> Comment prendre WASSALI ? </h2>
                <p id="maximize" style="text-align:center; display:none; margin-top:-10px"><a href="javascript:void(0)"
                        onClick="minmax('max')" class="dark-btn" data-toggle="tooltip" title="Click to Maximize"
                        data-placement="bottom"><span class="fa-stack fa-sm"><i
                                class="fa fa-square-o fa-stack-2x dark-btn"></i><i
                                class="fa fa-window-maximize fa-stack-1x dark-btn"></i></span></a></p>
                <div class="form-select">
                    <form action="javascript:void(0);" method="post" name="fm" id="fm">
                        <!-- From -->
                        <div class="col-sm-6 booking-fields bf3">
                            <div class="row input-group input-group-lg select2-bootstrap-append">
                                <input type="text" class="form-control" name="search-from"
                                    aria-describedby="search-from" [(ngModel)]="fromPlace"
                                    (input)="_fetchAllPlaceFrom($event.target.value)" placeholder="Lieu de départ"
                                    autofocus>

                                <ul class="list-group list-group-flush list-places"
                                    *ngIf="listPlacesFrom.length > 0 && fromPlace!=''">
                                    <li class="list-group-item select2-results__option select2-results__message"
                                        *ngFor="let c of listPlacesFrom" appHighlight [content]="c"
                                        [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                                        (click)="selectPlaceFrom($event)">
                                        {{c.description}}
                                    </li>
                                </ul>

                                <span class="input-group-btn">
                                    <button id="startBut" (click)="showLocation()" class="btn form-btn"
                                        style="margin-top:0.5px; padding-bottom:30px"><i
                                            class="fa fa-map-marker"></i></button>
                                </span>
                            </div>
                            <input type="hidden" id="startVal" value="">
                        </div>
                        <!-- From -->
                        <!-- Where -->
                        <div class="col-sm-6 booking-fields bf4">
                            <div class="row input-group input-group-lg select2-bootstrap-append">
                                <!-- <select id="end" name="end" class="end-ajax form-control input-lg">
                                    <option value="9" selected="selected">Where?</option>
                                </select> -->
                                <input type="email" class="form-control" name="search-where"
                                    aria-describedby="search-where" [(ngModel)]="wherePlace"
                                    (input)="_fetchAllPlaceWhere($event.target.value)" placeholder="Destination"
                                    autofocus>
                                <ul class="list-group list-group-flush list-places"
                                    *ngIf="listPlacesWhere.length > 0 && wherePlace!=''">
                                    <li class="list-group-item select2-results__option select2-results__message"
                                        *ngFor="let c of listPlacesWhere" appHighlight [content]="c"
                                        [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                                        (click)="selectPlaceWhere($event)">
                                        {{c.description}}
                                    </li>
                                </ul>

                                <span class="input-group-btn">
                                    <button id="endBut" (click)="showDestination()" class="btn form-btn"
                                        style="margin-top:0.5px; padding-bottom:30px"><i
                                            class="fa fa-map-marker"></i></button>
                                </span>
                            </div>
                            <input type="hidden" id="endVal" value="">
                        </div>
                        <!-- Where -->
                        <!-- Carousel -->
                        <div class="col-md-12 bf6" id="route" style="text-align: center; overflow: hidden;"
                            *ngIf="allCamion.length > 0 && _dataTargetLocation!=null && _dataTargetDestination != null">
                            <div class="demo">
                                <carousel class="carousel-html-content" style="z-index:0;" [cellWidth]="300">

                                    <div class="carousel-cell" *ngFor="let camion of allCamion"
                                        (click)="selectCamion(camion)"
                                        [class]="(dataServiceDemande!=null && dataServiceDemande.name==camion.name) ? 'camion-selected' : ''">
                                        <div class="row" style="width: 100%;">
                                            <div class="col-md-12">
                                                <div class="col-sm-12 tariffs-header-home">
                                                    <div class="tariffs-vehicle-wrap-home">
                                                        <div class="tariffs-vehicle">
                                                            <img src="{{camion.image}}" alt="camion wassali"
                                                                width="146">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 tariffs-vehicle-div">
                                                    <div class="text-center">
                                                        <h6 class="mat-h6-home">{{camion?.name}}</h6>
                                                    </div>
                                                    <div
                                                        class="description-service description-service-border text-center">
                                                        {{camion?.service}}<br>
                                                        <div class="col-md-12" id="route"
                                                            style="text-align: center; overflow: hidden;">
                                                            <span id="aPoint"
                                                                style="font-size:18px; font-weight:bold">{{camion?.masse?.min}}
                                                            </span>
                                                            <img src="assets/images/direct-arrow.svg"
                                                                style="width: 12px; height: 12px;" alt="camion wassali">
                                                            <span id="bPoint"
                                                                style="font-size:18px; font-weight:bold">{{camion?.masse?.max}}
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row" style="width: 100%;">
                                            hello
                                        </div> -->

                                        <div class="panel-bottom"
                                        *ngIf="dataServiceDemande!=null && dataServiceDemande.name==camion.name && camion?.possibleFridged==true">
                                            <input id="chk-fridge" type="checkbox" [(ngModel)]="isFridged" name="isFridged"/>
                                            <h5 class="label-fridge">Frigorifique </h5>
                                        </div>


                                        <div class="center"
                                            *ngIf="dataServiceDemande!=null && dataServiceDemande.name==camion.name">
                                            <img style="width: 40px;" src="assets/images/check-mark.png"
                                                alt="check camion wassali">
                                        </div>
                                        
                                    </div>
                                </carousel>
                            </div>
                        </div>
                        <!-- Carousel -->
                        <!-- Vehicle Type -->
                        <div class="col-sm-4 booking-fields bf1" *ngIf="dataServiceDemande != null">
                            <div class="row">
                                <ng-select [items]="allServices" name="post" bindLabel="service"
                                    placeholder="Selectionner le service" [(ngModel)]="dropdownTypeService"
                                    (clear)="clearService();" (change)="clearService();">
                                </ng-select>
                                <!-- <select id="post" name="post" class="select2-single form-control input-lg">
                                    <option value="0">Vehicle Type</option>
                                    <option value="1" data-subtext="for 1 person">Motorcycle (for 1 person)</option>
                                    <option value="2" data-subtext="max. 3 person">Car (max. 3 persons)</option>
                                    <option value="3" data-subtext="max. 6 person">Minivan (max. 6 persons)</option>
                                    <option value="4" data-subtext="max. 3 person">Luxury Car (max. 3 persons)</option>
                                </select><input type="hidden" id="postVal" value=""> -->
                            </div>
                        </div>
                        <!-- Vehicle Type -->
                        <!-- Ride Type -->
                        <div class="col-sm-4 booking-fields bf2" *ngIf="dataServiceDemande != null">
                            <div class="row">
                                <ng-select [items]="dropdownTypeService?.products" name="transfer" bindLabel="product"
                                    placeholder="Selectionner le type de produit" [(ngModel)]="dropdownTypeProduit"
                                    [disabled]="dropdownTypeService==null">
                                </ng-select>
                                <!-- <select id="transfer" name="transfer" class="select2-single form-control input-lg">
                                    <option value="0">Ride Type</option>
                                    <option value="1">One Way</option>
                                    <option value="2">Round Trip</option>
                                </select><input type="hidden" id="transferVal" value=""> -->
                            </div>
                        </div>
                        <!-- Ride Type -->
                        <!-- Date Time -->
                        <div class="col-sm-4 booking-fields bf5" *ngIf="dataServiceDemande != null">
                            <div class="row">
                                <table style="width:100%">
                                    <tr>
                                        <td style="width:65%">
                                            <!-- <input class="form-control daypicker input-lg" placeholder="Planifier"
                                                autocomplete="off" id="datepicker" name="datepicker" /> -->
                                            <div class="row">
                                                <input class="form-control input-lg" name="datepickerrr" matInput
                                                    [min]="startDate" [matDatepicker]="picker" placeholder="Planifier"
                                                    [(ngModel)]="valueDatepicker">
                                                <span class="cm" style="top: 2px; width: 47%;">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </span>
                                            </div>
                                        </td>
                                        <td style="width:35%">
                                            <!-- <input class="form-control hourpicker input-lg" placeholder="Heure"
                                                autocomplete="off" id="timepicker" name="timepicker" /> -->
                                            <ng-select [items]="listTimepicker" name="timepicker" bindLabel="value"
                                                placeholder="Heure" [(ngModel)]="valueTimepicker">
                                            </ng-select>
                                            <span class="hour-picker"></span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <!-- Date Time -->
                        <div class="col-md-12" id="mapLoader"
                            style="display:none; text-align:center; padding:10px 0 10px 0"
                            *ngIf="dataServiceDemande != null">
                            <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                        </div>
                        <div class="col-md-12"
                            style="text-align: center; color: rgb(204, 51, 0); font-weight: bold; margin: 5px 5px 10px; overflow: hidden; display: block;"
                            *ngIf="msgValidation != ''">
                            <h7>{{ msgValidation }}</h7>
                        </div>

                        <input type="hidden" id="costVal" value="">
                        <div class="col-md-12" id="route" style="display:none; text-align:center">
                            <h5 id="aPoint">&nbsp;</h5> <img src="assets/images/bidirectional-arrows.svg" width="12"
                                height="12" id="imgDirection" alt="camion wassali">
                            <h5 id="bPoint">&nbsp;</h5>
                        </div>
                        <input type="hidden" id="slideVal" value="slide">
                        <div class="col-md-12" id="normFare" style="display:none; text-align:center"></div>
                        <div *ngIf="msgValidation == ''" class="form-button col-md-12" id="bookingButton"
                            style="padding:0 10px 0 10px;">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:50%;">
                                        <button type="submit" class="btn form-btn btn-lg btn-block"
                                            (click)="_createDemande()">Passer la demande</button>
                                    </td>
                                    <td style="width:50%;">
                                        <button type="submit" class="btn form-btn btn-lg btn-block"
                                            style="background: #d7dee9; color: black !important;"
                                            (click)="_clearDemande()">Effacer la demande</button>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- <div class="col-md-12" id="mapLoader"
                            style="display:none; text-align:center; padding:10px 0 10px 0">
                            <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                        </div>
                        <div class="col-md-12" id="bookStatus"
                            style="display:none; text-align:center; color:#c30; font-weight:bold; margin:20px 5px 10px 5px">
                        </div>
                        <input type="hidden" id="costVal" value="">
                        <div class="col-md-12" id="route" style="display:none; text-align:center">
                            <h5 id="aPoint">&nbsp;</h5> <img src="assets/images/bidirectional-arrows.svg" width="12"
                                height="12" id="imgDirection" alt="Direction">
                            <h5 id="bPoint">&nbsp;</h5>
                        </div>
                        <input type="hidden" id="slideVal" value="slide">
                        <div class="col-md-12" id="normFare" style="display:none; text-align:center"></div>
                        <div class="form-button col-md-12" id="bookingButton"
                            style="padding:0 20px 0 20px; display:none">
                            <button type="submit" class="btn form-btn btn-lg btn-block" id="getTaxi">Take a
                                Taxi!</button>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="booking-form-wrapper" style="z-index: 51"
    *ngIf="permission!='denied' && userHasDemande==true && demande!=null">
    <div class="col-sm-12">
        <div class="row">
            <div class="form-wrap" style="border-radius: 10px;">
                <!-- <div class="form-headr"></div> -->
                <h2 style="line-height: 100%; padding:10px !important"> Mise en relation avec les transporteurs
                    <br> Vous pouvez recevoir plusieurs offres...
                </h2>

                <div class="form-select">
                    <div class="col-md-6" *ngFor="let response of pendingProposition(demande.driver_responses)">
                        <!-- <div class="row"> -->
                        <div class="services-content equal" style="background-color: white;">
                            <div class="row" style="width: 100%;">
                                <div class=" col-sm-12">
                                    <div class="row">
                                        <!-- <table style="width:100%">
                                            <tr>
                                                <td style="width:30%;">
                                                        <img class="img-driver-proposition"
                                                            src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/driver-trans/driver-vehicule/' + response.driver_id.info_vehicule.photo_vehicule}}"
                                                            alt="Luxury Taxi">
                                                </td>
                                                <td style="width:50%;">
                                                    <div class="services-text-proposition">
                                                        <h6 class="mat-h6-response description-text">
                                                            {{response?.driver_id?.info_vehicule?.type_camion}}</h6>
                                                        <h6 class="mat-h6-response description-text">
                                                            {{response?.driver_id?.user_transportor?.prenom_user + " " +
                                                            response?.driver_id?.user_transportor?.nom_user}}</h6>
                                                        {{response?.driver_id?.evaluate}} <span
                                                            class="fa fa-star checked description-text"></span>
                                                    </div>
                                                </td>
                                                <td style="width:20%;">
                                                    <div class="services-text-proposition text-right">
                                                        <h6 class="mat-h6-response description-text" style="color: #013274; font-size: 23px;
                                                            font-weight: bold;">{{response?.proposition + " TND"}}
                                                        </h6>
                                                        <h6 class="mat-h6-response description-text">{{response?.duree}}
                                                        </h6>
                                                        <h6 class="mat-h6-response description-text">{{response?.distance +
                                                            " Km"}}</h6>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table> -->

                                        <div class="col-sm-3">
                                            <div class="row">
                                                <div class="services-vehicle-home">
                                                    <img class="img-driver-proposition"
                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/driver-trans/driver-vehicule/' + response.driver_id.info_vehicule.photo_vehicule}}"
                                                        alt="proposition transporteur wassali" style="width: 100%;">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="services-text-proposition">
                                                    <h6 class="mat-h6-response description-text">
                                                        {{response?.driver_id?.info_vehicule?.type_camion}}</h6>
                                                    <h6 class="mat-h6-response description-text">
                                                        {{response?.driver_id?.user_transportor?.prenom_user + " " +
                                                        response?.driver_id?.user_transportor?.nom_user}}</h6>
                                                    {{response?.driver_id?.evaluate}} <span
                                                        class="fa fa-star checked description-text"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="row">
                                                <div class="services-text-proposition text-right">
                                                    <h6 class="mat-h6-response description-text" style="color: #013274; font-size: 18px;
                                                        font-weight: bold;">{{response?.proposition + " TND"}}
                                                    </h6>
                                                    <h6 class="mat-h6-response description-text">{{response?.duree}}
                                                    </h6>
                                                    <h6 class="mat-h6-response description-text">{{response?.distance +
                                                        " Km"}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="row">
                                        <table style="width:100%">
                                            <tr>
                                                <td style="width:50%;">
                                                    <!-- <div class="form-button-home" style="padding:0 20px 0 20px;"> -->
                                                    <button type="button" (click)="_refuseDemande(response._id)"
                                                        style="border: 3px solid red; color: black !important; background: transparent;border-radius: 20px;"
                                                        class="btn form-btn btn-lg btn-block">Refuser</button>
                                                    <!-- </div> -->
                                                </td>
                                                <td style="width:50%;">
                                                    <!-- <div class="form-button-home" style="padding:0 20px 0 20px;"> -->
                                                    <button type="button" class="btn form-btn btn-lg btn-block"
                                                        style="background: #013274; border: 3px solid #013274; border-radius: 20px;"
                                                        (click)="_accepteDemande(response, demande._id)">Accepter</button>
                                                    <!-- </div> -->
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                    <div class="form-button col-md-12" style="padding:0 20px 0 20px;">
                        <button type="submit" class="btn form-btn btn-lg btn-block"
                            style="background: #d7dee9; color: black !important;"
                            (click)="showCanceledDemande()">Annuler la demande</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xs-12"
    style="height:12px; opacity: 1; filter: alpha(opacity=50); float:left; background: url(assets/images/taxi-dama.png) bottom center repeat-x">
    &nbsp;</div>
<!-- BODY -->
<div class="col-xs-12 text-center" style="float:left;">
    <h4 class="text-center" style="font-weight: bold; margin-top: 20px;
    font-size: 22px;">Téléchargez notre application</h4>
    <p class="buttons">
        <input type="button" class="button google" value="&nbsp;" style="margin-top:5px"
            onClick="window.open('https://bit.ly/3KXts5p')" />
        <input type="button" class="button apple" value="&nbsp;" style="margin-top:5px"
            onClick="window.open('https://apple.co/3fNWqXc')" />
    </p>
</div>
<hr style="border:1px solid #ededed">

<div class="label-white">
    <div class="container">
        <div class="row">
            <h2 style="font-size: 45px; text-align: center;">
                Expediteur ou Transporteur ?</h2>
            <h4 style="font-size: 20px; text-align: center;">Rejoignez Wassali pour faciliter votre vie et bénéficier
                d’un service flexible, optimisé et garantie.</h4>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/client.png" alt="Client wassali">
                            </div>
                            <h3>Client Wassali</h3>
                            <p>Besoin d’un transporteur pour votre déménagement, vos transports de marchandise ou
                                transport des biens (électroménager ou meuble),
                                Wassali vous offre un service unique avec le meilleur prix de transport en Tunisie.</p>
                            <a class="link" [routerLink]="['/inscription-wassali']">
                                <span style="color: #013274;">Plus de détails</span>
                                <i class="icon-right-arrow"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item center-text columns">
                        <div class="div-image">
                            <div class="visual">
                                <img src="assets/images/transporteur.png" alt="Transporteur ewassali">
                            </div>
                            <h3>Transporteur Wassali</h3>
                            <p>Devenez transporteur chez Wassali et profitez de cette opportunité afin d'augmentez vos
                                revenus,
                                gérez au mieux votre activité en rejoignant notre communauté de transporteurs
                                professionnels et qualifiés.</p>
                            <a class="link" [routerLink]="['/inscription-wassali']">
                                <span style="color: #013274;">Plus de détails</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="label-white" style="background: #01327405; padding: 50px 0;">
    <div class="container" style="max-width: 1400px;">
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <iframe width="100%" height="370" [src]="videoProduct|safe: 'resourceUrl'" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item">
                        <div class="containt-text-video">
                            <h3>eWassali</h3>
                            <!-- <span>Traçabilité.</span> -->
                            <p style="font-size: 22px;
                            line-height: 35px; text-align: justify; text-justify: inter-word;">
                                eWASSALI transport express est une plateforme qui met en relation un expéditeur avec un
                                transporteur grace à notre flotte de véhicules.
                                e-Wassali vous assure un service flexible qui s’adapte à vos besoins en termes de
                                qualité, cout et délai.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="label-white">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item ">
                        <div class="containt-font img-circle">
                            <img src="assets/images/dollar.svg" alt="Production wassali" width="62" height="62" />
                        </div>
                        <div class="containt-text">
                            <h3>Temps de réponse</h3>
                            <span>Meilleur.</span>
                            <p>Obtenez instantanément une réponse adaptée à toutes vos demandes, Wassali est une
                                solution immédiate pour le transport de tous type de marchandise .</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item">
                        <div class="containt-font img-circle">
                            <img src="assets/images/satisfaction.svg" alt="Satisfaction ewassali" width="62"
                                height="62" />
                        </div>
                        <div class="containt-text">
                            <h3>Système de tracking</h3>
                            <span>Traçabilité.</span>
                            <p>Vous permet de suivre en temps réel chaque étape de votre transport jusqu’à la livraison.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item">
                        <div class="containt-font img-circle">
                            <img src="assets/images/safe.svg" alt="Sécurité wassali" width="62" height="62" />
                        </div>
                        <div class="containt-text">
                            <h3>Inscription</h3>
                            <span>Simple et facile.</span>
                            <p>Il vous suffit de quelques clics pour déposer votre demande : choisissez votre
                                destination, choisissez votre camion, et lancez votre demande.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="label-item">
                        <div class="containt-font img-circle">
                            <img src="assets/images/location.svg" alt="Service garanti ewassali" width="62"
                                height="62" />
                        </div>
                        <div class="containt-text">
                            <h3>Service garanti</h3>
                            <span>Flexible!</span>
                            <p>Un portefeuille de transporteurs spécialisés, tracking en temps réel, un service rapide
                                et fiable.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="stellar-image">
    <div class="stellar-motto stellar" data-stellar-background-ratio="0" data-stellar-vertical-offset="50">
        <div class="container">
            <div class="row">
                <h2 style="color: white; font-size: 40px;">eWassali</h2>
                <h4 style="color: white; font-size: 20px;">Expert dans le domaine du transport</h4>
                <div class="col-sm-3">
                    <div class="page7-j0urney-wrap">
                        <div class="fIcons"><img src="assets/images/icon-happy-passenger.svg" alt="Nos clients wassali"
                                width="52" height="52" /></div>
                        <div class="happy-custon">
                            <span class="counter" style="color: white;">{{statisticsUser?.client}}</span>
                            <h5 style="font-size: 17px;
                            color: white;
                            margin-top: auto;">Nos clients</h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="page7-j0urney-wrap">
                        <div class="fIcons"><img src="assets/images/icon-taxi-cruises.png"
                                alt="Nos transporteurs ewassali" width="52" height="52" /></div>
                        <div class="happy-custon">
                            <span class="counter" style="color: white;">{{statisticsUser?.driver}}</span>
                            <h5 style="font-size: 17px;
                            color: white;
                            margin-top: auto;">Nos transporteurs</h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="page7-j0urney-wrap">
                        <div class="fIcons"><img src="assets/images/icon-destination.svg"
                                alt="Territoire tunisien wassali" width="52" height="52" /></div>
                        <div class="happy-custon">
                            <span class="counter" style="color: white;">2366</span>
                            <h5 style="font-size: 17px;
                            color: white;
                            margin-top: auto;">Tout le territoire tunisien</h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="page7-j0urney-wrap">
                        <div class="fIcons"><img src="assets/images/icon-odometer.svg" alt="KM parcourus ewassali"
                                width="52" height="52" />
                        </div>
                        <div class="happy-custon">
                            <span class="counter" style="color: white;">14623</span>
                            <h5 style="font-size: 17px;
                            color: white;
                            margin-top: auto;">KM parcourus</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
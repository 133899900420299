import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { CauseCanceledDemandeComponent } from 'src/app/components/shared/dialog/cause-canceled-demande/cause-canceled-demande.component';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-details-demande',
  templateUrl: './details-demande.component.html',
  styleUrls: ['./details-demande.component.css']
})
export class DetailsDemandeComponent implements OnInit {

  /////// data google
  mapLoaded: boolean = false;
  map: google.maps.Map = null as any;
  center: google.maps.LatLngLiteral = null as any;

  source: google.maps.LatLngLiteral = null as any;
  markerStart: google.maps.Marker = null

  destination: google.maps.LatLngLiteral = null as any;
  destinationMarker: google.maps.Marker = null

  driver: google.maps.LatLngLiteral = null as any;
  driverMarker: google.maps.Marker = null

  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    zoom: 14
  }

  ds: google.maps.DirectionsService = null as any;
  dr: google.maps.DirectionsRenderer = null as any;

  ds2: google.maps.DirectionsService = null as any;
  dr2: google.maps.DirectionsRenderer = null as any;

  private geoCoder;
  /////--------------

  demandeID: string = null
  demande: any = null

  subscription: Subscription;

  constructor(private route: ActivatedRoute,
    private transServices: TransportorsService,
    private router: Router,
    private dialog: MatDialog) { }

  async ngOnInit() {
    this.geoCoder = new google.maps.Geocoder;
    await this._initMap();

    await this.route.queryParams.subscribe(async (params) => {
      // console.log("paraaaaammmms: ")
      // console.log(params)

      this.demandeID = params.demande;
      await this._fetchDemande();

      // await this.initMarkerDriver()
      await this.initMarkers()

      if (this.demande != null && (this.demande.etat_demande == "accepted" || this.demande.etat_demande == "started")) {
        await this.setRoutePolyline(this.source);
        await this.setRoutePolylineDriver();
      } else {
        await this.setRoutePolyline(this.driver);
      }
      // console.log(this.cmd);
    });

    const source = interval(1000);
    this.subscription = source.subscribe(async (val) => {
      if (this.demandeID != null && this.demande != null) {
        await this._fetchDemande()
        this._getPositionDriver()
        this.setSourceAndDestinationIcons()
        //   await this.initMarkerDriver()
        // await this.initMarkers()
      }
    });
  }

  _cancelDemande(msg) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.transServices.updateDemande(this.demande._id, "canceled", msg).then((res: any) => {
        if (res) {
          if (res.response && res.response == true)
            resolve(true)
          else
            resolve(null)
        } else
          resolve(null)
      }).catch((e) => {
        resolve(null)
      });
    });
    return promise
  }

  showCanceledDemande() {
    window.scrollTo(0, 0);
    const confirmDialog = this.dialog.open(CauseCanceledDemandeComponent, {
      data: {
        causeDescription: null
      }
    });

    confirmDialog.afterClosed().subscribe(async (result) => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        var updateDemande = await this._cancelDemande(data.causeDescription)
        if (updateDemande != null && updateDemande == true) {
          location.href = ''
        }
        // this.fromPlace = data.position.label_target
        // this._dataTargetLocation = data.position
        // this.setLocation(data.position.lat, data.position.lng)
        // if (this.destination != null) {
        //   this.setRoutePolyline()
        // }
        // console.log("this._dataTargetLocation")
        // console.log(this._dataTargetLocation)
      }
    });
  }

  async _fetchDemande() {
    // let promise = new Promise(async (resolve, reject) => {
    await this.transServices.getOneDemande(this.demandeID).then(async (d) => {
      // console.log("ddddddddddddddddd")
      // console.log(d)
      if (d) {
        this.demande = d
        // resolve(d);
        // await this.setRoutePolyline();
        // await this.setRoutePolylineDriver()
      } else {
        alert('Aucune demande valide');
        location.href = '/';
      }
    });
    // });
    // return promise
  }

  async _initMap() {
    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer({
      map: null,
      suppressMarkers: true
    });

    this.ds2 = new google.maps.DirectionsService();
    this.dr2 = new google.maps.DirectionsRenderer({
      map: null,
      suppressMarkers: true
    });

    await navigator.geolocation.getCurrentPosition(async (position) => {

      var center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      this.map = new google.maps.Map((document as any).getElementById('map-canvas'), {
        ...this.options,
        center: center
      });

      this.map.addListener('tilesloaded', () => {
        this.mapLoaded = true;
      });
    });
  }

  async initMarkers() {
    this.driver = {
      lat: this.demande.acceptedBy.driver_id.latitude,
      lng: this.demande.acceptedBy.driver_id.longitude
    };
    this.driverMarker = new google.maps.Marker({
      position: this.driver,
      icon: {
        url: 'assets/images/driver_marker_partner.png',
        // anchor: new google.maps.Point(35, 10),
        // scaledSize: new google.maps.Size(100, 100)
      },
      map: this.map
    });

    if (this.demande != null && (this.demande.etat_demande == "accepted" || this.demande.etat_demande == "started")) {
      this.source = {
        lat: this.demande.user_location.lat,
        lng: this.demande.user_location.lng
      };
      // adding a marker
      this.markerStart = new google.maps.Marker({
        position: this.source,
        label: {
          color: 'black',
          fontWeight: 'bold',
          text: '',
        },
        icon: {
          url: 'assets/images/destination_map_marker.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });
    }

    this.destination = {
      lat: this.demande.user_destination.lat,
      lng: this.demande.user_destination.lng
    };
    this.destinationMarker = new google.maps.Marker({
      position: this.destination,
      label: {
        color: 'black',
        fontWeight: 'bold',
        text: '',
      },
      icon: {
        // rota
        url: 'assets/images/destination_map_marker2.png',
        // anchor: new google.maps.Point(35, 10),
        // scaledSize: new google.maps.Size(100, 100)
      },
      map: this.map
    });
    // await this.setRoutePolyline();
    // await this.setRoutePolylineDriver()
  }

  async _getPositionDriver() {
    var getPosition = await this.transServices.getPositionDriver(this.demande["acceptedBy"]["driver_id"]["_id"])
    if (getPosition != null) {
      this.driver = {
        lat: getPosition["latitude"],
        lng: getPosition["longitude"]
      }
      this.driverMarker.setPosition(this.driver)
    }
  }

  async setSourceAndDestinationIcons() {
    if (this.demande["etat_demande"] == "accepted" ||
      this.demande["etat_demande"] == "started") {
      var directionstwo: any = await this.transServices.getDistances(
        this.driver.lat, this.driver.lng, this.source.lat, this.source.lng)
      if (directionstwo != null) {
        this.markerStart.setLabel({
          color: 'black',
          fontWeight: 'bold',
          text: directionstwo.totalDuration.toString() + " min; " + directionstwo.totalDistance.toString() +
            " Km",
        })
      }

      var directionsDestination: any = await this.transServices.getDistances(
        this.source.lat, this.source.lng, this.destination.lat, this.destination.lng)
      if (directionsDestination != null) {
        this.destinationMarker.setLabel({
          color: 'black',
          fontWeight: 'bold',
          text: directionsDestination.totalDuration.toString() + " min; " + directionsDestination.totalDistance.toString() +
            " Km",
        })
      }
    } else {
      if(this.demande["etat_demande"] == "finish") {
        this.router.navigate(['/']);
      }
      // this.source = null
      if(this.markerStart != null)
      this.markerStart.setMap(null)
      this.markerStart = null
      var directionsDestination: any = await this.transServices.getDistances(
        this.driver.lat, this.driver.lng, this.destination.lat, this.destination.lng)
      if (directionsDestination != null) {
        this.destinationMarker.setLabel({
          color: 'black',
          fontWeight: 'bold',
          text: directionsDestination.totalDuration.toString() + " min; " + directionsDestination.totalDistance.toString() +
            " Km",
        })
      }
    }
  }

  setRoutePolyline(source) {
    let request = {
      origin: source,
      destination: this.destination,
      travelMode: google.maps.TravelMode.DRIVING
    };

    this.ds.route(request, (response, status) => {
      this.dr.setOptions({
        suppressPolylines: false,
        map: this.map
      });

      if (status == google.maps.DirectionsStatus.OK) {
        this.dr.setDirections(response);
      }
    })
  }

  setRoutePolylineDriver() {
    let request = {
      origin: this.driver,
      destination: this.source,
      travelMode: google.maps.TravelMode.DRIVING
    };

    this.ds2.route(request, (response, status) => {
      this.dr2.setOptions({
        polylineOptions: { strokeColor: "red", strokeWeight: 4 },
        suppressPolylines: false,
        map: this.map
      });

      if (status == google.maps.DirectionsStatus.OK) {
        this.dr2.setDirections(response);
      }
    })
  }
}


interface marker {
  lat: number;
  lng: number;
  label_target: string;
  main_target: string;
  sencondary_target: string;
}
